import api from "../api/request";
import { get_local } from "../utils/storege";
import { get_card_project,get_card_project_realize } from '../templates/templates_projects';
import { PaginationProject } from "../components/pagination";
import { get_history, set_history } from "../utils/history_store";
import form_option from "../utils/trigget-filters";
import { SwitcherMap } from "../components/swicher_map";
import { CompletedProjectMap, ProjectMap } from "../components/map";
/**
 * @class -  класс содержит логику для секции "список проектов" на cтранице проекты
 * @method render_map - при наличие координат инициализирует карту и инициализирует табы переключения с карты на список проектов
 * @method init_map - создает экземпляр класса карты
 * @method init_projects - создает экземпляр класса пагинации
 * @method init_category_wrap - определяет активный тип проектов и показывает соответствующий контейнер
 * @method target_filters - слушает изменение категории, переключает контейнер и обновляет карту
 * @method target_project_link - слушает назатие на карточку проекта
 * @method init_project_link - формирует ссылку для перехода в деталку проекта
 * @method link_to_project - проверяет наличие ссылки и делает переход на проект
 * @param wraps контейнеры для категорий домов
 * all - все категории
 * 0 - проекты с многоэтажными домами
 * 1 - проекты  домами таунхаусами
 * 2 - проекты содержащие коммерцию
 */
export class ProjectsList {
    constructor() {
        this.city = get_local('city')
        this.form_pagination = $(".js-projects-filter");
        this.pagination = null;
        this.filters = $('.js-filters-category');
        this.type_pages = {
            CompletedProjectListPage: {
                api: api.complited_projects_list,
                class_map: CompletedProjectMap,
                template:get_card_project_realize
            },
            ProjectListPage: {
                api: api.projects_list,
                class_map: ProjectMap,
                template: get_card_project
            },
        }
        this.page = {}
        this.category_btn = {
            all: $('.js-category-all'),
            0: $('.js-category-house'),
            1: $('.js-category-townhouse'),
            2: $('.js-category-commerce')
        };
        this.category_btn_active = {};
        this.wraps = {
            all: $('.js-projects-all'),
            0: $('.js-projects-house'),
            1: $('.js-projects-townhouse'),
            2: $('.js-projects-commerce')
        };
        this.container_obj = {
            all: $('.js-projects-container-all'),
            0: $('.js-projects-container-house'),
            1: $('.js-projects-container-townhouse'),
            2: $('.js-projects-container-commerce')
        };
        this.container_active = {};
    }
    init = () => {
        this.init_page()
        this.init_category_wrap();
        this.request_map();
        this.init_projects();
        this.render_projects();
        this.target_filters();
        this.target_project_link();
    }
    init_page = () => {
        const page_name = $('.js-page-name').attr('data-page')
        this.page = this.type_pages[page_name]
    }
    request_map = () => {
        this.init_map()
    }
    init_category_wrap = () => {
        const params = get_history();
        if (params.type) {
            this.container_obj[params.type].addClass('show');
            this.category_btn[params.type].addClass('active_radio');
            this.category_btn[params.type].find('input').prop('checked', true);
            this.category_btn_active = this.category_btn[params.type];
            this.container_active = this.container_obj[params.type];
        } else {
            this.container_obj.all.addClass('show');
            this.category_btn.all.addClass('active_radio');
            this.category_btn_active = this.category_btn.all;
            this.container_active = this.container_obj.all;
        }
    }
    init_map = () => {
        const map = new this.page.class_map({
            mapName: "map",
            filter: this.filters,
            api: this.page.api,
            options: get_history()
        });
        map.init(map.createInitialMap);
        const switch_map = new SwitcherMap({ map: map });
        switch_map.init();
    }
    init_projects = () => {
        this.pagination_all = new PaginationProject({
            form: this.form_pagination,
            wrap_content: this.wraps.all,
            template: get_card_project,
            type: "all",
            btn_name: '.js-pagination-all',
            empty_block: $(".js-wrap-empty-all"),
            api: this.page.api,
            template:this.page.template
        })
        this.pagination_all.init();
        this.pagination_house = new PaginationProject({
            form: this.form_pagination,
            wrap_content: this.wraps[0],
            template: get_card_project,
            type: "0",
            btn_name: '.js-pagination-house',
            api: this.page.api,
            empty_block: $(".js-wrap-empty-house"),
            template:this.page.template
        })
        this.pagination_house.init();
        this.pagination_townhouse = new PaginationProject({
            form: this.form_pagination,
            wrap_content: this.wraps[1],
            template: get_card_project,
            type: "1",
            btn_name: '.js-pagination-townhouse',
            empty_block: $(".js-wrap-empty-townhouse"),
            api: this.page.api,
            template:this.page.template
        })
        this.pagination_townhouse.init();
        this.pagination_commerce = new PaginationProject({
            form: this.form_pagination,
            wrap_content: this.wraps[2],
            template: get_card_project,
            type: "2",
            btn_name: '.js-pagination-commerce',
            empty_block: $(".js-wrap-empty-commerce"),
            api: this.page.api,
            template:this.page.template
        })
        this.pagination_commerce.init();
    }
    render_projects = () => {
        this.pagination_all.first_render();
        this.pagination_house.first_render();
        this.pagination_townhouse.first_render();
        this.pagination_commerce.first_render();
    }
    target_filters = () => {
        this.form_pagination.on('change', (e) => {
            const params = form_option.get_options_form(this.filters);
            set_history(params);
            const id = $(e.target).val();
            this.category_btn_active.removeClass('active_radio');
            this.category_btn_active = this.category_btn[id];
            this.category_btn[id].addClass('active_radio');
            this.container_active.removeClass('show');
            this.container_active = this.container_obj[id];
            this.container_obj[id].addClass('show');
        })
    }
    target_project_link = () => {
        const self = this;
        $(document).on('click', '.js-project-link', function (e) {
            e.preventDefault()
            self.link_to_project(self.init_project_link(this))
        })
    }
    link_to_project = (link) => {
        if (link) {
            location.href = link
        }
    }
    init_project_link = (event) => {
        const type_multiproject = 0;
        const filters_houses = {
            all: '',
            0: 'house',
            1: 'queue',
            2: 'commercial'
        }
        const type_project = Number($(event).attr('data-typepath'));
        const link = $(event).attr('href');
        if (type_project === type_multiproject) {
            const params = form_option.get_options_form(this.filters);
            const type_filters = filters_houses[params.type];
            if (type_filters) {
                return `${link}?type=${type_filters}`
            } else {
                return link;
            }
        } else {
            return link;
        }
    }
}

export const get_template_decor_slide = (data) => {
    return `
    <div class="swiper-slide decor-slide">
        <div class="decor-slide js-decor-wrap-item">
            <div class="decor-slide__img">
                <img class="js-async-image" src="${data.mini_image}" data-src="${data.image}" alt="decor">
            </div>
            <div class="decor-slide__svg js-decor-slide__svg">
                ${data.svg ? data.svg : ''}
            </div>
        </div>
    </div>`
}
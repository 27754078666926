/**
 * @class - класс содержит логику инициации у управления кастомными чекбоксами
 * @method toggle_active_status_checkbox - переключает активный статус
 * @method change_check_box - проверяет наличие активных чек боксов в контейнере
 * @method active_filters - делает контейнер активным
 */
export class AbstractCheckbox {
  constructor() {
    this.form = $(".js-filters");
    this.choice_wrap = $(".js-choice__wrap");
    this.choice_inputs = $(".js-choice-option__input");
    this.active_filter_count = $(".js-send-filter-count");
  }
  toggle_active_status_checkbox = () => {
    this.choice_wrap.hover(function () {
      $(this).parent().toggleClass("hover");
    })
  }
  change_check_box = () => {
    const self = this
    this.choice_inputs.change(function (e) {
      let value = $(this).val().toLowerCase()
      $(`.js-choice-option[data-value=${value}-rooms]`).toggleClass('active_radio');
      let results = [];
      if ($(this).is(":checked")) {
        $(this).closest(".input").addClass("active_filter");
      } else {
        self.choice_inputs.map((index, item) => {
          if (item.checked) {
            results.push(true);
          } else {
            results.push(false);
          }
        })
        if (!results.some((e) => e === true)) {
          $(this).closest(".input").removeClass("active_filter");
        }
      }
      self.request();
      self.active_filters();
    })
  }
  request = () => { }
  active_filters = () => {
    // на схеме дома всегда 1 примененный, но скрытый фильтр
    const count = $('.active_filter', this.form).length - 1;
    if (count) {
      this.active_filter_count.text(count);
    } else {
      this.active_filter_count.text('');
    }
  }
}

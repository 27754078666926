import api from '../api/request';
import { conjugation } from '../utils/conjugation';
import { loader } from './loader';
import { showModalError } from './modal';
import form_option from '../utils/trigget-filters';
import { AbstractSort } from './sort'
import { asyncImg } from '../utils/loadingAsyncImg';

/**
 * @abstract - абстрактный класс пагинации расширеный логикой сортировки
 * @method first_render - метод нужен для первого запроса и рендера
 * @method reset_pagination - сбрасывает номер старницы до 1 и перерендеривает элементы в контейнере
 * @method target_pagination - слушает событие нажатия на кнопку пагинации
 * @method get_options_pagination - собирает объект с фильтрами для запроса
 * @method request - отправляет запрос, проверяет наличие данных и либо редреит, либо показывает пустой контейнер
 * @method show_content - показывает контейнер в контентом
 */
class AbstractPagination extends AbstractSort {
    constructor(props) {
        super(props);
        this.btn_name = props.btn_name ? props.btn_name : '.js-pagination';
        this.btn = $(this.btn_name);
        this.empty_block = props.empty_block ? props.empty_block : $(".js-wrap-empty");
        this.init_data = props.init_data ? props.init_data : null;
        this.modal_error = $('.js-req-modal__error');
        this.page_pagination = 1;
        this.page_size = props.page_size ? props.page_size : 6;
        this.api_request = props.api;
        this.wrap_content = props.wrap_content;
        this.form = props.form;
        this.template = props.template;
        this.count_wrap = props.count_wrap;
        this.count_bank = props.count_bank;
        this.loader_name = props.loader_name;
    }
    init = () => {
        this.target_pagination();
        if (this.btn_sort) {
            this.init_sort();
        }
    }
    first_render = () => {
        if (this.init_data) {
            this.handle_response(this.init_data, true);
            return;
        }

        const options = this.get_options_pagination();
        this.wrap_content.removeClass('show');
        this.request(options, true);
    }
    reset_pagination = () => {
        this.page_pagination = 1;
        this.first_render();
        if (this.btn_sort) {
            this.reset_active_sort()
        }
    }
    target_pagination = () => {
        $(document).on("click", this.btn_name, (e) => {
            e.preventDefault()
            this.page_pagination = this.page_pagination + 1;
            const options = this.get_options_pagination();
            this.request(options);
        })
    }
    get_options_pagination = () => {
        let options = { page: this.page_pagination, page_size: this.page_size };
        this.form && (options = { ...options, ...form_option.get_options_form(this.form) })
        return this.city ? { city: this.city, ...options } : options;
    }
    request = (options, rerender) => {
        this.show_loader()
        this.api_request(options)
            .then(data => {
                this.handle_response(data, rerender);
            })
            .catch(err => {
                switch (err?.response?.status) {
                    case 404:
                    case 500:
                        this.wrap_content.removeClass('show');
                        this.hidden_btn();
                        showModalError(this.modal_error);
                        break;
                }
            })
    }
    handle_response = (data, rerender) => {
        this.show_btn(data);
        if (data.results.length) {
            if (rerender) {
                this.wrap_content.children().remove();
                this.render(data.results);
                this.show_content();
            } else {
                this.render(data.results);
                this.show_content();
            }
        } else {
            this.show_empty();
        }
    }
    show_loader = () => {
        this.empty_block.removeClass('show');
        //this.wrap_content.removeClass('show');
        this.hidden_btn();
        loader.show_loader(this.loader_name);
    }
    show_content = () => {
        loader.hidden_loader(this.loader_name);
        this.wrap_content.addClass("show");
    }
    show_empty = () => {
        loader.hidden_loader(this.loader_name);
        this.empty_block.addClass('show');
    }
    render = (data) => {
        if (data) {
            this.wrap_content.append(data.map((i) => this.template(i)));
            asyncImg.initOnWrap(this.wrap_content)
        }
    }
    show_btn = (data) => {
        if (data.next) {
            this.btn.addClass("show");
        } else {
            this.hidden_btn();
        }
    }
    hidden_btn = () => {
        this.btn.removeClass("show");
    }
}
/**
 * @class - класс собержащий реализацию пагинацию
 */
export class DefaultPagination extends AbstractPagination {
    constructor(props) {
        super(props)
    }
}
export class FlatPlanPagination extends AbstractPagination {
    constructor(props) {
        super(props)
    }
}
/**
 * @class - класс собержащий реализацию пагинацию для списка проектов на "главной" странице
 */
export class PaginationProjectIndex extends AbstractPagination {
    constructor(props) {
        super(props)
        this.project_section = $('.js-project-wrap');
    }
    render = (data) => {
        if (data.length) {
            this.wrap_content.append(data.map((i) => this.template(i)));
            asyncImg.initOnWrap(this.wrap_content);
        } else {
            this.project_section.addClass('hidden-wrap');
        }
    }
}
/**
 * @class - класс собержащий реализацию пагинацию для списка проектов на странице "проекты"
 */
export class PaginationProject extends AbstractPagination {
    constructor(props) {
        super(props)
        this.type = props.type;
    }
    get_options_pagination = () => {
        return { page: this.page_pagination, page_size: this.page_size, type: this.type, city: this.city };
    }
}
/**
 * @class - класс собержащий реализацию пагинацию для "списка предложений банков"
 * @method view_count_banks - высчитывает и выводит колличество предложений для следующей пагинации
 */
export class PaginationBanks extends AbstractPagination {
    constructor(props) {
        super(props);
        this.api_request = api.banks;
        this.count_bank = $('.js-mortage-bank-count');
    }
    show_btn = (data) => {
        if (data.next) {
            this.btn.addClass("show");
            this.view_count_banks(data);
        } else {
            this.btn.removeClass("show");
        }
    }
    view_count_banks = (data) => {
        const page_size_prev = (this.page_pagination - 1) * this.page_size;
        const page_size_all = this.page_pagination * this.page_size;
        if (data.count > page_size_all) {
            const count = data.count - (page_size_prev + data.results.length);
            this.count_bank.text(conjugation(count, ['предложение', 'предложения', 'предложений']));
        } else {
            this.count_bank.text(conjugation(this.page_size, ['предложение', 'предложения', 'предложений']));
        }
    }
}

import { WAY_UP_DEF_IMG } from '../const/way_up'
export const get_template_booking_event = (data) => {
    return `
    <div class="application-card card">
        <div class="application-card__img">
        ${data.image ? `<img class="image" src="${data.image}" alt="event">` : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
        </div>
        <div class="application-card-content">
            ${data.title ? `<p class="application-card__title">${data.title}</p>` : ``}
            ${data.address ? `<p class="application-card__text">${data.address}</p>` : ``}
            <a class="btn btn_primary req-modal__btn js-back-events" href="#section_booking_events"> Сменить дом </a>
        </div>
    </div>`
}

export const get_template_application_month = (data) => {
    const date = new Date();
    const year = date.getFullYear();
    return `
    ${Object.entries(data).map(month =>
        `<div class="radio__option radio__option_pad">
        <input class="radio__input js-radio-input js-target-month" type="radio" data-year='${month[1].year}' name="month" value='${month[1].month}' data-month='${month[0]}' >
        <label class="radio__label" for="month">${month[0]}${month[1].year !== year ? ' ' + month[1].year + ' г.' : ''}</label>
        </div>`
    )}
    `
}

export const get_template_application_date = (data) => {
    let text = ''
    Object.entries(data).forEach((month) => {
        text += `<div class='hidden-wrap js-trigger-month' data-mount='${month[1].month}'>
        <div class="booking-horizontal-wrap">`
        month[1].dates.forEach(i =>
            text += `<div class="radio__option application-days-block">
                <input class="radio__input js-radio-input" type="radio" name="day" data-week='${i.day_week}' value='${i.day}' ${i.free ? '' : 'disabled '}>
                <label class="radio__label" for="day"><span class="day">${i.day}</span>
                    <span class="weekday">${i.day_week}</span>
                </label>
            </div>`
        )
    })
    return text += `</div></div>`
}

export const get_template_application_times = (data) => {
    return `
    <div class="radio__option">
        <input class="radio__input js-radio-input" value='${data.time}' type="radio" name="time" ${data.free ? '' : 'disabled'}>
        <label class="radio__label" for="time">${data.time}</label>
    </div>`
}
export const get_template_events = (data) => {
    const lending_type = 3;
    return `
    <div class="application-card card">
        <div class="application-card__img">
        ${data.image ? `<img class="image" src="${data.image}" alt="event">` : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
        </div>
        <div class="application-card-content">
            ${data.title ? `<p class="application-card__title">${data.title}</p>` : ``}
            ${data.address ? `<p class="application-card__text">${data.address}</p>` : ``}
            ${Number(data.event_type) === lending_type ? `
            <a class="btn btn_primary req-modal__btn" href="${data.landing_absolute_url}"> Записаться</a>` :
            `<span class="btn btn_primary req-modal__btn js-target-events" data-type-event='${data.event_type}' data-id='${data.pk}'> Записаться</span>`}
        </div>
    </div>`
}
export const form_registration_template = (data) => {
    let html = '';
    html += data.first_name ? `<div class="input text application-form__input js-input-wrap" data-input="first_name">
            <label class="input__label" for="first_name"><span>ФИО</span></label>
            <input class="input__field js-name" type="text"  name='first_name' placeholder="Иванов Иван Иванович"/>
            <span class="input__error js-input-error" data-error='first_name'></span>
        </div>`: '';
    html += data.phone ? `<div class="input text application-form__input js-group-phone js-input-wrap" data-input="phone">
            <label class="input__label" for="phone"><span>Телефон</span></label>
            <input class="input__field  js-phone" type="tel" name='phone' placeholder="+7 (999) 999-9999" />
            <span class="input__error js-input-error" data-error='phone'></span>
        </div>`: ''
    html += data.flat_number ? ` <div class="input text application-form__input js-input-wrap" data-input="flat_number">
            <label class="input__label" for="flat"><span>№ квартиры</span></label>
            <input class="input__field" type="text" name="flat_number" placeholder="№ квартиры">
            <span class="input__error js-input-error" data-error='flat_number'></span>
        </div>`: '';
    html += data.contract_number ? `<div class="input text application-form__input js-input-wrap" data-input="contract_number">
            <label class="input__label" for="contract"><span>№ договора</span></label>
            <input class="input__field" type="text" name="contract_number" placeholder="№ договора">
            <span class="input__error js-input-error" data-error='contract_number'></span>
        </div>`: '';
    html += data.email ? ` <div class="input text application-form__input js-input-wrap" data-input="email">
            <label class="input__label" for="email"><span>E-mail</span></label>
            <input class="input  input__field js-email" type="text" name='email' placeholder="mail@mail.ru"/>
            <span class="input__error js-input-error" name="email"></span>
        </div>`: '';
    data.extra_fields.forEach(i => html += get_template_input_text(i));
    html += data.shareholder ? `<div class="radio input__radio_wrap">
            <label class="radio-button">
                <input type="radio" name="shareholder" value='interested_in_buying' checked required>
                <span class="radio-tick"></span>
                <span class="radio-label">Интересуюсь покупкой</span>
            </label>
            <label class="radio-button">
                <input type="radio" name="shareholder" value="shareholder" required>
                <span class="radio-tick"></span>
                <span class="radio-label">Я дольщик</span>
            </label>
        </div>`: '';
    if (data.add_file_button === true)
      html += data.file_to_download ? `<a class="application-form__link link_accent file_download_link" target="_blank" href="${data.file_to_download}">${data.file_button_title}</a>` : '';
    return html
}

export const get_template_input_text = (data) => {
    return `<div class="input text application-form__input js-input-wrap" data-input="${data.name}">
        <label class="input__label" for="${data.name}"><span>${data.label}</span></label>
        <input class="input__field js-input-validate" type="${data.type}" name='extra_fields.${data.name}' placeholder="${data.placeholder}" required/>
        <span class="input__error js-input-error" data-error='${data.name}'></span>
    </div>`
}

import { conjugation } from '../utils/conjugation.js';
import { favorite } from './favorite_actions.js';
import { comparison } from './comparison_actions.js';
import { loader } from './loader';
import { showModalError } from './modal.js';
import form_option from '../utils/trigget-filters';
import { AbstractSort } from './sort';
import { asyncImg } from '../utils/loadingAsyncImg.js';
import axios from 'axios';

/**
 * @abstruct - абстрактный класс содержит логику пагинации для секции "каталога" расширенный сортировкой
 * @method check_booked - формирует запрос для фильтра "забронированиые" помещения
 * @method get_rooms - определяет запрашиваемый тип помещений и делает запрос
 * @method request - отправляет запрос и скрывает лоадер
 * @method show_loader - показывает лоадер
 * @method send_pagination - отправляет запрос с пагинацией
 * @method render - ставляет верстку и скрывает или показывает информацию отсутствия данных
 * @method count_rooms - выводит колличество помещений
 * @method show_button_pagination - скрывает или показывает кнопку пагинации
 * @method target_sort_tiles - слушатель для сортировки цены для расположения помещений карточками
 */
class AbstractActiontCatalog extends AbstractSort {
  constructor(props) {
    super(props);
    this.wrap_content = props.wrap;
    this.template = props.template;
    this.api = props.api;
    this.form = $(".js-filters");
    this.form_filters = this.form;
    this.btn_pagination = props.btn_pagination;
    this.btn_pagination_on_wrap = props.btn_pagination_on_wrap
    this.count_pagination = props.count_pagination;
    this.count_pagination_last = props.count_pagination_last;
    this.page_size = props.page_size;
    this.loader_name = props.loader_name;
    this.btn_sort = props.btn_sort_table;
    this.select_sort_tiles = props.select_sort_tiles;
    this.empty_block = props.empty_block;
    this.project_type = props.project_type;
    this.count_flat = $(".js-main-count-rooms");
    this.type = null;
    this.page_pagination = 1;
    this.options = {};
    this.apiController = null;
  }
  reset_pagination = () => {
    this.page_pagination = 1
    this.wrap_content.children().remove();
    this.reset_active_sort();
    this.get_rooms({});
  }
  get_rooms = (option) => {
    this.pagination = 1;
    this.wrap_content.children().remove();
    this.options = form_option.get_qwery_string(this.form_filters);
    option = Object.assign({}, this.options, option);

    if (option) {
      if (Number(option.type) === Number(this.project_type)) {
        this.options = option;
        this.request({ ...option, type: this.project_type, page: this.page_pagination, page_size: this.page_size });
      } else {
        this.request({ type: this.project_type, page: this.page_pagination, page_size: this.page_size });
      }
    } else {
      this.request({ ...this.options, type: this.project_type, page: this.page_pagination, page_size: this.page_size });
    }
  }
  send_pagination = () => {
    this.btn_pagination.on("click", () => {
      this.page_pagination = Number(this.page_pagination) + 1;
      this.request({ ...this.options, page: this.page_pagination, page_size: this.page_size });
    })
  }
  request = (option, id = null) => {
    if (this.apiController !== null) {
      this.apiController.cancel();
    }
    this.apiController = axios.CancelToken.source();
    this.defualt_request();
    this.options = Object.assign({}, this.options, option);
    this.page_pagination = this.options.page ?? this.page_pagination;
    const axiosParams = { cancelToken: this.apiController.token };
    const additionalParams = id !== null ? [id, axiosParams] : [axiosParams];
    this.api(this.options, ...additionalParams)
      .then(data => {
        this.apiController = null;
        this.render(data);
        this.count_rooms(data);
      })
      .then(() => {
        favorite.check_active_like();
        comparison.check_active_comparison();
        loader.hidden_loader(this.loader_name);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return;
        }

        switch (err?.response?.status) {
          case 400:
          case 500:
            showModalError($('.js-req-modal__error'));
            break;
        }
      })
  }
  defualt_request = () => {
    this.btn_pagination_on_wrap.removeClass('show');
    loader.show_loader(this.loader_name);
  }
  render = (data) => {
    if (data.results.length) {
      this.wrap_content.append(data.results.map(i => this.template(i)));
      this.show_button_pagination(data);
      this.empty_block.removeClass('show');
      asyncImg.initOnWrap(this.wrap_content);
    } else {
      this.empty_block.addClass('show');
      this.btn_pagination_on_wrap.removeClass('show');
    }
  }
  count_rooms = (data) => {
    if (data.results.length) {
      const count_room = data.count - (this.page_pagination * this.page_size);
      if (12 <= count_room) {
        this.count_pagination_last.text(this.page_size);
        this.count_pagination.text(conjugation(count_room, ['предложения', 'предложений', 'предложений']));
      } else {
        this.count_pagination_last.text(count_room);
        this.count_pagination.text(conjugation(count_room, ['предложение', 'предложения', 'предложений']));
      }
    }
  }
  show_button_pagination = (data) => {
    if (data.next) {
      this.btn_pagination_on_wrap.addClass('show');
    } else {
      this.btn_pagination_on_wrap.removeClass('show');
    }
  }
  target_sort_tiles = () => {
    if (this.select_sort_tiles) {
      this.select_sort_tiles.on('click', (e) => {
        const name = e.target.getAttribute("name");
        const input = $(".js-filter-ordering");
        const sort_value = Boolean(e.target.getAttribute('data-sort'))
        this.wrap_content.children().remove();

        let options = {
          ...this.options,
          page_size: this.page_pagination * this.page_size,
        };
        if (name !== null) {
          const sort_name = sort_value ? name : `-${name}`;
          options.ordering = sort_name;

          input.val(sort_name);
        } else {
          delete options.ordering;
        }

        this.options = options;
        this.request(this.options);
      })
    }
  }
}
/**
 * @class - класс содержит логику реализации абстрактного класса  AbstractActiontCatalog
 */
export class ActionRequestCatalogDefault extends AbstractActiontCatalog {
  constructor(props) {
    super(props)
  }
  init = () => {
    this.send_pagination();
    this.target_sort_tiles();
  }
}
/**
 * @class - класс содержит логику реализации абстрактного класса  AbstractActiontCatalog для детальньной страницы планировки
 */
export class ActionRequestCatalogDetail extends AbstractActiontCatalog {
  constructor(props) {
    super(props)
    this.first_render = null;
    this.wrap_tiles = $('.js-card-detail')
    this.wrap_content = $(`.js-wrap-detail .js-objects-table-content`);
    this.template_table = props.template_table;
    this.template_tiles = props.template_tiles;
    this.id_plan = null;
  }
  init = () => {
    this.send_pagination();
  }
  get_rooms = (id) => {
    this.id_plan = id;
    this.pagination = 1;
    this.first_render = true;
    this.options = {  ...form_option.get_qwery_string(this.filter),page: this.pagination, page_size: this.page_size };
    this.request(this.options, id);
  }
  render = (data) => {
    if (this.first_render) {
      $(`.js-type-detail-title`).text(data.name);
      this.wrap_tiles.html(this.template_tiles(data));
      this.wrap_content.children().remove();
      this.init_wrap(data);
      this.first_render = false;
    } else {
      this.init_wrap(data);
    }
    asyncImg.initOnWrap(this.wrap_content);
  }
  init_wrap = (data) => {
    if (data.properties.results.length) {
      this.wrap_content.append(data.properties.results.map(i => this.template_table(i)));
      this.show_button_pagination(data.properties);
      this.empty_block.removeClass('show');
    } else {
      this.empty_block.addClass('show');
      this.btn_pagination.removeClass('show');
    }
  }
  count_rooms = (data) => {
    const paginations_request = data.properties
    if (paginations_request.results.length) {
      const count_room = paginations_request.count - (this.page_pagination * this.page_size);
      if (12 <= count_room) {
        this.count_pagination_last.text(this.page_size);
        this.count_pagination.text(conjugation(count_room, ['предложения', 'предложений', 'предложений']));
      } else {
        this.count_pagination_last.text(count_room);
        this.count_pagination.text(conjugation(count_room, ['предложение', 'предложения', 'предложений']));
      }
    }
  }
}

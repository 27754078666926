const API_AUTH = "/api/v1/content/cookies_consent"
const API_PROJECT_IN_CITY = "/api/v1/content/projects"
const API_PROJECT = "/api/v1/property/projects";
const API_INF_PROJECT = "/api/v1/property/project_detail";
const API_INFRASTRUCTURE= "/api/v1/property/infrastructure";
const API_FLAT = "/api/v1/property/properties";
const API_OFFICES = '/api/v1/content/salesoffices';
const API_FAVORITE = "/api/v1/content/favorite";
const API_FAVORITE_COUNT = "/api/v1/content/favorite_count"
const API_FAVORITE_PROPERTIES = "/api/v1/property/favorite_properties"
const API_BANKS = '/api/v1/how_buy/banks';
const API_MORTGAGE= '/api/v1/how_buy/hypothec';
const API_INSTALLMENY= '/api/v1/how_buy/installment';
const API_NEWS = '/api/v1/content/news';
const API_PROMOTIONS = '/api/v1/content/promotions';
const API_PARTNERS = '/api/v1/content/partners';
const API_VACANCIES = '/api/v1/content/jobs';
const API_CONSTRUCTIONS = '/api/v1/property/construction_progress';
const API_BOOKING = '/api/v1/appointment/event';
const API_FLOOR = '/api/v1/property/house_floor';
const API_INF_FLAT = '/api/v1/property/house_properties';
export const API_INF_FLAT_TOWNHOUSE = '/api/v1/property/townhouse_properties';
const API_INF_SECTION = '/api/v1/property/floor_properties';
const API_SCHEMA = "/api/v1/property/house_detail";
const API_HOUSE_SECTION = "/api/v1/property/house_section";
const API_SET_BOOKING = '/api/v1/appointment/application';
const API_SET_BOOKING_FLAT = '/api/v1/property/booking';
const API_SEARCH = '/api/v1/property/project_search';
const API_DECOR = '/api/v1/property/apartments_renovations';
const API_NEWS_FILTERS = '/api/v1/content/news_filters';
const API_CITY = '/api/v1/config/city';
const API_FLAT_FILTERS = '/api/v1/property/property_filter';
const API_CATALOG_TYPES = '/api/v1/property/layouts';
const API_PRICE_THIS_DISCOUNT = '/api/v1/property/property_payments';
const API_FILTERS_PARTNERS = '/api/v1/content/partners_categories';
const API_DOWNLOAD_PARTNERS = '/api/v1/content/partners/download';
const API_DOWNLOAD_ROOM = '/api/v1/property/property_pdf';
const API_DOWNLOAD_COMPARISON = '/api/v1/property/comparison/get_pdf/'
const API_ENTRANCE = '/api/v1/property/entrance';
const API_QUEUE_MAP = '/api/v1/property/queue';
const API_QUEUE_PROPS = '/api/v1/property/queue_detail';
const API_FLOOR_INF = '/api/v1/property/floor_inf';
const API_COMPLITED_PROJECTS = '/api/v1/property/completed_projects';
const API_COMPARISON = "/api/v1/property/comparison";
const API_COMPARISON_COUNT = "/api/v1/property/comparison/count";
const API_COMPARISON_PROPERTIES = '/api/v1/property/comparison'
export {
    API_AUTH,
    API_PROJECT,
    API_INF_PROJECT,
    API_FLAT,
    API_INFRASTRUCTURE,
    API_CITY,
    API_OFFICES,
    API_FAVORITE,
    API_BANKS,
    API_MORTGAGE,
    API_INSTALLMENY,
    API_NEWS,
    API_PROMOTIONS,
    API_PARTNERS,
    API_VACANCIES,
    API_CONSTRUCTIONS,
    API_BOOKING,
    API_FLOOR,
    API_INF_FLAT,
    API_INF_SECTION,
    API_SCHEMA,
    API_HOUSE_SECTION,
    API_SEARCH,
    API_SET_BOOKING,
    API_SET_BOOKING_FLAT,
    API_DECOR,
    API_FAVORITE_COUNT,
    API_PROJECT_IN_CITY,
    API_NEWS_FILTERS,
    API_FLAT_FILTERS,
    API_FAVORITE_PROPERTIES,
    API_CATALOG_TYPES,
    API_PRICE_THIS_DISCOUNT,
    API_FILTERS_PARTNERS,
    API_DOWNLOAD_PARTNERS,
    API_DOWNLOAD_ROOM,
    API_ENTRANCE,
    API_QUEUE_MAP,
    API_QUEUE_PROPS,
    API_FLOOR_INF,
    API_COMPLITED_PROJECTS,
    API_COMPARISON,
    API_COMPARISON_COUNT,
    API_COMPARISON_PROPERTIES,
    API_DOWNLOAD_COMPARISON
}

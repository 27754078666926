import { CalcInstallment } from '../components/calculations';
import api from '../api/request';
import { custom_select } from '../components/custom-select';
import form_option from '../utils/trigget-filters';
import {  format_price_full } from '../utils/format_price';
/**
 * @class  класс содержит логику для секции "Рассрочка"
 * @method update_ranges - обновляет фильтры ренжей.
 * @method update_select_house - обновляет кастомный селект "дома"
 * @method listen_filters - слушатель обновления фильтров проекта и дома
 * @method get_payment - запрос информации ипотеки
 */
export class InstallmentCalculation {
    constructor() {
        this.month_price = $(".js-installment-pay");
        this.houses_wrap = $(".js-dropdown-list[name=house]");
        this.first_pay = $('.js-installment-first-pay');
        this.range_year_wrap = $(".js-installment-month");
        this.mortgage_calculation = null;
        this.first_render = true;
    }
    init = () => {
        this.get_payment({ project: custom_select.get_select('project') }, true)
        this.listen_filters();
    }
    update_ranges = (data) => {
        this.mortgage_calculation.update_all({
            price_min: data.min_price,
            price_max: data.max_price,
            day_min: 2,
            day_max: data.max_loan_period,
        })
    }
    update_select_house = (data) => {
        custom_select.remove('house');
        if (data) {
            Object.entries(data).map(([key, value]) => {
                this.houses_wrap.append(this.template_select_house(key, value));
            });
        }
        custom_select.update_select('house');
    }
    listen_filters = () => {
        const self = this;
        $(document).on('click', '.js-custom-select-wrap[name=project] li', function () {
            self.update_select_house();
            const option = form_option.get_options_form(this,['project']);
            self.get_payment(option, true);
        })
        $(document).on('click', '.js-custom-select-wrap[name=house] li', function () {
            const option = form_option.get_options_form(this,['project','house']);
            self.get_payment(option, false);
        })
    }
    get_payment = async (option = {}, rerender_houses = false, update_again = null) => {
        update_again = update_again === null ? option['house'] === undefined : update_again;
        await api.installment(option)
            .then((data) => {
                this.first_pay.attr('data-value-max', data[0].min_installment_initial_payment);
                if (rerender_houses) {
                    this.update_select_house(data[0].houses);
                }
                if (this.first_render) {
                    this.range_year_wrap.attr('data-value-start', data[0].max_loan_period);
                    this.mortgage_calculation = new CalcInstallment();
                    this.mortgage_calculation.init()
                } else {
                    this.mortgage_calculation.default_persent = data[0].min_installment_initial_payment;
                }
                this.update_ranges(data[0]);
                this.mortgage_calculation && this.mortgage_calculation.get();
                this.month_price.text(format_price_full.format(data[0].monthly_payments));
            })
            .then(() => {
                if (this.first_render) {
                    this.first_render = false;
                    this.mortgage_calculation.get();
                }
                if (update_again) {
                    this.get_payment(form_option.get_options_form($('.js-custom-select-wrap[name=project]'), ['project','house']), false, false);
                }
            })
    }
    template_select_house = (id, title) => {
        return `<li class="dropdown__list-item js-dropdown-list-item" data-value="${id}">${title}</li> `
    }
}

import { ActionSvg } from '../components/action_svg';
import { get_local } from '../utils/storege';
import api from '../api/request';
import { SwitchHouseDetails } from '../components/switch_house_detail';
import { get_render_drop_flat, get_render_drop_house, get_template_office, get_render_drop_floor } from "../templates/templates_dropdown_cards";
/**
 * @class -  класс содержит логику для секции "отображения дома"
 * @method render_map - при наличие координат отмечает дом на карте
 * @method target_entrance - выводит информацию при наведение на свг подъезда
 * @method target_floor - выводит информацию при наведение на свг этажа
 * @method target_flat - выводит информацию при наведение на свг помещения
 * @method target_schema - выводит информацию при наведение на помещение в схеме дома
 * @method target_office - - выводит информацию при наведение на свг офиса в доме
 * @method switch_details - инициализирует переключение контейнеров выбора подъезда, этажа, помещения на этаже, схемы
 * @method link_to - при выборе помещения на этаже открывает детальную страницу помещения на новой странице
 * @method init_position_title_for_svg - определяет положение свг и рассчитывает координаты положения title для свг
 */
export class HouseDetail {
    constructor() {
        this.city = get_local('city');
        this.house = document.querySelector(".js-entrances-wrap").getAttribute("data-house");
        this.form_pagination = $(".js-filter-flat-plan");
    }
    init = () => {
        this.target_entrance();
        this.target_floor();
        this.target_flat();
        this.target_schema();
        this.target_office();
        this.switch_details();
        this.link_to();
        this.init_position_title_for_svg();
        this.init_svg_scroll_position();
    }
    //модалка инфо блока и перехода на следующую деталку
    target_entrance = () => {
        const paths_entrances = new ActionSvg({
            project: $(".js-entrances-svg-wrap"),
            project_item: ".js-entrances-svg-wrap [data-house]",
            object_filters: {
                id: "section",
                house: "house"
            },
            api: api.house_inf,
            template: get_render_drop_house,
        });
        paths_entrances.init();
    }
    target_floor = () => {
        const paths_floor = new ActionSvg({
            project: $(".js-floors-svg-wrap"),
            project_item: ".js-floors-svg-wrap path",
            object_filters: {
                floor: "floor"
            },
            template: get_render_drop_floor,
            api: api.floor_inf
        });
        paths_floor.init();
    }
    target_flat = () => {
        const paths_floor_flat = new ActionSvg({
            project: $(".js-floor-flat-svg"),
            project_item: ".js-floor-flat-svg [data-flat]",
            object_filters: {
                flat: "flat"
            },
            template: get_render_drop_flat,
            api: api.flat_inf
        });
        paths_floor_flat.init();
    }
    target_schema = () => {
        const schema_flat = new ActionSvg({
            new_window: true,
            project: $(".js-plan-chema"),
            project_item: ".js-plan-flat-chema",
            object_filters: {
                flat: "flat"
            },
            template: get_render_drop_flat,
            api: api.flat_inf
        });
        schema_flat.init();
    }
    target_office = () => {
        const paths_office = new ActionSvg({
            new_window: true,
            project: $(".js-plan-office-flat"),
            project_item: ".js-plan-office-flat [data-flat]",
            object_filters: {
                flat: "flat"
            },
            template: get_template_office,
            api: api.flat_inf
        });
        paths_office.init();
    }
    switch_details = () => {
        const switcher_on_house_detail = new SwitchHouseDetails();
        switcher_on_house_detail.init();
    }
    link_to = () => {
        $(document).on('click', ".js-floor-flat-svg path", (e) => {
            const links_url = $(e.target).attr('data-link')
            window.open(`${links_url}`, '_blank').focus();
        })
        $(document).on('click', ".js-plan-office-flat path", (e) => {
            const links_url = $(e.target).attr('data-link')
            window.open(`${links_url}`, '_blank').focus();
        })
    }
    init_position_title_for_svg = () => {
        $('.js-entrances-svg-wrap path').each((index, i) => {
            const value = $(i).attr('data-entrances');
            const wrap = $('.js-entrances-svg-wrap');
            const width_title = 50;
            const path_width = i.getBoundingClientRect().width;

            // первый вариант с определнием координат от  контейнера, не определяет положение скрытых path

            // const path_position = $(i).position();
            // const path_position_top = path_position.top;
            // const path_position_left = path_position.left;

            //Второй вариант получения координат из списка координат в path, сделано по туториалу
            // M - начальная точка
            // L - координаты от текущего положения к указаному
            // H - рисует горизонтальную линию
            // V - рисует вертикальную линию
            // Z - рисует линию от последней точки к начальной

            const coordinates_all = $(i).attr("d").split(' ');
            const coordinates = coordinates_all[1].split(/['L','V','H']/g)

            const path_position_top = parseInt((coordinates[0]).split('M'));
            const path_position_left = parseInt((coordinates_all[0]).replace(/\D+/g, " "));

            const top = (path_position_top) * 100 / wrap.height();
            const left = ((path_position_left + (path_width / 2)) - width_title) * 100 / wrap.width();

            this.render_title_for_svg({ y: top, x: left, value: value });
        })
    }
    init_svg_scroll_position = () => {
        if (window.innerWidth <= 768) {
            const element = document.querySelector(".js-scroll-svg-content");
            if (!element) {
                return;
            }

            const image = element.querySelector('.veiw_plan_content_img img');
            if (!image) {
                return;
            }

            const fn = () => {
                element.scrollLeft = (element.scrollWidth - element.clientWidth) / 2;
            }

            // Только если оригинальное изображение загружено, а не изображение-заглушка в плохом качестве.
            if (image.complete && image.src.split(image.dataset.src)[1] === '') {
                fn();
            } else {
                image.addEventListener('load', fn);
            }
        }
    }
    render_title_for_svg = (data) => {
        $('.js-title-for-svg-section').append(`<span class="title-for-svg" style="top:${data.y}%; left: ${data.x}%">${data.value}</span>`);
    }
}

import { get_template_mortage_bank } from '../templates/templates_banks';
import { CalcBanks } from "../components/calculations";
import { PaginationBanks } from "../components/pagination";

/**
 * @class - класс содержит логику для секции "калькулятора ипотеки" на странице "Ипотека"
 * @method open_banks - расскрывает предложения банка в карточке банка
 * @method mortgage_calculation - создает экземпляр класса калькулятора банка и инициализирует его.
 */

export class BanksCalculation {
    constructor() {
        this.project = document.querySelector(".js-plan-project path") && document.querySelector(".js-plan-project path").getAttribute("data-id");
        this.form_pagination = $(".js-banks-filters");
        this.block_wrap_pagination = $(".js-mortgage-banks-wrap");
    }
    init = () => {
        this.open_banks();
        this.mortgage_calculation();
        console.log("================================================================", 'BanksCalculation')
    }
    open_banks = () => {
        $(document).on('click', '.js-bankcard', function () {
            const setClasses = !$(this).hasClass('open');
            $('.js-bankcard').removeClass('open');
            if (setClasses) {
                $(this).toggleClass('open');
            }
        })
    }
    mortgage_calculation = () => {
        const calculation = new CalcBanks(new PaginationBanks({
            wrap_content: this.block_wrap_pagination,
            form: this.form_pagination,
            template: get_template_mortage_bank,
            btn_sort:$('.js-sort-item')
        }));
        calculation.init();
    }
}

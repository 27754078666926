class LocalStore {
  set_local = (name, value) => {
    localStorage.setItem(name, value);
  }
  get_local = (name) => {
    return localStorage.getItem(name);
  }
}

const localStore = new LocalStore()
let set_local = localStore.set_local
let get_local = localStore.get_local

export {
  set_local,
  get_local
}
import api from "../api/request"
import { get_house_card, get_render_drop_project_map, get_render_drop_queue_map, get_house_realize } from "../templates/templates_map_cards"
import form_option from "../utils/trigget-filters"
import { WAY_UP_DEF_IMG } from '../const/way_up'
import { createPopper } from '@popperjs/core'

/**
 * @class  класс содержит логику для секции "карты" и подключения яндекс карт
 * @method createInitialMap - создает экземпляр карты, отрисыоывает в контейнере и отображает элементов наличие управления картой
 * @method zoomControl - создает элементы управления zoom
 * @method update_location - после обновление пинов метод определяет размер и положение карты так чтобы были видны все пины
 * @method resetPlacemarkIconLayout - отправляет запрос и обновляет пины на карте
 * @method addPlacemarksToGeoObject - добавляет пины на карту
 * @method createNewPlacemark - создает объект содержащий все пины карты
 * @method createPlacemarkIconLayout - создает пин
 */
class MapYa {
    constructor(params) {
        this.name = params.mapName;
        this.filter = params.filter ? params.filter : null;
        this.options = params.options ? params.options : {};
        this.houses = params.houses ? params.houses : {};
        this.api = params.api;
        this.map = {};
        this.location = [];
        this.placemark = {};
    }
    init = () => {
        if (Object.keys(this.houses).length !== 0) {
            this.install_params(this.houses);
            return;
        }

        this.request(this.options)
            .then((data) => {
                if (data.results) {
                    this.install_params(data.results);
                } else if (data.length) {
                    this.install_params(data);
                } else {
                    this.hidden_map()
                }
            })
    }
    install_params = (houses) => {
        this.location = houses[0].houses_coordinates;
        for(let i = 0; i < houses.length; ++i) {
            if (houses[i].houses_coordinates.length !== 0) {
                this.location = houses[i].houses_coordinates;
                break;
            }
        }
        this.houses = houses
        if (this.location.length) {
            this.init_map(this.createInitialMap);
            this.filter && this.target_filters()
        } else {
            this.hidden_map();
        }
    }
    init_map(mapCreator) {
        if ($(`#${this.name}`).length) {
            this.map = ymaps.ready(mapCreator)
        }
    }
    target_filters = () => {
        this.filter.on('click', () => {
            this.resetPlacemarkIconLayout()
        })
    }
    createInitialMap = () => { }
    zoomControl = (ymaps) => {
        const zoomControl = new ymaps.control.ZoomControl({
            options: {
                position: {
                    right: 40,
                    top: 300
                },
                size: 'small'
            }
        });
        return zoomControl
    }
    addPlacemarksToGeoObject = () => {
        const placemarks = this.houses.filter(item => {
            if (item.houses_coordinates.length) return item
        })
        const placemarkToGeoObject = placemarks.map((item) => this.createNewPlacemark(item));
        placemarks.push(...placemarkToGeoObject);
        placemarkToGeoObject.forEach(i => this.map.geoObjects.add(i));
        this.update_location()
    }
    update_location = () => {
        if (this.houses.length > 1) {
            const objs = this.map.geoObjects.getBounds();
            const average0 = objs.reduce((total, next) => total + next[0], 0) / objs.length;
            const average1 = objs.reduce((total, next) => total + next[1], 0) / objs.length;
            this.map.setCenter([average0, average1], this.map.getZoom())
        } else {
            this.map.setCenter(this.map.geoObjects.getBounds()[0]);
            this.map.setZoom(this.map.getZoom() + 1);
        }
    }
    createNewPlacemark = (item) => {
        if (item.houses_coordinates.length) {
            this.placemark = new ymaps.Placemark(item.houses_coordinates, {}, {
                iconLayout: this.createPlacemarkIconLayout(item),
            })
            // скрывет название объекта при наведение на маркер
            this.placemark.events.add('mouseleave', () => {
                $('.js-handle-pin').removeClass('pin-active');
                $('.js-handle-text').removeClass('pin-active-text');
            })
            return this.placemark;
        }
    }
    createPlacemarkIconLayout = (item) => {
        const placemarkIcon = ymaps.templateLayoutFactory.createClass(
            `<a class="map-point js-handle-pin">
                <span class="map-point__text js-handle-text">
                    ${item.title}
                </span>
            </a>
            `,
            {
                build: function () {
                    placemarkIcon.superclass.build.call(this);
                    let options = this.getData().options, circleShape = { type: 'Circle', coordinates: [0, 0], radius: 20 };
                    options.set('shape', circleShape);
                }
            }
        );
        return placemarkIcon;
    }
    resetPlacemarkIconLayout = () => {
        this.map.geoObjects.removeAll();
        const option = form_option.get_options_form(this.filter);
        this.request(option)
            .then(data => {
                if (data.results) {
                    this.houses = data.results;
                    if (this.houses.length) {
                        this.centerCoords = this.houses[0].city_coordinates;
                        return true;
                    } else {
                        return false;
                    }
                } else if (data.length) {
                    this.houses = data;
                    this.centerCoords = this.houses[0].city_coordinates;
                    return true;
                } else {
                    return false;
                }
            })
            .then((result) => {
                if (result) {
                    this.addPlacemarksToGeoObject();
                }
            })
    }
    request = async (option) => {
        return await this.api(option);
    }
    hidden_map = () => {
        $('#section_map').addClass('hidden-wrap');
    }
    is_coordinates = (element) => element.houses_coordinates;
}

export class ProjectMap extends MapYa {
    constructor(options) {
        super(options);
        this.active_flilter = null;
    }
    createInitialMap = () => {
        if (!ymaps) return null
        this.map = new ymaps.Map(this.name, {
            center: this.location,
            zoom: 13,
            controls: []
        });
        this.addPlacemarksToGeoObject();
        this.map.controls.add(this.zoomControl(ymaps));
        // this.map.behaviors.disable('scrollZoom');
    }
    createPlacemarkIconLayout = (item) => {
        let info = '';
        switch (item.project_front_type) {
            case 0:
                info = get_render_drop_project_map(item);
                break;
            case 2:
                info = get_render_drop_queue_map(item);
                break;
            case 1:
                info = get_house_card(item);
                break;
            default:
                info = get_house_realize(item)
        }
        const placemarkIcon = ymaps.templateLayoutFactory.createClass(
            `<a class="map-point js-handle-pin"  href='${item.absolute_url}'>
                    <span class="map-point__text js-handle-text">
                        ${item.title}
                    </span>
                    <div class='coords-map-card js-point-info-wrap'>
                        ${info}
                    </div>
                </a>`,
            {
                build: function () {
                    placemarkIcon.superclass.build.call(this);
                    let options = this.getData().options, circleShape = { type: 'Circle', coordinates: [0, 0], radius: 30 };

                    options.set('shape', circleShape);
                    const event = this.getData().geoObject.events
                    const getTargetElem = (className) => this.getParentElement().getElementsByClassName(className)[0]

                    const targetPin = getTargetElem('js-handle-pin');
                    const flyCard = $(targetPin).find('.js-point-info-wrap');

                    const popper = createPopper(targetPin, flyCard[0], {
                        placement: 'auto',
                        modifiers: [
                            {
                                name: 'preventOverflow',
                                enabled: true,
                                phase: 'main',
                            },
                            {
                                name: 'offset',
                                options: {
                                  offset: [0, 20],
                                },
                            },
                        ]
                    });

                    event.add('mouseenter', () => {
                        popper.forceUpdate();
                        $(".js-point-info-wrap").removeClass('active-card');
                        flyCard.addClass('active-card');
                        targetPin.classList.add('pin-active');
                        getTargetElem('js-handle-text').classList.add('pin-active-text');
                    }).add('mouseleave', () => {
                        $(".js-point-info-wrap").removeClass('active-card');
                    }).add('click', () => {
                        window.location = targetPin.href;
                    })
                }
            }
        );
        return placemarkIcon;
    }

}

export class CompletedProjectMap extends ProjectMap {
    constructor(options) {
        super(options);
    }

    createInitialMap = () => {
        if (!ymaps) return null
        this.map = new ymaps.Map(this.name, {
            center: this.location,
            zoom: 10,
            controls: []
        });
        this.addPlacemarksToGeoObject();
        this.map.controls.add(this.zoomControl(ymaps));
    }
}

export class HouseMap extends MapYa {
    constructor(options) {
        super(options)
    }
    createInitialMap = () => {
        if (!ymaps) return null
        this.map = new ymaps.Map(this.name, {
            center: this.location,
            zoom: 15,
            controls: []
        });

        this.addPlacemarksToGeoObject();
        this.map.controls.add(this.zoomControl(ymaps));
    }
    addPlacemarksToGeoObject = () => {
        const placemarkToGeoObject = this.createNewPlacemark(this.houses[0]);
        this.map.geoObjects.add(placemarkToGeoObject);
    }
    createNewPlacemark = (item) => {
        this.placemark = new ymaps.Placemark(item.houses_coordinates, {}, {
            iconLayout: this.createPlacemarkIconLayout(item),
        })

        // скрывет название объекта при наведение на маркер
        this.placemark.events.add('mouseleave', () => {
            $('.js-handle-pin').removeClass('pin-active')
            $('.js-handle-text').removeClass('pin-active-text')
        })
        return this.placemark
    }

    createPlacemarkIconLayout = (item) => {
        let info = '';
        switch (item.type) {
            case 0:
                info = get_house_card(item);
                break;
            case 1:
                info = get_render_drop_queue_map(item);
                break;
        }
        const placemarkIcon = ymaps.templateLayoutFactory.createClass(
            `<div class="map-point-img js-handle-pin">
                <div class="js-handle-text">
                    ${item.house_preview ?
                `<img src=${item.house_preview}>`
                : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
                </div>
            </div>`,
            {
                build: function () {
                    placemarkIcon.superclass.build.call(this);
                    let options = this.getData().options, circleShape = { type: 'Circle', coordinates: [0, 0], radius: 60 };
                    options.set('shape', circleShape);
                }
            }
        );
        return placemarkIcon;
    }
}

export class OfficeMap extends MapYa {
    constructor(options) {
        super(options)
    }
    createInitialMap = () => {
        if (!ymaps) return null
        this.map = new ymaps.Map(this.name, {
            center: this.location,
            zoom: 18,
            controls: []
        });
        this.addPlacemarksToGeoObject();
        this.map.controls.add(this.zoomControl(ymaps));
    }
    addPlacemarksToGeoObject = () => {
        const placemarkToGeoObject = this.houses.map((item) => this.createNewPlacemark(item))
        this.houses.push(...placemarkToGeoObject)
        for (let i = 0; i < placemarkToGeoObject.length; i++) {
            this.map.geoObjects.add(placemarkToGeoObject[i])
        }
        // this.map.setBounds(this.map.geoObjects.getBounds(), { checkZoomRange: true, zoomMargin: 9 });
    }
    createNewPlacemark = (item) => {
        const placemark = new ymaps.Placemark(item.houses_coordinates, {}, {
            iconLayout: this.createPlacemarkIconLayout(item),
        })
        return placemark;
    }
    createPlacemarkIconLayout = (item) => {
        const placemarkIcon = ymaps.templateLayoutFactory.createClass(
            `<div class='map-point-img'>
                <img src="${item.project_preview ? item.project_preview : WAY_UP_DEF_IMG}" alt="office">
            </div>`,
            {
                build: function () {
                    placemarkIcon.superclass.build.call(this);
                    let options = this.getData().options, circleShape = { type: 'Circle', coordinates: [0, 0], radius: 20 };
                    options.set('shape', circleShape);
                }
            }
        );
        return placemarkIcon;
    }
}
export class ProjectDetailMap extends MapYa {
    constructor(options) {
        super(options)
    }
    createInitialMap = () => {
        if (!ymaps) return null
        this.map = new ymaps.Map(this.name, {
            center: this.location,
            zoom: 15,
            controls: []
        });

        this.addPlacemarksToGeoObject();
        this.map.controls.add(this.zoomControl(ymaps));
        this.map.behaviors.disable('scrollZoom');
    }
    createNewPlacemark = (item) => {
        this.placemark = new ymaps.Placemark(item.houses_coordinates, {}, {
            iconLayout: this.createPlacemarkIconLayout(item),
        })
        return this.placemark;
    }
    createPlacemarkIconLayout = (item) => {
        const placemarkIcon = ymaps.templateLayoutFactory.createClass(
            `<div class="map-point-img js-handle-pin">
                <div class="js-handle-text">
                    ${item.project_preview ?
                `<img src=${item.project_preview} alt='project'>`
                : `<img src="${WAY_UP_DEF_IMG}" alt="">`}
                </div>
            </div>`
        );
        return placemarkIcon;
    }
}

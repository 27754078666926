import api from "../api/request";
import { get_local } from '../utils/storege';
/**
 * @class  класс содержит логику для секции "лендинг мероприятия"
 * @method render_map - отображает дом на карте
 * @method set_booking_landing - отправляет запись на мероприятие
 */
export class LandingBooking {
    constructor() {
        this.city = get_local('city');
        this.project = $(".js-project-id").attr("id");
    }
    init = () => {
        this.set_booking_landing();
    }
    set_booking_landing = () => {
        let collection_options = {};
        $(document).on('submit', '.js-landing-form', (e) => {
            e.preventDefault()
            collection_options.event = $('.js-event-id').val();
            collection_options.first_name = $('.js-landing-name').val().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
            collection_options.phone_number = $('.js-landing-number').val().replace(/[^0-9]/ig, '');
            collection_options.email = $('.js-landing-email').val();
            collection_options.shareholder = $('.js-landing-status').val();
            $('.js-input-error').removeClass('show');
            $('.js-input-error').text('');
            api.set_booking(collection_options)
                .then((messege) => {
                    $('.js-finish-registration').text(messege);
                    $('.js-form-landing').addClass('hidden-wrap');
                })
                .catch(err => {
                    const error = err.response.data;
                    if (error.name && error.name[0]) {
                        $(".js-input-wrap[data-input=name_user]").addClass('form-error');
                        $('.js-input-error[data-error=name_user]').text(error.name[0]);
                    }
                    if (error.phone_number && error.phone_number[0]) {
                        $(".js-input-wrap[data-input=phone_number]").addClass('form-error');
                        $('.js-input-error[data-error=phone_number]').text(error.phone_number[0]);
                    }
                });
        })
    }
}

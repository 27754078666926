class AsyncImg {
    constructor() {
        this.images = document.querySelectorAll('.js-async-image');
    }
    init() {
        if (this.images.length) {
            this.loadingImg(this.images)
        }
    }
    initOnWrap(wrap){
       const images = wrap[0].querySelectorAll('.js-async-image');
        if (images.length) {
            this.loadingImg(images);
        }
    }
    loadingImg(images) {
        images.forEach((item) => {
            const img = new Image();
            img.src = item.dataset.src;
            img.onload = () => {
                item.classList.remove('js-async-image');
                item.src = item.dataset.src
            };
        });
    }
}

export const asyncImg = new AsyncImg ();
asyncImg.init()

import { format_price_intl } from "../utils/format_price"
export const get_template_mortage_bank = (data) => {
    let text = `<div class="bank-card-wrap js-bankcard" data-id=${data.id}>
    <div class="bank-card  bank-card_default">
        <div class="bank-card__name">
            <div class="bank-card__img">
                <img src="${data.logo}" alt="logo">
            </div> 
            <div class="bank-card__title">${data.bank_name}</div>
        </div>
        ${data.products[0] ? `<div class="bank-card__rate"><span class="bank-card_sub-name">Ставка</span> от ${data.products[0].rate} %</div>` : '<div class="bank-card__rate"></div>'} 
        ${data.products[0] ? `<div class="bank-card__fee"><span class="bank-card_sub-name">Первый взнос</span> от ${data.products[0].initial_payment} %</div>` : '<div class="bank-card__fee"></div>'}
        ${data.products[0] ? `<div class="bank-card__term"><span class="bank-card_sub-name">Срок кредита</span> до ${data.products[0].loan_period} лет</div>` : '<div class="bank-card__term"></div>'}
        ${data.products[0] ? `<div class="bank-card__payment"><span class="bank-card_sub-name">Ежемесячный платеж</span> ${format_price_intl.format(data.products[0].monthly_payments)} </div>` : '<div class="bank-card__payment"></div>'}
        <div class="bank-card__drop js-bank-dropdown">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 6L8 11L3 6" stroke="#8B98A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    </div>
    <div class="bank-card-content">
    `
    data.products && data.products.forEach(i => {
        text += `
        <div class="bank-card bank-card_inner">
            <div class="bank-card__name">
                <p class="bank-card__title">${i.product_name}</p>
            </div>
            <div class="bank-card__rate"><span class="bank-card_sub-name">Ставка</span> от ${i.rate} %</div>
            <div class="bank-card__fee"><span class="bank-card_sub-name">Первый взнос</span> от ${i.initial_payment} %</div>
            <div class="bank-card__term"><span class="bank-card_sub-name">Срок кредита</span> до ${i.loan_period} лет</div>
            <div class="bank-card__payment"> <span class="bank-card_sub-name">Ежемесячный платеж</span> ${format_price_intl.format(i.monthly_payments)} </div>
        </div>`})

    return text += `</div></div>`
}

export const get_logo_bank = (data) => {
    return data.map(i =>
        `<div class="bank-logo">
            <div class="bank-card__img"><img src="${i}" alt="logo"></div>
        </div>` )
}
import { RangesDefualt, RangeDeadline, RangeFloor } from '../components/ranges';
import form_option from '../utils/trigget-filters';
import { AbstractCheckbox } from '../components/check_box';
import api from '../api/request';
import { parseOptionInString } from '../utils/parse_url';
import { SetValueCatalofFilters } from '../components/set_value_catalog_filters';
import { SetActiveCatalogFilters } from '../components/set_active_catalog_filters';
/**
 * @class -  класс содержит логику для секции показывающей кол-во квартир от выбранных фильтров
 * @method init_ranges - инициализирует ренжи
 * @method first_request - отпределяет параметры фильтров и делает первый запрос
 * @method link_estate - собирает qwery строку и переводит на страницу выбора недвижимости
 * @method target_range - слушает событие изменения состояние ренжей
 * @method request - отправляет запрос на кол-ва квартир
 */
export class SectionFlatCount extends AbstractCheckbox {
  constructor(props) {
    super(props)
    this.set_value_filters = new SetValueCatalofFilters();
    this.set_active_filters = new SetActiveCatalogFilters();
    this.range_price_input = $(".js-flat_count-price");
    this.range_area_input = $('.js-flat_count-area');
    this.range_floor_input = $('.js-flat_count-floor');
    this.range_deadline_input = $('.js-flat_count-deadline');
    this.filter_flat_count = $(".js-filters[data-name=main]");
    this.range_price = null;
    this.range_area = null;
    this.range_floor = null;
    this.range_deadline = null;
    this.count_wrap = $(".js-main-count-rooms");
    this.choice_wrap = $(".js-choice__wrap");
    this.choice_inputs = $(".js-choice-option__input");
  }
  init = () => {
    this.init_ranges();
    this.first_request();
    this.link_estate();
    this.target_range();
    this.toggle_active_status_checkbox();
    this.change_check_box();
  }
  init_ranges() {
    if (this.range_price_input.length) {
      this.range_price = new RangesDefualt({
        range: this.range_price_input
      });
      this.range_price.init();
    }
    if (this.range_area_input.length) {
      this.range_area = new RangesDefualt({
        range: this.range_area_input
      });
      this.range_area.init();
    }
    if (this.range_floor_input.length) {
      this.range_floor = new RangeFloor({
        range: this.range_floor_input
      });
      this.range_floor.init();
    }
    if (this.range_deadline_input.length) {
      this.range_deadline = new RangeDeadline({
        range: this.range_deadline_input
      });
      this.range_deadline.init();
    }
  }
  first_request = () => {
    const params = form_option.get_qwery_string(this.filter_flat_count);
    this.request(params);
    this.request_filter();
  }
  link_estate = () => {
    const url = $(".js-link-estate").attr('data-url');
    $(document).on("click", ".js-link-estate", (e) => {
      e.preventDefault()
      let new_options = form_option.get_qwery_string(this.filter_flat_count);
      let options_string = parseOptionInString(new_options);
      document.location.href = `${url}/?${options_string}`;
    })
  }
  target_range = () => {
    this.range_price &&
      this.range_price.get_range().noUiSlider &&
      this.range_price.get_range().noUiSlider.on('change', () => {
        this.request();
        this.request_filter();
      })
    this.range_area &&
      this.range_area.get_range().noUiSlider &&
      this.range_area.get_range().noUiSlider.on('change', () => {
        this.request();
        this.request_filter();
      })
    this.range_floor &&
      this.range_floor.get_range().noUiSlider &&
      this.range_floor.get_range().noUiSlider.on('change', () => {
        this.request();
        this.request_filter();
      })
    this.range_deadline &&
      this.range_deadline.get_range().noUiSlider &&
      this.range_deadline.get_range().noUiSlider.on('change', () => {
        this.request();
        this.request_filter();
      })
  }
  change_check_box = () => {
    const self = this
    this.choice_inputs.change(function (e) {
      let value = $(this).val().toLowerCase()
      $(`.js-choice-option[data-value=${value}-rooms]`).toggleClass('active_radio');
      let results = [];
      if ($(this).is(":checked")) {
        $(this).closest(".input").addClass("active_filter");
      } else {
        self.choice_inputs.map((index, item) => {
          if (item.checked) {
            results.push(true);
          } else {
            results.push(false);
          }
        })
        if (!results.some((e) => e === true)) {
          $(this).closest(".input").removeClass("active_filter");
        }
      }
      self.request();
      self.active_filters();
      self.request_filter();
    })
  }
  request = () => {
    const params = form_option.get_qwery_string(this.filter_flat_count);
    api.flat_count({ ...params, page_size: 1 })
      .then(data => this.render(data));
  }
  request_filter = async () => {
    const params = form_option.get_qwery_string(this.filter_flat_count);
    await api.get_catalog_filters(params)
      .then(data => {
        this.set_value_filters.render_values_filters(data,
          {
            price: this.range_price,
            floor: this.range_floor,
            area_total: this.range_area,
          })
      })
      .then(() => {
        if (this.first_render) {
          this.first_render = false;
          this.active_filters();
        }
      })
  }
  render = (data) => {
    if (data) {
      this.count_wrap.text(data.count);
    }
  }
}

/**
 * @class -  класс содержит логику для секции блока "Вопросы и ответы"
 * @method show_detail_faq - переключает видимость ответа на вопрос
 */

export class SectionFAQ {
  constructor() {}
  init = () => {
    this.show_detail_faq();
  }
  show_detail_faq = () => {
    $(document).on("click", ".js-faqcard", (e) => {
      const setClasses = !$(e.currentTarget).hasClass('active');
      $('.js-faq-card__answer').css({ 'display': '' });
      if (setClasses) {
        $(".js-faqcard").removeClass('active');
        $(e.currentTarget).addClass('active');
        $(e.currentTarget).find('.js-faq-card-header').next().slideToggle(200);
      } else {
        $(".js-faqcard").removeClass('active');
      }
    })
  }
}

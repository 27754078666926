import { custom_select } from "./custom-select";
import { seporator_params_in_object } from "../utils/parse_url";
/**
 * @class - Устанавливает значения фильтров из url строки
 */
export class SetActiveCatalogFilters {
  constructor(){}
  install_active_filters = (params,filters) => {
    const object_options = seporator_params_in_object(params);
    for (let key in object_options) {
      const value = object_options[key];
      if (value){
       switch (key) {
        case 'project':
        case 'development_quarter':  
        case 'house':
        case "heating_matters":
        case "bathroom":
        case 'queue': 
        case 'facing':
        case 'material':
        case 'garage_or_carport':
          custom_select.install_value(key,value);
          break;
        case 'price':
        case 'area_total':
        case 'area_kitchen':
        case "floor":
        case "plot_area": 
          this.set_ranges(filters[key],value);
          break;
        case "not_first_floor":
        case "not_last_floor":
        case "dressing_room":
        case 'private_entrance': 
        case 'with_booked':
        case 'with_sales':
          this.checkbox_active(key,value);
          break;
        case 'is_studio':
        case 'rooms':
          this.active_choice(filters[key],key,value,true);
          break;
        case "decor":
          this.decor_active(value);
          break;
       }
      }
    }
  }
  decor_active = (value) =>{
    const item_decor = $(`.js-filter-decor[data-value=${value}]`)
    item_decor.addClass("active_filter");
    item_decor.find('input').attr('checked',true);
  }
  checkbox_active = (key,value) =>{
    if (value) {
      const item = $(`.js-filter-checkbox[data-name=${key}]`);
      item.addClass("active_filter");
      item.find('input').attr('checked',true);
    }
  }
  active_choice = (wrap,key,value) => {
    if (value.constructor === Array) {
      value.map(value_item => {
        $(`.js-choice-option[data-value=${value_item}-rooms]`).addClass('active_radio');
        this.add_active_status_choice(wrap,key, value_item);
      })
    } else {
      if(value === "True"){
        $(`.js-choice-option[data-value=true-rooms]`).addClass('active_radio');
      } else {
        $(`.js-choice-option[data-value=${value}-rooms]`).addClass('active_radio');
      }
      this.add_active_status_choice(wrap,key,value);
    }
  }
  add_active_status_choice = (wrap,key, value) => {
    const elems = wrap.find(`[name=${key}]`);
    if (elems.length) {
      elems.map((ind, item) => {
        if (String($(item).val()).toLowerCase() === String(value).toLowerCase()) {
          $(item).attr('checked', true);
          wrap.addClass("active_filter");
        }
      })
    }
  }
  set_ranges = (item,values) =>{
    item &&
    item.get_range().noUiSlider &&
    item.get_range().noUiSlider.set([values[0],values[1]]);
  }
}

import { sliders } from "../components/sliders";
/**
 * инициализирует создает экземпляры слайдеров в проекте и экспортит экземпляры
 */
const slider_top = sliders.slider_on_1_slide({ wrap: '.js-mainslider', next_nav: '.js-mainslider-next', prev_nav: '.js-mainslider-prev', pagination: '.js-mainslider-pagination' });
const slider_discount = sliders.slider_on_1_slide({ wrap: '.js-discount-slider', next_nav: '.js-discount-slider-next', prev_nav: '.js-discount-slider-prev' });
const slider_landing_gallery = sliders.slider_on_1_slide({ wrap: '.js-landing-gallery', next_nav: '.js-landing-gallery-next', prev_nav: '.js-landing-gallery-prev', pagination: '.js-landing-gallery-pagination' });
const slider_construction = sliders.slider_on_1_slide({ wrap: '.js-constructionslider', next_nav: '.js-constructionslider-next', prev_nav: '.js-constructionslider-prev', pagination: '.js-constructionslider-pagination' });
const slider_decor = sliders.slider_on_1_slide({ wrap: '.js-decorslider', next_nav: '.js-decorslider-next', prev_nav: '.js-decorslider-prev', pagination: '.js-decorslider-pagination' });
const flat_slider = sliders.slider_on_1_slide({ wrap: '.js-flat-photo-swiper', next_nav: '.js-flat-photo-swiper-next', prev_nav: '.js-flat-photo-swiper-prev' });

const slider_news_and_promotions = sliders.slider_on_3_slide({ wrap: '.js-news-slider', next_nav: '.js-news-slider-next', prev_nav: '.js-news-slider-prev' });
const slider_gallary = sliders.slider_on_3_slide({ wrap: '.js-gallery-slider', next_nav: '.js-gallery-slider-next', prev_nav: '.js-gallery-slider-prev' });
const slider_entrance = sliders.slider_on_3_slide({ wrap: '.js-entrance-slider', next_nav: '.js-entrance-slider-next', prev_nav: '.js-entrance-slider-prev' });
const slider_awards = sliders.slider_on_3_slide({ wrap: '.js-awards-slider', next_nav: '.js-awards-slider-next', prev_nav: '.js-awards-slider-prev' });
const slider_vacansies = sliders.slider_on_3_slide({ wrap: '.js-vacansies-slider', next_nav: '.js-vacansies-slider-next', prev_nav: '.js-vacansies-slider-prev' });
const slider_realized_houses = sliders.slider_on_3_slide({ wrap: '.js-realized-houses-slider', next_nav: '.js-realized-houses-next', prev_nav: '.js-realized-houses-prev' });

const slider_manager = sliders.slider_on_4_slide({ wrap: '.js-manager-slider', next_nav: '.js-manager-slider-next', prev_nav: '.js-manager-slider-prev' });
const slider_instagram = sliders.slider_on_4_slide({ wrap: '.js-mainphotosslider', next_nav: '.js-mainphotosslider-next', prev_nav: '.js-mainphotosslider-prev' });
const slider_group_company = sliders.slider_on_4_slide({ wrap: '.js-groupcompany-slider', next_nav: '.js-groupcompany-slider-next', prev_nav: '.js-groupcompany-slider-prev' });
const slider_advantages = sliders.slider_on_4_slide({ wrap: '.js-advantages-slider', next_nav: '.js-advantages-slider-next', prev_nav: '.js-advantages-slider-prev' });
const slider_like_flat = sliders.slider_on_4_slide({ wrap: '.js-like-flat-slider', next_nav: '.js-like-flat-slider-next', prev_nav: '.js-like-flat-slider-prev' });
const comparison_slider = sliders.comparison_slider({ wrap: '.js-comparison-swiper', next_nav: '.js-comparison-section__btn--next', prev_nav: '.js-comparison-section__btn--prev' });

const slider_how_to_buy = sliders.how_to_buy_slider()
const schema_slider = sliders.schema_slider();
const floor_slider = sliders.slider_floors();

export {
    floor_slider,
    schema_slider,
    slider_manager,
    slider_decor,
    slider_construction,
    comparison_slider
}

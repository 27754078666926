import { RangesDefualt } from "../components/ranges";
import {
    get_template_house_schema,
    get_template_townhouse_schema
} from "../templates/templates_schema"
import { AbstractCheckbox } from "../components/check_box";
import api from "../api/request"
import { loader } from "../components/loader";
import form_option from "../utils/trigget-filters";
import { schema_slider } from "./section_sliders";

/**
 * абстрактный класс "схемы" для детальной страници дома и очереди таунхаусов
 * @method first_render - первый запрос
 * @method init_ranges - инициализирует ренжи
 * @method target_ranges - слушатель событий для ренжей
 * @method request - отправляет запрос и обновляет слайдер
 * @method render - обновляет верстку
 * @method reset_form -сбрасывает фильтры
 * @method toggle_show_contols - скрывает и показывает кнопки управления слайдером
 */

class AbstractSchema extends AbstractCheckbox {
    constructor(props) {
        super(props)
        this.form = $(".js-form-schema");
        this.schema_content = $('.js-schemas-content')
        this.btn_reset = $(".js-form-reset");
        this.controls_wrap = $(".js-schema-slider-controls");
        this.swichers_plan = $('.js-switch-plan')
        this.range_price_wrap = props.range_price_wrap;
        this.range_area_wrap = props.range_area_wrap;
        this.range_price = null;
        this.range_area = null;
        this.loader_schema = 'schema';
    }
    first_render = () => {
        this.request();
    }
    init_ranges = () => {
        if (this.range_price_wrap) {
            this.range_price = new RangesDefualt({
                range: this.range_price_wrap
            });
            this.range_price.init();
        }
        if (this.range_area_wrap) {
            this.range_area = new RangesDefualt({
                range: this.range_area_wrap
            });
            this.range_area.init();
        }
    }
    target_ranges = () => {
        this.range_price &&
            this.range_price.get_range().noUiSlider &&
            this.range_price.get_range().noUiSlider.on('change', () => {
                this.request();
                this.active_filters();
            })
        this.range_area &&
            this.range_area.get_range().noUiSlider &&
            this.range_area.get_range().noUiSlider.on('change', () => {
                this.request();
                this.active_filters();
            })
    }
    request = () => {
        loader.show_loader(this.loader_schema);
        this.schema_content.addClass('hidden-wrap');
        const option = form_option.get_qwery_string(this.range_price.get_range());
        api.object_schema(option)
            .then(data => {
                loader.hidden_loader(this.loader_schema);
                if (data.results[0]) {
                    this.schema_content.removeClass('hidden-wrap');
                    this.toggle_show_contols(data)
                    this.render(data);
                } else {
                    this.swichers_plan.addClass('hidden-wrap');
                }
            })
            .then(() => schema_slider.update())
            .catch((e) => {
                console.log(e);
            })
    }
    render = () => { }
    toggle_show_contols = () => { }
    reset_form = () => {
        this.btn_reset.on('click', () => {
            this.form.find(".active_filter").removeClass("active_filter");
            this.form.find('.js-choice-option').removeClass("active_radio");
            $(".js-project-id").addClass("active_filter");
            this.range_price &&
                this.range_price.get_range().noUiSlider &&
                this.range_price.get_range().noUiSlider.reset();
            this.range_area.get_range().noUiSlider &&
                this.range_area.get_range().noUiSlider.reset();
            this.active_filters();
            this.request();
        })
    }
}

/**
 * @class HouseSchema- Схема для деталки дома
 */
export class HouseSchema extends AbstractSchema {
    constructor(props) {
        super(props)
        this.wrap = $(".js-schemas-wrap");
        this.init();
    }
    init = () => {
        this.init_ranges();
        this.change_check_box();
        this.target_ranges();
        this.reset_form();
        this.first_render();
    }
    render = (data) => {
        this.wrap.html(
            Object.entries(data.results[0].sections)
                .sort((item, item2) => parseInt(item[0]) - parseInt(item2[0]))
                .map(([key, value]) => get_template_house_schema(key, value))
            )
    }
    toggle_show_contols = (data) => {
        if (Object.keys(data.results[0].sections).length < 2) {
            this.controls_wrap.addClass('hidden-wrap');
        } else {
            this.controls_wrap.removeClass('hidden-wrap');
        }
    }
}

/**
 * @class TownhouseSchema - Схема для деталки очереди таунхауса
 */
export class TownhouseSchema extends AbstractSchema {
    constructor(props) {
        super(props)
        this.wrap = $(".js-schemas-wrap");
        this.init();
    }
    init = () => {
        this.init_ranges();
        this.target_ranges();
        this.reset_form();
        this.first_render();
    }
    render = (data) => {
        this.wrap.html(data.results[0].houses.map(slide => get_template_townhouse_schema(slide)))
    }
    toggle_show_contols = (data) => {
        if (data.results[0].houses.length < 2) {
            this.controls_wrap.addClass('hidden-wrap');
        } else {
            this.controls_wrap.removeClass('hidden-wrap');
        }
    }
}

const serializer_construction = (data) =>{
    const new_data = {
        year:[],
        months:[],
        item:{}
    };
    const month_title = {
        1:"Январь",
        2:"Февраль",
        3:"Март",
        4:"Апрель",
        5:"Май",
        6:"Июнь",
        7:"Июль",
        8:"Август",
        9:"Сентябрь",
        10:"Октябрь",
        11:"Ноябрь",
        12:"Декабрь",
    };
    if(data.results.length){
        new_data.year =data.results[0].year;
        data.results.forEach(j => new_data.months.push({ id:j.month,title:month_title[j.month] }));
        new_data.months = new_data.months.sort((a,b) => b.id-a.id);
        new_data.item = data.results.find(i=> Number(i.month) === Number(new_data.months[0].id) && i);
    }
    return new_data;
}
export default serializer_construction

import { WAY_UP_DEF_IMG } from '../const/way_up'
export const get_construction_img = (data) => {
    let url = data.url;
    let miniUrl = data.mini_url;

    return `<div class="swiper-slide">
                <a class="construction-slider__img" href="${url}" data-fancybox="construction">
                ${url ?
                    `<img class="flat-map__img js-async-image" src="${miniUrl}" data-src="${url}" alt='construction' loading='lazy'>`
                    : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
                </a>
            </div>`
}

export const get_construction_content = (data) => {
    return `
        <div class="construction-content">
        ${data.title ? `<h3>${data.title}</h3>` : ''}
        ${data.description ?
            `<div>
                ${data.description}
            </div>`: ''}
        </div>`
}

export const get_construction_month = (data) => {
    return `<li class="dropdown__list-item js-dropdown-list-item" data-value='${data.id}'>${data.title}</li>`
}

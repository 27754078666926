import { createPopper } from '@popperjs/core';
/**
 * @class -  класс содержит логику для секции "навигации с якорями" на cтраниц проекты, дома, очередь таунхаусов
 * @method resize_window - слушает событие изменения размерове экрана
 * @method init_nav_section - в зависимости от разрешения скрывает меню навигации
 * @method init_popover - инициализирует поповер
 * @method check_width - проверяешь ширину экрана и контейнера
 * @method init_category_wrap - определяет активный тип проектов и показывает соответствующий контейнер
 * @method true_check - убирает ссылку в выпадающей список якорей
 * @method false_check - достает ссылку из выпадающей список якорей
 * @method check_btn_more - проверяет нужно ли показывать кнопку "еще" для отображения выпадающего списка якорей что не уместились на экране
 * @method drop_contents - слушает событие в выпадающием списке с якорями и переключает видимость выпадающего списка

 */
export class ProjectMenu {
    constructor() {
        this.container = document.querySelector('.js-project-menu-container');
        this.wrap = document.querySelectorAll(".js-project-nav-wrap");
        this.button_drop_menu = document.querySelector('.js-drop-trigger');
        this.nav_section = document.querySelector('.js-project-nav-section');
        this.width_container = null;
        this.padding = 30;
        this.width_wrap = null;
        this.min_width = 769;
    }
    init = () => {
        this.resize_window();
        this.init_popover();
        this.init_nav_section();
        this.drop_contents();
    }
    init_popover = () => {
        createPopper(document.querySelector(".js-drop-more"), document.querySelector(".js-drop-more-content-sub"), {
            placement: 'bottom',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        });
    }
    resize_window = () => {
        window.addEventListener('resize', () => {
            this.init_nav_section();
        })
    }
    init_nav_section = () => {
        if (parseInt(window.innerWidth) > this.min_width) {
            this.nav_section.classList.add('show')
            this.check_width();
        } else {
            this.nav_section.classList.remove('show')
        }
    }
    check_width = () => {
        this.width_container = parseInt(this.container.getBoundingClientRect().width) - this.padding * 2;
        if (this.nav_section.classList.contains('show')) {
            if (this.width_container < this.get_width_wraps()) {
                this.true_check();
                this.check_btn_more();
            } else {
                this.false_check();
                this.check_btn_more();
            }
        }
    }
    update_width = () => {
        const links = document.querySelectorAll(".js-project-nav .js-project-nav-item.show")
        if (links.length) {
            if (parseInt(window.innerWidth) > this.min_width & this.width_container < this.get_width_wraps()) {
                this.check_width();
            }
        }
    }
    get_width_wraps = () => {
        return parseInt(this.wrap[0].getBoundingClientRect().width + this.wrap[1].getBoundingClientRect().width + this.wrap[2].getBoundingClientRect().width)
    }
    true_check = () => {
        const links = document.querySelectorAll(".js-project-nav .js-project-nav-item.show");
        const drop_links = document.querySelectorAll(".js-drop-project-nav .js-drop-project-nav-item.hidden");
        const num_link = links.length - 1;
        if (num_link >= 0) {
            links[num_link].classList.add('hidden');
            links[num_link].classList.remove('show');
            drop_links[num_link].classList.add('show');
            drop_links[num_link].classList.remove('hidden');
            this.update_width();
        }
    }
    false_check = () => {
        const links = document.querySelectorAll(".js-project-nav .js-project-nav-item.hidden");
        const drop_links = document.querySelectorAll(".js-drop-project-nav .js-drop-project-nav-item.show");
        if (links.length) {
            links[0].classList.add('show');
            links[0].classList.remove('hidden');
            drop_links[0].classList.add('hidden');
            drop_links[0].classList.remove('show');
            this.update_width();
        }
    }
    check_btn_more = () => {
        const drop_links = document.querySelectorAll(".js-drop-project-nav li.show");
        if (drop_links.length) {
            this.button_drop_menu.classList.add('show');
        } else {
            this.button_drop_menu.classList.remove('show');
        }
    }
    drop_contents = () => {
        const sub_content = $(".js-drop-project-nav");
        const arrow = $(".js-drop-more__trigger-icon");
        $(document).on("click", ".js-drop-more", function () {
            arrow.toggleClass("rotate");
            sub_content.toggleClass('show');
        })
        $(document).on("click", function (e) {
            const target = e.target.closest('.js-drop-trigger');
            if (target !== $('.js-drop-trigger')[0]) {
                arrow.removeClass("rotate");
                sub_content.removeClass('show');
            }
        })

        Array.from(document.querySelectorAll('.js-project-nav .js-drop-project-nav-item a')).forEach(el => {
            el.addEventListener('click', () => {
                arrow.toggleClass("rotate");
                sub_content.toggleClass('show');
            });
        })
    }
}

import { ProjectMap } from "../components/map";
import { get_local } from "../utils/storege";
import { get_card_project_index } from '../templates/templates_projects';
import { PaginationProjectIndex } from "../components/pagination";
import api from "../api/request";
import { SwitcherMap } from "../components/swicher_map";
/**
 * @class -  класс содержит логику для секции "список проектов" на главной странице
 * @method render_map - при наличие координат инициализирует карту и инициализирует табы переключения с карты на список проектов
 * @method init_map - создает экземпляр класса карты
 * @method pagination_project - создает экземпляр класса пагинации
 */
export class ProjectHomeList {
    constructor() {
        this.city = get_local('city');
        this.map = null;
        this.block_wrap_pagination = $(".js-projects-wrap");
    }
    init = () => {
        api.project_map({ city: this.city })
            .then((data) => {
                this.init_map(data.results)
                this.pagination_project(data);
            })
    }
    init_map = (data) => {
        const city_coordinates = data[0].city_coordinates;
        this.map = new ProjectMap({
            mapName: "map",
            centerCoords: city_coordinates,
            house: data,
            api: api.project_map
        });
        this.map.init(this.map.createInitialMap);
        const switch_map = new SwitcherMap({ map: this.map });
        switch_map.init();
    }
    //пагинация
    pagination_project = (data) => {
        const pagination = new PaginationProjectIndex({
            wrap_content: this.block_wrap_pagination,
            template: get_card_project_index,
            api: api.projects_list,
            init_data: data,
            loader_name: 'project'
        });
        pagination.init();
        pagination.first_render();
    }
}

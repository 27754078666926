import { get_template_partner, template_catigories_partners, template_catigories_partners_list } from '../templates/templates_partner';
import { DefaultPagination } from '../components/pagination'
import api from '../api/request';
import { custom_select } from '../components/custom-select';
import { API_DOWNLOAD_PARTNERS } from "../api/api_list"

/**
 * @class  класс содержит логику для секции "список партнеров" 
 * @method render_first_filter - устанавливе активный город в фильтр городов и запрашивает данные фильтров
 * @method request_filter - отправляет запрос на получнеия данных фильтров и отрисовывает их
 * @method pagination_partners - создает экземпляр класса пагинации 
 * @method search_partners - слушает фильтр поиска партнера по названию
 * @method switch_category - переключает категории связываю кастомынй стелект категории и радиокнопки категории
 * @method target_category - слушает фильтры категорий и категорию кнопку с категорией в карточке партнера
 * @method target_city - слушает фильтр города
 * @method download_partners - открывает в новом окне ссылку на скачивание списка партнеров
 */

export class PartnersList {
    constructor() {
        this.input_search_partners = $(".js-search-partners");
        this.wrap_partners = $(".js-partner-wrap");
        this.form_pagination = $(".js-partners-filters");
        this.block_wrap_pagination = $(".js-partner-wrap");
        this.radio_button_category = $(".js-filter-radio");
        this.wrap_category = $(".js-partners-category-wrap")
        this.wrap_category_list = $('.js-category-list')
        this.pagination = null;
    }
    init = () => {
        this.render_first_filter();
        this.target_category();
        this.pagination_partners();
        this.search_partners();
        this.switch_category();
        this.target_city();
        this.download_partners();
    }
    render_first_filter = () => {
        let value = $(".js-set-city li").attr("data-value");
        let options = {}
        if (value) {
            options = { city: value };
        }
        this.request_filter(options);
    }
    pagination_partners = () => {
        this.pagination = new DefaultPagination({
            form: this.form_pagination,
            wrap_content: this.block_wrap_pagination,
            template: get_template_partner,
            btn_sort: $(".js-sort-item"),
            api: api.get_partners
        });
        this.pagination.init();
    }
    search_partners = () => {
        this.input_search_partners.on('keydown', (e) => {
            if (e.key === "Enter") {
                this.pagination.reset_pagination();
            }
        })
        this.input_search_partners.on('blur', () => {
            this.pagination.reset_pagination();
        })
    };
    request_filter = (options) => {
        api.get_filters_partners(options)
            .then(data => {
                this.wrap_category.html(template_catigories_partners({ id: '', title: 'Все' }));
                this.wrap_category.append(data.catigories.map(i => template_catigories_partners(i)));
                this.wrap_category_list.html(template_catigories_partners_list({ id: '', title: 'Все' }));
                this.wrap_category_list.append(data.catigories.map(i => template_catigories_partners_list(i)));
            }).then(() => {
                this.set_value_radiobutton_category();
                this.set_value_select_category();
                this.pagination.reset_pagination();
            })
    }
    switch_category = () => {
        $(document).on("click", 'js-partners-category', (e) => {
            let id = String(e.target.id)
            $(`.js-partners-category#${id}`).prop("checked", true);
            this.pagination.reset_pagination();
        })
    }
    target_city = () => {
        $(document).on('click', '.js-set-city li', (e) => {
            this.request_filter({ city: $(e.target).attr('data-value') });
        })
    }
    render_partners = (data) => {
        this.wrap_partners.children().remove();
        data.map(i => {
            this.wrap_partners.append(get_template_partner(i));
        })
    }
    target_category = () => {
        let prev_id = ''
        $(document).on('click', '.js-partners-category', (e) => {
            const id = $(e.target).val();
            if (Number(prev_id) !== Number(id)) {
                prev_id = id;
                this.set_value_select_category(id);
                this.set_value_radiobutton_category(id)
                this.pagination.reset_pagination();
            }
        })
        $(document).on('click', '.js-select-category li', (e) => {
            const id = $(e.target).attr("data-value");
            if (Number(prev_id) !== Number(id)) {
                prev_id = id;
                this.set_value_radiobutton_category(id);
                this.pagination.reset_pagination();
            }
        })
        $(document).on('click', ".js-category-card", (e) => {
            const id = $(e.target).attr("id");
            if (Number(prev_id) !== Number(id)) {
                prev_id = id;
                this.set_value_select_category(id);
                this.set_value_radiobutton_category(id);
                this.pagination.reset_pagination();
            }
        })
    }
    set_value_select_category = (id = '') => {
        const item_select = $(`.js-select-category li`)
        const item = id ? $(`.js-select-category li[data-value='${id}']`)[0] : item_select[0];
        custom_select.set_value($(item))
    }
    set_value_radiobutton_category = (id = '') => {
        const radio_button_category = $(`.js-partners-category`)
        radio_button_category.prop('checked', false)
        const item = id ? $(`.js-partners-category[value=${id}]`)[0] : radio_button_category[0];
        $(item).prop("checked", true);
    }
    download_partners = () => {
        $('.js_download_partners').on('click', (e) => {
            e.preventDefault();
            window.open(`${API_DOWNLOAD_PARTNERS}`, '_blank').focus();
        })
    }
}

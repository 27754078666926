/**
 * @class  класс содержит логику для секции "деталка новости"
 * @method wrap_tables - оборачивает все таблицы в блок с overflow: scroll
 */
export class ArticleDetail {
    constructor() {
        this.tables = document.querySelectorAll('.js-article-content table');
    }
    init = () => {
        this.wrap_tables();
    };
    wrap_tables = () => {
        this.tables.forEach(table => {
            const wrapper = document.createElement('div');
            wrapper.classList.add('news-detail__table');
            table.parentNode.insertBefore(wrapper, table);
            wrapper.appendChild(table);
        })
    };
}

import { get_card_news_and_promotions } from '../templates/templates_news_and_promotions';
import { DefaultPagination } from "../components/pagination";
import { get_city } from "../components/get-city";
import { custom_select } from "../components/custom-select";
import api from "../api/request";
import form_option from "../utils/trigget-filters";
import { get_history, set_history } from "../utils/history_store";
import { get_tempalte_select_option } from "../templates/templates_filters";
import {get_local} from "../utils/storege";
/**
 * @class  класс содержит логику для секции "список акций"
 * @method first_render - отрисовывает фильтр города, получает из history состояние и передает их в запрос
 * @method target_* - слушатель фильтра, содержит логику с обновлением фильтров
 * @method pagination_promotions - создает экземпляр класса пагинации
 * @method request_project - запрос списка проектов в городе
 * @method update_select_projects -обновляет фильтр проектов
 */
export class PromotionsList {
    constructor() {
        this.city = null
        this.form_pagination = $(".js-promotions-filters");
        this.block_wrap_pagination = $(".js-discount-wrap");
        this.projects_wrap = $(".js-projects-list");
        this.empty_wrap = $(".js-wrap-empty");
        this.pagination = {};
    }
    init = () => {
        this.target_city();
        this.target_projects();
        this.pagination_promotions();
        this.first_render();
    }
    first_render = () => {
        get_city.render_city_select($(".js-set-city"), true)
            .then(() => {
                const params = get_history();
                if (!params.city) {
                  params.city = get_local("city");
                }
                this.request_project(params);
            })
    }
    target_city = () => {
        $(document).on('click', '.js-set-city li', (e) => {
            this.city = $(e.target).attr("data-value");
            set_history({ city: this.city });
            this.request_project({ city: this.city });
            this.pagination.reset_pagination();
        })
    }
    target_projects = () => {
        $(document).on('click', '.js-projects-list li', () => {
            const params = form_option.get_options_form(this.form_pagination);
            set_history(params);
            this.pagination.reset_pagination();
        })
    }
    pagination_promotions = () => {
        this.pagination = new DefaultPagination({
            form: this.form_pagination,
            wrap_content: this.block_wrap_pagination,
            template: get_card_news_and_promotions,
            city: this.city,
            api:api.get_promotions
        });
        this.pagination.init();
    }
    request_project = (options) => {
        api.get_project_in_city(options)
            .then(data => {
                this.update_select_projects(data);
            })
            .then(() => {
                custom_select.install_value('project', options.project);
                custom_select.install_value('city', options.city);
            })
            .then(() => {
                this.pagination.reset_pagination();
            })
            .catch(err => {
                switch (err?.response?.status) {
                    case 404:
                    case 500:
                        showModalError($('.js-req-modal__error'));
                        this.pagination.hidden_btn();
                        break;
                }
            })
    }
    update_select_projects = (data) => {
        custom_select.remove('project');
        this.projects_wrap.append(get_tempalte_select_option({ id: "", title: "Все проекты" }));
        Object.entries(data).map(([key, value]) => {
            this.projects_wrap.append(get_tempalte_select_option({ id: key, title: value }));
        });
    }
}

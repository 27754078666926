
import { TownhouseSchema } from '../sections/section_delail_object_schema';
/**
 * @class - класс содержит логику переключения между контейнерами в секции "отображение очереди таунхаусов"
 * @method init_schema - создает экземпляр класса управляющей слайдером схемы
 * @method switch_schema_plan -  слушает радиокнопку, делает активным план дома или схему дома
 */
export class SwitchTownhouseQueue {
    constructor() {
        this.plan_queue = $(".js-plan-queue-wrap");
        this.schema_plan = $(".js-plan-chema");
        this.schema_project_items = $(".js-plan-queue path");
        this.project_header = $(".js-project-plan-detal-header");
    }
    init = () => {
        this.init_schema();
        this.hidden_schema();
        this.switch_schema_plan();
    }
    init_schema = () => new TownhouseSchema({
        range_price_wrap: $(".js-price-flat"),
        range_area_wrap: $(".js-area-flat")
    })
    switch_schema_plan = () => {
        $(".js-switch-plan").on("change", (e) => {
            const value = String(e.target.value);
            if (value === '3d') {
                this.show_wrap_3d();
            } else {
                this.show_wrap_schema();
            }
        })
    }
    hidden_schema = () => {
        this.schema_plan.css({ "display": "none" });
    }
    show_wrap_3d = () => {
        this.plan_queue.css({ "display": "" });
        this.schema_plan.css({ "display": "none" });
        this.project_header.css({ "position": "" });
    }
    show_wrap_schema = () => {
        this.plan_queue.css({ "display": "none" });
        this.schema_plan.css({ "display": "" });
        this.project_header.css({ "position": "initial" });
    }
}
import { HouseSchema } from '../sections/section_delail_object_schema';
import { getAllUrlParams } from '../utils/parse_url';
import { SliderFloorsHouse } from './slider_floors_house'
import api from "../api/request";
import { WAY_UP_DEF_IMG } from '../const/way_up';
import { loader } from './loader';
/**
 * @class - класс содержит логику переключения между контейнерами в секции "отображения дома"
 * @method init_type_flat_plan - определяет из qwery  строки какой контейнер сделать активным
 * @method init_schema - создает экземпляр класса управляющей слайдером схемы и запускает метод переключения между планом дома и схемой
 * @method init_slider_floors - создает экземпляр класса управляющий слайдером этажей
 * @method target_entrances_wrap - слушает нажатие на path подъезда, делает запрос на получшение этажей дома и делает активным контейнер выбора этажа
 * @method switch_schema_plan -  слушает радиокнопку, делает активным план дома или схему дома
 * @method switch_type_flat_plan - слушает радиокнопку, делает активным план дома или коммерческого этажа
 * @method step_back - переключает к предыдущему контейнеру
 */

export class SwitchHouseDetails {
    constructor() {
        this.project_header = $(".js-project-plan-detal-header");
        this.plans = $('.js-project-plan-detal');
        this.plan_office = $(".js-plan-office");
        this.view_plan = $(".js-view-plan");
        this.button_back = $("#js-section-back");

        this.filters_top = $(".js-object-filters");
        this.address_top = $(".js-object-address");

        this.step = 1;

        this.schema_house_items = $(".js-entrances-svg-wrap path");

        this.entrances_wrap = $(".js-entrances-wrap");
        this.entrances_svg_wrap = $(".js-entrances-svg-wrap");
        this.view_entrances = this.schema_house_items.length > 1 | !$(".js-entrances-svg-wrap path").attr('data-section')  ? true : false;

        this.entrances_detail_wrap = $(".js-floors-wrap");
        this.entrances_detail_img = $(".js-floors-img");
        this.entrances_detail_content = $('.js-floors-content')
        this.entrances_detail_svg_wrap = $('.js-floors-svg-wrap');
        this.entrances_detail_img_prev = null;

        this.floor_detail_wrap = $(".js-floor-detail-wrap");

        this.schema_plan = $(".js-plan-chema");
        this.is_render_schema = true;

        this.commerce_paths = $(".js-plan-office-flat path");
        this.houses_switch_btns = $(".js-houses-switch-btns");
        this.floors_slider = null;
        this.loader_entrances = 'entrances';
    }
    init = () => {
        this.init_type_flat_plan();
        this.init_schema();
        this.init_slider_floors();
        this.switch_type_flat_plan();
        this.target_entrances_wrap();
        this.show_floor_detail_wrap();
        this.step_back();
    }
    init_type_flat_plan = () => {
        this.show_main_plan(true);
        const hasCommerce = this.commerce_paths.length;
        const hasHouses = this.schema_house_items.length > 1;

        if (!hasCommerce || !hasHouses) {
            this.houses_switch_btns.addClass('hidden-wrap');
        }

        if (!hasCommerce) {
            this.show_plan_house();
            return;
        }

        const get_params = getAllUrlParams();
        if (get_params?.property === 'commercial' || !hasHouses) {
            this.show_plan_commercial();
            return;
        }

        this.show_plan_house();
    }
    init_schema = () => {
        new HouseSchema({
            range_price_wrap: $(".js-price-flat"),
            range_area_wrap: $(".js-area-flat"),
        });
        this.switch_schema_plan();
    }
    init_slider_floors = () => {
        this.floors_slider = new SliderFloorsHouse();
        this.floors_slider.init();
    }
    // показывает свг этажа
    target_entrances_wrap = () => {
        this.entrances_svg_wrap.on('click', (e) => {
            const id_entrances = e.target.getAttribute("data-section");
            if (id_entrances) {
                this.request_entrances_detail(id_entrances);
                this.show_entrances_detail(false);
            }
        })
    }
    request_entrances_detail = (id_entrances) => {
        this.entrances_detail_content.addClass("hidden-wrap")
        loader.show_loader(this.loader_entrances);
        api.get_entrance({ section: id_entrances })
            .then((data) => {
                loader.hidden_loader(this.loader_entrances);
                this.entrances_detail_content.removeClass("hidden-wrap")
                this.render_entrances_detail(data);

            })
            .catch((e) => {});
    };
    //отрисосвывает этажи в подъезде
    render_entrances_detail = (item) => {
        if (this.entrances_detail_img_prev !== item.picture) {
            this.entrances_detail_img_prev = item.picture;
            if (item.picture) {
                this.entrances_detail_img.attr('src', item.picture);
            } else {
                this.entrances_detail_img.attr('src', WAY_UP_DEF_IMG);
            }
        }
        this.entrances_detail_svg_wrap.html(item.svg);
    }
    show_floor_detail_wrap = () => {
        this.entrances_detail_svg_wrap.on('click', (e) => {
            const id_floor = e.target.getAttribute("data-floor");
            if (id_floor) {
                this.floors_slider.request_plan_floor(true, id_floor)
                this.show_floor_detail();
            }
        })
    }
    switch_schema_plan = () => {
        this.is_render_schema = false;
        $(".js-switch-plan").on("change", (e) => {
            let value = String(e.target.value);
            if (value === '3d') {
                this.show_main_plan(false);
            } else {
                this.show_schema();
            }
        })
    }
    switch_type_flat_plan = () => {
        this.view_plan.on("click", (e) => {
            let id = e.target.value;
            if (id === 'house') {
                this.show_main_plan(false);
                this.show_plan_house();
            } else {
                this.show_plan_commercial();
            }
        })
    }

    step_back = () => {
        this.button_back.on('click', (e) => {
            e.preventDefault()
            if (this.step > 1) {
                this.step--;
            }
            switch (this.step) {
                case 1:
                    this.show_house();
                    break;
                case 2:
                    this.show_entrances_detail(false);
                    break;
                case 3:
                    this.show_floor_detail();
                    break;
            }
        })
    }
    show_house = () => {
        this.entrances_wrap.css({ "display": "" });
        this.schema_plan.css({ "display": "none" });
        this.entrances_detail_wrap.css({ "display": "none" });
        this.floor_detail_wrap.css({ "display": "none" });
        this.project_header.css({ "position": "" });
        this.filters_top.css({ "display": "" });
        this.address_top.css({ "display": "none" });
        this.button_back.css({ "display": "none" });
    }
    show_schema = () => {
        this.entrances_wrap.css({ "display": "none" });
        this.entrances_detail_wrap.css({ "display": "none" });
        this.floor_detail_wrap.css({ "display": "none" });
        this.schema_plan.css({ "display": "" });
        this.project_header.css({ "position": "initial" });
    }
    show_entrances_detail = (request = false) => {
        this.step = 2;
        this.filters_top.css({ "display": "" });
        this.address_top.css({ "display": "none" });
        this.entrances_wrap.css({ "display": "none" });
        this.schema_plan.css({ "display": "none" });
        this.entrances_detail_wrap.css({ "display": "" });
        this.floor_detail_wrap.css({ "display": "none" });
        this.project_header.css({ "position": "" });
        if (this.view_entrances) {
            this.button_back.css({ "display": "" });
        } else {
            this.button_back.css({ "display": "none" });
        }
        if (request) {
            const id_entrances = $(".js-entrances-svg-wrap path").attr('data-section');
            if (id_entrances) {
                this.request_entrances_detail(id_entrances);
            } else {
                loader.hidden_loader(this.loader_entrances);
            }
        }
    }
    show_floor_detail = () => {
        this.step = 3;
        this.filters_top.css({ "display": "none" });
        this.floor_detail_wrap.css({ "display": "" });
        this.address_top.css({ "display": "" });
        this.entrances_wrap.css({ "display": "none" });
        this.entrances_detail_wrap.css({ "display": "none" });
        this.button_back.css({ "display": "" });
        this.project_header.css({ "position": "initial" });
    }
    show_plan_commercial = () => {
        this.plans.addClass("hidden-wrap");
        this.plan_office.removeClass("hidden-wrap");
    }
    show_plan_house = () => {
        this.plans.removeClass("hidden-wrap");
        this.plan_office.addClass("hidden-wrap");
    }
    show_main_plan = (request) => {
        if (this.view_entrances) {
            this.show_house();
        } else {
            this.show_entrances_detail(request);
        }
    }
}

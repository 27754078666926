import { FlyCard } from "./fly_card";
/**
 * @class -класс содержит логику отслеживания наведения на свг, отправляет запрос на получение информации
 * @method move_mouse_event - слушатель события наведения на свг в зависимости от типа свг либо делает запрос на эндпоинт, либо берет данные из атрибута
 * @method init_options - собирает свойства из из атрибутов и вщзвращает объект для запроса.
 * @method request_api - отпрпавляет запрос и передает верстку в class FlyCard для рендера и отслеживания положения
 * @method request - берет данные из дата атрибута и передает верстку в class FlyCard для рендера и отслеживания положения
 */
export class ActionSvg {
  constructor(props, disableOnMobile = true) {
    this.new_window = props.new_window;
    this.api = props.api;
    this.object = props.project;
    this.object_items = props.project_item;
    this.object_filters = props.object_filters;
    this.links_url = null;
    this.template = props.template;
    this.disable_on_mobile = disableOnMobile;
    self.options_prev = null;
  }

  init = () => {
    this.move_mouse_event();
    this.init_fly_card();
  }
  move_mouse_event = () => {
    const self = this;
    $(document).on("mouseenter", this.object_items, function(e) {
      if (self.disable_on_mobile && window.innerWidth <= 810) {
        return;
      }
      if (self.api) {
        const options = self.init_options(this);
        if (JSON.stringify(self.options_prev) !== JSON.stringify(options))
          self.options_prev = options;
          self.request_api(options, e);
      } else {
        self.request($(this).attr("data-description"), e);
      }
    })
  }
  init_fly_card = () => {
    this.fly_card = new FlyCard(this.disable_on_mobile);
    this.fly_card.init();
  }
  init_options = (path) => {
    const options = {};
    Object.entries(this.object_filters).forEach(([i, j]) => options[i] = $(path).attr(`data-${j}`))
    return options;
  }
  request_api = (options = '', event) => {
    this.fly_card.show_loader();
    this.api(options)
      .then(data => {
        if (typeof data === "object") {
          if (data.results) {
            this.fly_card.mouse_event(this.template(data.results[0]), event);
          } else {
            this.fly_card.mouse_event(this.template(data), event);
          }
        } else {
          this.fly_card.mouse_event(this.template(data[0]), event);
        }
      })
      .catch(e => {})
  }
  request = (data, event) => {
    this.fly_card.mouse_event(this.template(data), event);
  }
}

import { getAllUrlParams } from '../utils/parse_url';
import form_option from '../utils/trigget-filters';
import {
  get_template_card_object_tiles_notype,
  get_template_card_object_table_notype,
  get_template_card_townhouse_tiles_notype,
  get_template_card_townhouse_table_notype,
  get_template_card_office_tiles_notype,
  get_template_card_office_table_notype,
  get_template_card_object_table_notype_short,
  get_template_card_object_tiles_type,
  get_template_card_object_table_type,
  get_template_card_detail
} from '../templates/templates_catalog';
import api from '../api/request';
import { RangeFloor, RangesDefualt } from '../components/ranges';
import { SetValueCatalofFilters } from '../components/set_value_catalog_filters';
import { SetActiveCatalogFilters } from '../components/set_active_catalog_filters';
import { ActionRequestCatalogDetail, ActionRequestCatalogDefault } from '../components/action_catalog';
import FlyCardPrompt from '../components/fly_card_prompt';
import ZoomImage from "../components/zoom_image"
import { custom_select } from '../components/custom-select';
import { add_history, add_query_to_history, get_history } from '../utils/history_store';
import { loader } from '../components/loader';
import { conjugation } from '../utils/conjugation';

/**
 * @class - класс содержит логику для секции блока "Каталог" на странице "Выбор недвижимости"
 * @param params - объект содержащий данные фильтров
 * @method init_history - делает запрос истории асинхронным
 * @method run_page - получает данные из history, записывает в params, устанавливает значения фильтров, устанавливает автывный контейнер
 * @method send_filter - устанавливает значения фильтров в qwery строку и перезагружает с ними страницу
 * @method reset_active_filter - удаляет активное состояние у фильтров
 * @method reset_pagination - откадывает пагинацию до первой страницы
 * @method reset_filter - скидывает значения фильтров
 * @method active_filters - устанавливает значения из qwery строки в фильтры
 * @method init_actions_filters - инициализирует слушатели событий фильтров
 * @method init_value_filters - запрашивает и устанавливает значения в фильтра в зависимости от выбранного фильтра
 * @method get_count_active_filters - определяет кол-во активных фильтров
 * @method init_wraps - определяет активный контейнер, активные фильтры и сортировки к активному контейнеру.
 * @method show_subfilter - слушает событие нажатия на "расширенные фильтры", метод показывает и скрывает доп.фильтры
 * @method switch_content - слушает события переключающие контейнеры каталогов,запрашивает колличество доступных помещений в выбраном типе помещений, записывает состояние history
 * @method init_action_requesе_* - все методы с эти префиксом инициазируют пагинацию и сортировку в пагинации.
 * @method init_ranges - инициализирует ренжи в фильтрации
 * @method init_checkbox - инициализирует checkboxs в фильтрации и сортировки
 * @method active_zoom_image - инициализирует модалку с увеличиной картинкой
 * @method init_prompt_block - инициализирует модалку с подсказкой
*/

export class CatalogList {
  constructor() {
    this.params = getAllUrlParams();
    this.set_value_filters = new SetValueCatalofFilters();
    this.set_active_filters = new SetActiveCatalogFilters();
    this.url_href = window.location.href.split('?')[0];
    this.active_filter_count = $(".js-send-filter-count");
    this.wrap_nottype = $('.js-wrap-nottype');
    this.wrap_type = $('.js-wrap-type');
    this.wrap_detail = $('.js-type-detail');
    this.wrap_sort = $(".js-subsort-wrap");
    this.btn_reset = $(".js-form-reset");
    this.checkboxs = $(".js-filter-checkbox");
    this.btn_position = $(".js-position");
    this.more = $(".js-drop-more-filters");
    this.moretext = $(".drop-more__trigger-text");
    this.arrow = $(".drop-more__trigger-icon");
    this.prompt = $(".header-options__item-prompt");
    this.count_flat = $(".js-main-count-rooms");
    this.sort_btn = $('.js-sort-table');
    this.form_filters = $(".js-filters");
    this.choice_wrap = $(".js-choice__wrap");
    this.choice_inputs = $(".js-choice-option__input");
    this.filter_house = $('.js-subfilters[data-filter-type=house');
    this.filter_area = $('.js-subfilters[data-filter-type=area');
    this.pagination_btn_flat = $(".js-nottype-pagination-flat");
    this.pagination_btn_townhouse = $(".js-nottype-pagination-townhouse");
    this.pagination_btn_commerce = $(".js-nottype-pagination-commerce");
    this.pagination_btn_type = $(".js-type-pagination");
    this.booked = false;
    this.sort_value = false;
    this.display = false;
    this.detail_plan = false;
    this.coollection_filters = {};
    this.wrap_content = null;
    this.id_project = null;
    this.project_type = null;
    this.action_request_nottype_flat_tiles = null;
    this.action_request_nottype_townhouse_tiles = null;
    this.action_request_nottype_commerce_tiles = null;
    this.action_request_nottype_flat_table = null;
    this.action_request_nottype_townhouse_table = null;
    this.action_request_nottype_commerce_table = null;
    this.action_request_type_tiles = null;
    this.action_request_type_table = null;
    this.action_request_detail = null;
    this.position = null;
    this.type = null;
    this.range_price = null;
    this.range_area = null;
    this.range_area_kitchen = null;
    this.range_floor = null;
    this.range_plot_area = null;
    this.modal_error = $('.js-req-modal__error');
    this.first_render = true;
    this.filter_booked = $(".js-filter-booked");
  }
  init = () => {
    this.show_subfilter();
    this.switch_content();
    this.init_action_request_nottype_flat_tiles();
    this.init_action_request_nottype_townhouse_tiles();
    this.init_action_request_nottype_commerce_tiles();
    this.init_action_request_type_tiles();
    this.init_action_request_nottype_flat_table();
    this.init_action_request_nottype_townhouse_table();
    this.init_action_request_nottype_commerce_table();
    this.init_action_request_type_table();
    this.init_action_request_detail();
    this.init_ranges();
    this.init_checkbox();
    this.run_page();
    this.send_filter();
    this.reset_filter();
    this.init_prompt_block();
    this.active_zoom_image();
  }
  init_history = async () => {
    return await get_history()
  }
  run_page = () => {
    this.init_history()
      .then((params) => {
        this.project_type = this.params.type ? this.params.type : '0';
        this.position = params.position ? params.position : 'tiles';
        this.type = params.sort_type === 'type' || this.params.plan ? 'type' : 'nottype';
      })
      .then(() => {
        this.init_actions_filters();
      })
      .then(() => {
        this.init_value_filters();
        this.get_count_active_filters();
      })
      .then(() => {
        this.init_wraps();
      })
  }
  init_actions_filters = () => {
    this.target_ranges();
    this.target_select();
    this.target_select_type();
    this.target_checkbox();
  }
  init_value_filters = () => {
    this.install_switcher_btn('js-sort-type', this.type);
    this.install_switcher_btn('js-position', this.position);
    custom_select.install_value('type', this.project_type);
    this.request_filter({ type: this.project_type }).then(() => {
      let options = {};
      if (!$.isEmptyObject(this.params)) {
        options = this.params;
      }
      options.type = this.project_type;
      this.get_catalog(options);
      this.get_count_flat(options);
    })
  }
  init_wraps = () => {
    this.checked_arrow();
    this.show_btn_type();
    this.show_sort_price();
    this.show_house_filter();
    this.visible_contrent(this.params.type_content);
  }
  get_catalog = (options) => {
    this.action_request_nottype_flat_tiles.get_rooms(options);
    this.action_request_nottype_townhouse_tiles.get_rooms(options);
    this.action_request_nottype_commerce_tiles.get_rooms(options);
    this.action_request_nottype_flat_table.get_rooms(options);
    this.action_request_nottype_townhouse_table.get_rooms(options);
    this.action_request_nottype_commerce_table.get_rooms(options);
    this.action_request_type_table.get_rooms(options);
    this.action_request_type_tiles.get_rooms(options);
  }
  install_switcher_btn = (name, value) => {
    $(`.${name}`).removeClass('active_radio');
    $(`.${name}[data-value=${value}]`).addClass("active_radio");
  }
  show_subfilter = () => {
    this.more.on("click", (e) => {
      this.display = !this.display;
      this.checked_arrow();
    });
  }
  switch_content = () => {
    //переключает фильтры
    $(document).on('click', ".js-filter-type li", (e) => {
      this.hidden_content();
      this.project_type = $(e.target).attr('data-value');
      add_history({ project_type: this.project_type, position: this.position, sort_type: this.type });
      this.visible_contrent();
      this.show_filters();
      this.checked_arrow();
    })
    // переключает контент списком или блоками
    $(document).on('change', '.js-position', (e) => {
      this.hidden_content();
      this.position = String(e.target.value);
      add_history({ project_type: this.project_type, position: this.position, sort_type: this.type })
      this.install_switcher_btn('js-position', this.position);
      this.visible_contrent();
      this.show_sort_price();
    })
    // переключает контент на типы
    $(document).on('change', ".js-sort-type", (e) => {
      this.type = String(e.target.value);
      this.install_switcher_btn('js-sort-type', this.type);
      add_history({ project_type: this.project_type, position: this.position, sort_type: this.type });
      this.get_count_flat();
      this.visible_contrent();
    })
    // показывает деталку карточки "по типам"
    const self = this;
    $(document).on('click', ".js-link-info-block", function (e) {
      self.hidden_content();
      const id_type = $(this).attr("data-id");
      self.action_request_detail.get_rooms(id_type);
      self.show_type_detail();
    })
    // скрывает деталку карточки "по типам"
    $(".js-back-link-type-card").on('click', () => {
      this.show_wraps_on_type()
    })
  }
  show_wraps_on_type = () => {
    this.wrap_detail.removeClass('show');
    this.wrap_sort.removeClass('hidden-wrap');
    this.wrap_type.addClass('show');
    this.visible_contrent();
  }
  show_type_detail = () => {
    this.wrap_type.removeClass('show');
    this.wrap_detail.addClass('show');
    this.wrap_sort.addClass('hidden-wrap');
  }
  show_sort_price = () => {
    if (this.position === 'tiles') {
      $('.js-block-sort-price').removeClass('show');
    } else {
      // Если отображение списком - мы выключаем стандартную сортировку по цене
      // и сбрасываем её.
      $('.js-block-sort-price').addClass('show');
      $(".js-price-sort ul li:first-child").trigger('click');
    }
  }
  show_btn_type = () => {
    switch (Number(this.project_type)) {
      case 0:
        $(".js-block-type").removeClass('show');
        break;
      case 1:
        $(".js-block-type").addClass('show');
        this.type = 'nottype';
        this.install_switcher_btn('js-sort-type', this.type);
        this.visible_contrent();
        break;
      case 2:
        $(".js-block-type").addClass('show');
        this.type = 'nottype';
        this.install_switcher_btn('js-sort-type', this.type);
        this.visible_contrent();
        break;
    }
  }
  // делает видимым контент контейнер
  visible_contrent = (type_content = false) => {
    switch (type_content) {
      case 'detail':
        this.action_request_detail.get_rooms(this.params.plan);
        this.show_type_detail();
        break;
      case 'type':
        this.type = 'type';
        this.wrap_type.addClass('show')
        this.wrap_nottype.removeClass('show')
        this.wrap_content = $(`.js-estae-wrap#${this.project_type}-${this.position}-${this.type}`);
        this.wrap_content.addClass('show');
        break;
      default:
        if (this.type === 'type') {
          this.wrap_type.addClass('show')
          this.wrap_nottype.removeClass('show')
        } else {
          this.wrap_type.removeClass('show')
          this.wrap_nottype.addClass('show')
        }
        this.wrap_content = $(`.js-estae-wrap#${this.project_type}-${this.position}-${this.type}`);
        this.wrap_content.addClass('show');
    }

    this.install_switcher_btn('js-sort-type', this.type);
  }
  //скрывает контент контейнер
  hidden_content = () => {
    $(".js-estae-wrap").removeClass('show');
  }
  //  показывает фильтры согласно типу помещения
  show_filters = () => {
    this.show_house_filter();
    this.coollection_filters.length && this.coollection_filters.removeClass('show-filters');
    this.coollection_filters = $(`.js-subfilters[data-filter-type=filter-${this.project_type}]`);
    this.coollection_filters.addClass('show-filters');
  }
  show_house_filter = () => {
    if (Number(this.project_type)) {
      this.choice_wrap.css({ 'display': 'none' });
      this.filter_house.addClass("show-filters");
    } else {
      this.filter_house.removeClass("show-filters");
      this.choice_wrap.css({ 'display': '' });
    }
  }
  // счетчик выбранных фильтров
  get_count_active_filters = () => {
    // вычитаем один, т.к. всегда активен тип (квартира/офис/таунхаус)
    const count = $('.js-filters .active_filter').length - 1;
    if (count) {
      this.active_filter_count.text(count);
    } else {
      this.active_filter_count.text('');
    }
  }
  target_select_type = () => {
    let prev_state = this.project_type;
    $(document).on('click', '.js-filter-type-item', () => {
      if (Number(prev_state) !== Number(this.project_type)) {
        prev_state = this.project_type;
        this.request_filter({ type: this.project_type })
          .then(() => {
            this.get_count_flat(form_option.get_qwery_string(this.form_filters));
            this.reset_active_filter();
          })
        this.wrap_sort.removeClass('hidden-wrap')
        this.wrap_detail.removeClass('show');
        loader.hidden_loader('nottype');
        loader.hidden_loader('type');
        loader.hidden_loader('detail');
        if (Number(this.project_type) !== 0) {
          this.hidden_content();
          this.type = 'nottype';
        }
        this.show_btn_type();
      }
    })
  }
  reset_active_filter = () => {
    $('.active_filter:not([name=ordering])').removeClass("active_filter");
    $(".js-choice-option").removeClass('active_radio');
    custom_select.install_value('type', this.project_type);
    custom_select.update_select('project');
    custom_select.update_select('house');
    custom_select.update_select('development_quarter');
    custom_select.update_select('heating_matters');
    custom_select.update_select('material');
    custom_select.update_select('facing');
    custom_select.update_select('bathroom');
    custom_select.update_select('garage_or_carport');
    custom_select.update_select('queue');
  }
  target_select = () => {
    $(document).on('click', '.js-select-filter li', (e) => {
      const select = $(e.target).closest('.js-select-filter');
      $('.range').removeClass('active_filter');
      if (select.attr('name') === 'project') {
        $('.active_filter:not([name=ordering])').removeClass("active_filter");
        $(".js-choice-option").removeClass('active_radio');
        custom_select.install_value('type', this.project_type);
        select.addClass('active_filter');
      }
      this.get_count_flat(form_option.get_qwery_string(this.form_filters));
      this.request_filter(form_option.get_qwery_string(this.form_filters));
    })
  }
  target_checkbox = () => {
    const self = this;
    $(document).on('click', '.js-filter-checkbox input', function() {
      $(this).closest(".input").toggleClass("active_filter");
      const options_item = form_option.get_qwery_string(self.form_filters)
      self.get_count_flat(options_item);
      self.request_filter(options_item);
      if ($(this).attr('name') === 'with_booked') {
        self.reset_pagination();
      }
      if ($(this).attr('name') === 'with_sales') {
        self.reset_pagination();
      }
    })
  }

  init_ranges = () => {
    this.range_price = new RangesDefualt({
      range: $(".js-range-price")
    });
    this.range_price.init();
    this.range_area = new RangesDefualt({
      range: $(".js-range-area")
    });
    this.range_area.init();
    this.range_area_kitchen = new RangesDefualt({
      range: $(".js-range-kitchen-area")
    });
    this.range_area_kitchen.init();
    this.range_floor = new RangeFloor({
      range: $(".js-range-floor")
    });
    this.range_floor.init();
    this.range_plot_area = new RangesDefualt({
      range: $(".js-range-plot-area")
    });
    this.range_plot_area.init();
  }
  target_ranges = () => {
    this.range_price &&
      this.range_price.get_range().noUiSlider &&
      this.range_price.get_range().noUiSlider.on('change', () => {
        this.get_count_flat(form_option.get_qwery_string(this.form_filters));
        this.request_filter(form_option.get_qwery_string(this.form_filters));
      })

    this.range_area &&
      this.range_area.get_range().noUiSlider &&
      this.range_area.get_range().noUiSlider.on('change', () => {
        this.get_count_flat(form_option.get_qwery_string(this.form_filters));
        this.request_filter(form_option.get_qwery_string(this.form_filters));
      })

    this.range_area_kitchen &&
      this.range_area_kitchen.get_range().noUiSlider &&
      this.range_area_kitchen.get_range().noUiSlider.on('change', () => {
        this.get_count_flat(form_option.get_qwery_string(this.form_filters));
        this.request_filter(form_option.get_qwery_string(this.form_filters));
      })

    this.range_floor &&
      this.range_floor.get_range().noUiSlider &&
      this.range_floor.get_range().noUiSlider.on('change', () => {
        this.get_count_flat(form_option.get_qwery_string(this.form_filters));
        this.request_filter(form_option.get_qwery_string(this.form_filters));
      })
    this.range_plot_area &&
      this.range_plot_area.get_range().noUiSlider &&
      this.range_plot_area.get_range().noUiSlider.on('change', () => {
        this.get_count_flat(form_option.get_qwery_string(this.form_filters));
        this.request_filter(form_option.get_qwery_string(this.form_filters));
      })
  }
  init_checkbox = () => {
    this.hover_check_box();
    this.target_check_box();
  }
  hover_check_box = () => {
    this.choice_wrap.hover(function () {
      $(this).parent().toggleClass("hover");
    });
  }
  target_check_box = () => {
    const self = this
    this.choice_inputs.change(function (e) {
      let value = $(this).val().toLowerCase()
      $(`.js-choice-option[data-value=${value}-rooms]`).toggleClass('active_radio');
      let results = [];
      if ($(this).is(":checked")) {
        $(this).closest(".input").addClass("active_filter");
      } else {
        self.choice_inputs.map((index, item) => {
          if (item.checked) {
            results.push(true);
          } else {
            results.push(false);
          }
        })
        if (!results.some((e) => e === true)) {
          $(this).closest(".input").removeClass("active_filter");
        }
      }
      const options = form_option.get_qwery_string(self.form_filters);
      self.get_count_flat(options);
      self.request_filter(options);
    })
  }
  get_count_flat = (params) => {
    if (!params) {
      params = form_option.get_qwery_string(this.form_filters);
    }
    add_query_to_history(params);
    if (this.type === 'nottype') {
      api.flat_count({ ...params, page_size: 1 })
        .then(data => {
          this.get_count_active_filters();
          this.count_flat.text(conjugation(data.count, ['предложение', 'предложения', 'предложений']));
        })
    } else {
      api.get_catalog_types({ ...params, page_size: 1 })
        .then(data => {
          this.get_count_active_filters();
          this.count_flat.text(conjugation(data.count, ['предложение', 'предложения', 'предложений']));
        })
    }
  }
  checked_arrow = () => {
    if (this.display === true) {
      this.show_filters();
      this.moretext.text("Скрыть фильтр");
      this.arrow.addClass("rotate");
      this.filter_house.addClass("show-filters");
      this.filter_area.addClass('show-filters');
    } else {
      if (!Number(this.project_type)) {
        this.filter_house.removeClass("show-filters");
      }
      if (this.coollection_filters.length) {
        this.coollection_filters.removeClass('show-filters');
      }
      this.filter_area.removeClass("show-filters");
      this.arrow.removeClass("rotate");
      this.moretext.text("Расширенный фильтр");
    }
  }
  send_filter = () => {
    $(document).on("submit", ".js-filters", (e, scrollToBlock = true) => {
      e.preventDefault();
      this.reset_pagination();
      this.show_wraps_on_type();
      if (scrollToBlock) {
        document.location.href = `#filter-content`;
      }
      this.show_prompt();
    })
  }
  reset_pagination = () => {
    switch (Number(this.project_type)) {
      case 0:
        this.action_request_nottype_flat_tiles.reset_pagination();
        this.action_request_nottype_flat_table.reset_pagination();
        this.action_request_type_table.reset_pagination();
        this.action_request_type_tiles.reset_pagination();
        break;
      case 1:
        this.action_request_nottype_townhouse_tiles.reset_pagination();
        this.action_request_nottype_townhouse_table.reset_pagination();
        break
      case 2:
        this.action_request_nottype_commerce_tiles.reset_pagination();
        this.action_request_nottype_commerce_table.reset_pagination();
    }
  }
  reset_filter = () => {
    this.btn_reset.on('click', () => {
      this.request_filter({ type: this.project_type })
        .then(() => {
          this.get_count_flat({ type: this.project_type });
          this.reset_active_filter();
        })
    })
  }
  show_prompt = () => {
    this.prompt.css({ "display": "block" });
    setTimeout(() => { this.prompt.css({ "display": "none" }) }, 3000);
  }
  init_prompt_block = () => {
    new FlyCardPrompt({
      trigger_class: 'js-card-blocked',
      text: 'Квартира забронирована. Но от нее еще могут отказаться.'
    }).init();
    new FlyCardPrompt({
      trigger_class: 'js-townhouse-card-blocked',
      text: 'Таунхаус забронирован. Но от него еще могут отказаться.'
    }).init();
    new FlyCardPrompt({
      trigger_class: 'js-office-card-blocked',
      text: 'Офис забронирован. Но от него еще могут отказаться.'
    }).init();
  }
  request_filter = async (params, get_options, render_catalog) => {
    await api.get_catalog_filters(params)
      .then(data => {
        this.set_value_filters.render_values_filters(data,
          {
            price: this.range_price,
            floor: this.range_floor,
            area_total: this.range_area,
            area_kitchen: this.range_area_kitchen,
            plot_area: this.range_plot_area
          })
      })
      .then(() => {
        if (render_catalog) {
          let param = null;
          if (get_options) {
            param = form_option.get_qwery_string();
          } else {
            //проверка наличия фильтров из url
            if ($.isEmptyObject(this.params)) {
              param = params;
            } else {
              param = this.params;
            }
          }
          return params;
        }
      })
      .then(() => {
        if (this.first_render) {
          this.first_render = false;
          this.active_filters();
        }
        this.get_count_active_filters();
      })
  }
  active_filters = () => this.set_active_filters.install_active_filters(
    this.params,
    {
      price: this.range_price,
      floor: this.range_floor,
      area_total: this.range_area,
      area_kitchen: this.range_area_kitchen,
      plot_area: this.range_plot_area,
      is_studio: this.form_filters.find('.js-choice__wrap'),
      rooms: this.form_filters.find('.js-choice__wrap'),
      not_first_floor: this.form_filters.find('.js-filter-checkbox'),
      not_last_floor: this.form_filters.find('.js-filter-checkbox'),
      private_entrance: this.form_filters.find('.js-filter-checkbox'),
      dressing_room: this.form_filters.find('.js-filter-checkbox'),
      with_booked: $('.js-filter-checkbox'),
      with_sales: $('.js-filter-checkbox'),
      decor: this.form_filters.find('.js-filter-radio'),
      garage_or_carport: this.form_filters.find('.js-filter-radio'),
    }
  )
  active_zoom_image = () => {
    const zoom_image = new ZoomImage({ trigger_class: 'js-card__img' });
    zoom_image.init();
  }
  init_action_request_nottype_flat_tiles = () => {
    const pagination_button = $(".js-nottype-pagination-flat[data-type='0-tiles-nottype']")
    this.action_request_nottype_flat_tiles = new ActionRequestCatalogDefault({
      btn_pagination: pagination_button,
      btn_pagination_on_wrap: pagination_button,
      select_sort_tiles: $(".js-price-sort li"),
      count_pagination: $(".js-nottype-count-pagination-flat"),
      count_pagination_last: $('.js-nottype-count-last-flat'),
      wrap: $(`.js-estae-wrap#0-tiles-nottype .js-objects-tiles-content`),
      empty_block: $(".js-wrap-empty[data-type=0-nottype]"),
      api: api.flat,
      loader_name: '0-tiles-nottype',
      project_type: 0,
      template: get_template_card_object_tiles_notype,
      page_size: 12
    });
    this.action_request_nottype_flat_tiles.init()
  }
  init_action_request_nottype_townhouse_tiles = () => {
    const pagination_button = $(".js-nottype-pagination-townhouse[data-type='1-tiles-nottype']")
    this.action_request_nottype_townhouse_tiles = new ActionRequestCatalogDefault({
      btn_pagination: pagination_button,
      btn_pagination_on_wrap: pagination_button,
      select_sort_tiles: $(".js-price-sort li"),
      count_pagination: $(".js-nottype-count-pagination-townhouse"),
      count_pagination_last: $('.js-nottype-count-last-townhouse'),
      wrap: $(`.js-estae-wrap#1-tiles-nottype .js-objects-tiles-content`),
      empty_block: $(".js-wrap-empty[data-type=1-nottype]"),
      api: api.flat,
      loader_name: '1-tiles-nottype',
      project_type: 1,
      template: get_template_card_townhouse_tiles_notype,
      page_size: 12
    });
    this.action_request_nottype_townhouse_tiles.init();
  }
  init_action_request_nottype_commerce_tiles = () => {
    const pagination_button = $(".js-nottype-pagination-commerce[data-type='2-tiles-nottype']");
    this.action_request_nottype_commerce_tiles = new ActionRequestCatalogDefault({
      btn_pagination: pagination_button,
      btn_pagination_on_wrap: pagination_button,
      select_sort_tiles: $(".js-price-sort li"),
      count_pagination: $(".js-nottype-count-pagination-commerce"),
      count_pagination_last: $('.js-nottype-count-last-commerce'),
      wrap: $(`.js-estae-wrap#2-tiles-nottype .js-objects-tiles-content`),
      empty_block: $(".js-wrap-empty[data-type=2-nottype]"),
      api: api.flat,
      loader_name: '2-tiles-nottype',
      project_type: 2,
      template: get_template_card_office_tiles_notype,
      page_size: 12
    })
    this.action_request_nottype_commerce_tiles.init()
  }
  init_action_request_type_tiles = () => {
    const pagination_button = $(".js-type-pagination[data-type='0-tiles-type']");
    this.action_request_type_tiles = new ActionRequestCatalogDefault({
      btn_pagination: pagination_button,
      btn_pagination_on_wrap: pagination_button,
      select_sort_tiles: $(".js-price-sort li"),
      count_pagination: $(".js-type-count-pagination"),
      count_pagination_last: $('.js-type-count-last'),
      wrap: $(`.js-estae-wrap#0-tiles-type .js-objects-tiles-content`),
      empty_block: $(".js-wrap-empty[data-type=0-type]"),
      api: api.get_catalog_types,
      loader_name: '0-tiles-type',
      project_type: 0,
      template: get_template_card_object_tiles_type,
      page_size: 12
    });
    this.action_request_type_tiles.init();
  }
  init_action_request_nottype_flat_table = () => {
    const pagination_button = $(".js-nottype-pagination-flat[data-type='0-table-nottype']");
    this.action_request_nottype_flat_table = new ActionRequestCatalogDefault({
      btn_pagination: pagination_button,
      btn_pagination_on_wrap: pagination_button,
      btn_sort_table: $(".js-sort-table-flat"),
      count_pagination: $(".js-nottype-count-pagination-flat"),
      count_pagination_last: $('.js-nottype-count-last-flat'),
      wrap: $(`.js-estae-wrap#0-table-nottype .objects-table-content`),
      empty_block: $(".js-wrap-empty[data-type=0-nottype]"),
      api: api.flat,
      loader_name: '0-table-nottype',
      project_type: 0,
      template: get_template_card_object_table_notype,
      page_size: 12
    });
    this.action_request_nottype_flat_table.init();
    this.action_request_nottype_flat_table.init_sort();
  }
  init_action_request_nottype_townhouse_table = () => {
    const pagination_button = $(".js-nottype-pagination-townhouse[data-type='1-table-nottype']");
    this.action_request_nottype_townhouse_table = new ActionRequestCatalogDefault({
      btn_pagination: pagination_button,
      btn_pagination_on_wrap: pagination_button,
      btn_sort_table: $(".js-sort-table-townhouse"),
      count_pagination: $(".js-nottype-count-pagination-townhouse"),
      count_pagination_last: $('.js-nottype-count-last-townhouse'),
      wrap: $(`.js-estae-wrap#1-table-nottype .objects-table-content`),
      empty_block: $(".js-wrap-empty[data-type=1-nottype]"),
      api: api.flat,
      loader_name: '1-table-nottype',
      project_type: 1,
      template: get_template_card_townhouse_table_notype,
      page_size: 12
    });
    this.action_request_nottype_townhouse_table.init();
    this.action_request_nottype_townhouse_table.init_sort();
  }
  init_action_request_nottype_commerce_table = () => {
    const pagination_button = $(".js-nottype-pagination-commerce[data-type='2-table-nottype']");
    this.action_request_nottype_commerce_table = new ActionRequestCatalogDefault({
      btn_pagination: pagination_button,
      btn_pagination_on_wrap: pagination_button,
      btn_sort_table: $(".js-sort-table-commerce"),
      count_pagination: $(".js-nottype-count-pagination-commerce"),
      count_pagination_last: $('.js-nottype-count-last-commerce'),
      wrap: $(`.js-estae-wrap#2-table-nottype .objects-table-content`),
      empty_block: $(".js-wrap-empty[data-type=2-nottype]"),
      api: api.flat,
      loader_name: '2-table-nottype',
      project_type: 2,
      template: get_template_card_office_table_notype,
      page_size: 12
    });
    this.action_request_nottype_commerce_table.init();
    this.action_request_nottype_commerce_table.init_sort();
  }
  init_action_request_type_table = () => {
    const pagination_button = $(".js-type-pagination[data-type='0-table-type']");
    this.action_request_type_table = new ActionRequestCatalogDefault({
      btn_pagination: pagination_button,
      btn_pagination_on_wrap: pagination_button,
      btn_sort_table: $(".js-sort-table-type"),
      count_pagination: $(".js-type-count-pagination"),
      count_pagination_last: $('.js-type-count-last'),
      wrap: $(`.js-estae-wrap#0-table-type .objects-table-content`),
      empty_block: $(".js-wrap-empty[data-type=0-type]"),
      api: api.get_catalog_types,
      loader_name: '0-table-type',
      project_type: 0,
      template: get_template_card_object_table_type,
      page_size: 12
    });
    this.action_request_type_table.init();
    this.action_request_type_table.init_sort();
  }
  init_action_request_detail = () => {
    this.action_request_detail = new ActionRequestCatalogDetail({
      btn_pagination: $(".js-detail-pagination"),
      btn_pagination_on_wrap: $(".js-type-pagination[data-type='detail']"),
      btn_sort_table: $(".js-sort-flat-detail"),
      count_pagination: $(".js-detail-count-pagination"),
      count_pagination_last: $('.js-detail-count-last'),
      wrap_tiles: $('.js-card-detail'),
      wrap_table: $(`.js-wrap-detail .js-objects-table-content`),
      empty_block: $(".js-wrap-empty-detail"),
      api: api.get_catalog_types_detail,
      loader_name: 'detail',
      template_table: get_template_card_object_table_notype_short,
      template_tiles: get_template_card_detail,
      page_size: 1000,
    });
    this.action_request_detail.init();
    this.action_request_detail.init_sort();
  }
}

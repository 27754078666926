import BaseApi from "@garpix/base-api";
import axios from "axios";
import {
    API_PROJECT,
    API_INF_PROJECT,
    API_FLAT,
    API_INFRASTRUCTURE,
    API_CITY,
    API_OFFICES,
    API_FAVORITE,
    API_BANKS,
    API_MORTGAGE,
    API_INSTALLMENY,
    API_NEWS,
    API_PROMOTIONS,
    API_PARTNERS,
    API_VACANCIES,
    API_CONSTRUCTIONS,
    API_BOOKING,
    API_FLOOR,
    API_INF_FLAT,
    API_SCHEMA,
    API_HOUSE_SECTION,
    API_SEARCH,
    API_SET_BOOKING,
    API_SET_BOOKING_FLAT,
    API_DECOR,
    API_AUTH,
    API_FAVORITE_COUNT,
    API_PROJECT_IN_CITY,
    API_NEWS_FILTERS,
    API_FLAT_FILTERS,
    API_FAVORITE_PROPERTIES,
    API_CATALOG_TYPES,
    API_PRICE_THIS_DISCOUNT,
    API_FILTERS_PARTNERS,
    API_DOWNLOAD_PARTNERS,
    API_DOWNLOAD_ROOM,
    API_ENTRANCE,
    API_QUEUE_MAP,
    API_QUEUE_PROPS,
    API_FLOOR_INF,
    API_COMPLITED_PROJECTS,
    API_INF_FLAT_TOWNHOUSE,
    API_COMPARISON,
    API_COMPARISON_COUNT,
    API_COMPARISON_PROPERTIES,
} from "./api_list"
import serializer_construction from "./serializer/construction";


const BASE_URL = '';

class Request extends BaseApi {
    constructor(url) {
        super(url);
        this.url = url;
        this.ajaxRequest = null;
    }

    axiosOverride = (axios) => {
        const csrf = document.cookie.replace(/(?:(?:^|.*;\s*)csrftoken\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        axios.defaults.headers.common['X-CSRFToken'] = `${csrf}`;
        return axios
    };

    auth = async (params = {}) => {
        const res = await this.post(`${API_AUTH}/`, params);
        return res.data;
    };

    city = async (params = {}) => {
        const res = await this.get(`${API_CITY}/`, params);
        return res.data;
    };
    projects_list = async (params = {}) => {
        const res = await this.get(`${API_PROJECT}/`, params);
        return res.data;
    };
    project_map = async (params = {}) => {
        const res = await this.get(`${API_PROJECT}/`, params);
        return res.data;
    };
    project_inf = async (params = {}) => {
        if (this.ajaxRequest) {
            this.ajaxRequest.cancel();
        }
        this.ajaxRequest = axios.CancelToken.source();
        const res = await this.get(`${API_INF_PROJECT}/`, params, { cancelToken: this.ajaxRequest.token });
        return res.data;
    };
    infrastructure_inf = async (params = {}) => {
        const res = await this.get(`${API_INFRASTRUCTURE}/`, params);
        return res.data;
    };
    house_map = async (params = {}) => {
        params['map_info'] = 1
        const res = await this.get(`${API_SCHEMA}/`, params);
        return res.data;
    };
    house_inf = async (params = {}) => {
        if (this.ajaxRequest) {
            this.ajaxRequest.cancel();
        }
        this.ajaxRequest = axios.CancelToken.source();
        const res = await this.get(`${API_HOUSE_SECTION}/`, params, { cancelToken: this.ajaxRequest.token });
        return res.data;
    };
    flat_inf = async (params = {}) => {
        if (this.ajaxRequest) {
            this.ajaxRequest.cancel();
        }
        this.ajaxRequest = axios.CancelToken.source();
        const res = await this.get(`${API_INF_FLAT}/`, params, { cancelToken: this.ajaxRequest.token });
        return res.data;
    };
    flat = async (params = {}, axiosParams = {}) => {
        const res = await this.get(`${API_FLAT}/`, params, axiosParams);
        return res.data;
    };
    house_floor = async (params = {}) => {
        if (this.ajaxRequest) {
            this.ajaxRequest.cancel();
        }
        this.ajaxRequest = axios.CancelToken.source();
        const res = await this.get(`${API_FLOOR}/`, params, { cancelToken: this.ajaxRequest.token });
        return res.data;
    };
    object_schema = async (params = {}) => {
        const res = await this.get(`${API_SCHEMA}/`, params);
        return res.data;
    };
    flat_count = async (params = {}) => {
        const res = await this.get(`${API_FLAT}/`, params);
        return res.data;
    };
    banks = async (params = {}) => {
        const res = await this.get(`${API_BANKS}/`, params);
        return res.data;
    };
    installment = async (params = {}) => {
        const res = await this.get(`${API_INSTALLMENY}/`, params);
        return res.data;
    };
    mortgage = async (params = {}) => {
        const res = await this.get(`${API_MORTGAGE}/`, params);
        return res.data;
    };
    set_booking_flat = async (params = {}) => {
        const res = await this.post(`${API_SET_BOOKING_FLAT}/`, params);
        return res;
    };
    news = async (params = {}) => {
        const res = await this.get(`${API_NEWS}/`, params);
        return res.data;
    };
    constructions_process = async (params = {}) => {
        const res = await this.get(`${API_CONSTRUCTIONS}/`, params);
        return serializer_construction(res.data);
    };
    townhouse_flat = async (params = {}) => {
        const res = await this.get(`${API_INF_FLAT_TOWNHOUSE}/`, params);
        return res.data;
    };
    set_booking = async (params = {}) => {
        const res = await this.post(`${API_SET_BOOKING}/`, params);
        return res.data;
    };
    get_events = async (params = {}) => {
        const res = await this.get(`${API_BOOKING}/`, params);
        return res.data;
    };
    get_application_chosen_dates = async (data, params = {}) => {
        const res = await this.get(`${API_BOOKING}/${data.event_pk}/get_dates_and_form/`, params);
        return res.data;
    };
    get_application_chosen_times = async (data, params = {}) => {
        const res = await this.get(`${API_BOOKING}/${data.event_pk}/get_times/`, params);
        return res.data;
    };
    get_catalog = async (params = {}) => {
        const res = await this.get(`${API_FLAT}/`, params);
        return res.data
    }
    get_favorite = async (params = {}) => {
        const res = await this.get(`${API_FAVORITE}/`, params);
        return res.data
    }
    post_favorite = async (params = {}) => {
        const res = await this.post(`${API_FAVORITE}/`, params);
        return res.data
    }
    delete_favorite = async (params = {}) => {
        const res = await this.delete(`${API_FAVORITE}/`, params);
        return res.data
    }
    count_favorite = async (params = {}) => {
        const res = await this.get(`${API_FAVORITE_COUNT}/`, params);
        return res.data
    }
    get_office = async (params = {}) => {
        const res = await this.get(`${API_OFFICES}/`, params);
        return res.data
    }
    get_partners = async (params = {}) => {
        const res = await this.get(`${API_PARTNERS}/`, params);
        return res.data
    }
    get_promotions = async (params = {}) => {
        const res = await this.get(`${API_PROMOTIONS}/`, params);
        return res.data
    }
    get_vacancy = async (params = {}) => {
        const res = await this.get(`${API_VACANCIES}/`, params);
        return res.data
    }
    search = async (params = {}) => {
        const res = await this.get(`${API_SEARCH}/`, params);
        return res.data
    }
    get_decor = async (params = {}) => {
        const res = await this.get(`${API_DECOR}/`, params);
        return res.data
    }
    get_project_in_city = async (params = {}) => {
        const res = await this.get(`${API_PROJECT_IN_CITY}/`, params);
        return res.data
    }
    get_news_filters = async (params = {}) => {
        const res = await this.get(`${API_NEWS_FILTERS}/`, params);
        return res.data
    }
    get_catalog_filters = async (params = {}) => {
        const res = await this.get(`${API_FLAT_FILTERS}/`, params);
        return res.data
    }
    post_city = async (params = {}) => {
        const res = await this.post(`${API_CITY}/`, params);
        return res.data
    }
    get_catalog_types = async (params = {}, axiosParams = {}) => {
        const res = await this.get(`${API_CATALOG_TYPES}/`, params, axiosParams);
        return res.data
    }
    get_catalog_types_detail = async (params = {}, id, axiosParams = {}) => {
        const res = await this.get(`${API_CATALOG_TYPES}/${id}/`, params, axiosParams);
        return res.data
    }
    get_favorite_properties = async (params = {}) => {
        const res = await this.get(`${API_FAVORITE_PROPERTIES}/`, params);
        return res.data
    }
    get_price_this_discount = async (params = {}) => {
        const res = await this.get(`${API_PRICE_THIS_DISCOUNT}/`, params);
        return res.data
    }
    get_filters_partners = async (params = {}) => {
        const res = await this.get(`${API_FILTERS_PARTNERS}/`, params);
        return res.data
    }
    get_download_partners = async (params = {}) => {
        const res = await this.get(`${API_DOWNLOAD_PARTNERS}/`, params);
        return res.data
    }
    get_download_room = async (params = {}) => {
        const res = await this.get(`${API_DOWNLOAD_ROOM}/`, params);
        return res.data
    }
    get_entrance = async (params = {}) => {
        const res = await this.get(`${API_ENTRANCE}/`, params);
        return res.data
    }
    queue_map = async (params = {}) => {
        const res = await this.get(`${API_QUEUE_MAP}/`, params);
        return res.data
    }
    queue_props = async (params = {}) => {
        const res = await this.get(`${API_QUEUE_PROPS}/`, params);
        return res.data
    }
    floor_inf = async (params = {}) => {
        const res = await this.get(`${API_FLOOR_INF}/`, params);
        return res.data
    }
    complited_projects_list = async (params = {}) => {
        const res = await this.get(`${API_COMPLITED_PROJECTS}/`, params);
        return res.data
    }
    get_comparison = async (params = {}) => {
      const res = await this.get(`${API_COMPARISON}/`, params);
      return res.data
    }
    post_comparison = async (params = {}) => {
      const res = await this.post(`${API_COMPARISON}/`, params);
      return res.data
    }
    delete_comparison = async (params = {}) => {
        const res = await this.delete(`${API_COMPARISON}/${params.id}/`);
        return res.data
    }
    delete_all_comparison = async (params = {}) => {
      const res = await this.delete(`${API_COMPARISON}/clear/`);
      return res.data
  }
    count_comparison = async (params = {}) => {
        const res = await this.get(`${API_COMPARISON_COUNT}/`, params);
        return res.data
    }
    post_comparison = async (params = {}) => {
        const res = await this.post(`${API_COMPARISON}/`, params);
        return res.data
    }
    get_comparison_properties = async (params = {}) => {
      const res = await this.get(`${API_COMPARISON_PROPERTIES}/`, params);
      return res.data
  }
}

const api = new Request(BASE_URL);

export default api

import api from "../api/request";
import { DefaultPagination } from "../components/pagination";
import { get_template_card_object_tiles_type } from "../templates/templates_catalog";
import { get_local } from "../utils/storege";
/**
 * @class  класс содержит логику для секции "планировки"
 * @method init_flat_plan_wrap - создает экземпляр класса пагинации
 * @method target_radiobutton - слушает событие нажатия на радиокнопки фильтрации
 * @method link - формирутет qwery строку и  направляет на страницу "выбор недвижимости"
 */

export class SectionFlatPlan {
    constructor() {
        this.block_wrap_pagination = $(".js-flat-plan-wrap");
        this.form_pagination = $(".js-filter-flat-plan");
        this.radio_buttons = $(".js-radio-button");
        this.city = get_local('city');
        this.pagination = null;
        this.count_rooms = 'True'
    }
    init = () => {
        this.init_flat_plan_wrap();
        this.target_radiobutton();
        this.link();
    }
    init_flat_plan_wrap = () => {
        this.pagination = new DefaultPagination({
            wrap_content: this.block_wrap_pagination,
            template: get_template_card_object_tiles_type,
            form: this.form_pagination,
            api:api.get_catalog_types,
            page_size:4,
            loader_name:'flat_plans'
        });
        this.pagination.init();
    }
    target_radiobutton = () => {
        const self = this;
        const makeActive = function () {
            self.count_rooms = this.value;
            self.radio_buttons.prop('checked', false);
            $(this).prop('checked', true);
            self.render();
        }
        $(document).on('click', ".js-radio-button", makeActive);

        // First render.
        const checkedInput = $(".js-radio-button:checked", this.form_pagination);
        if (checkedInput.length !== 0) {
            makeActive.call(checkedInput[0]);
        }
    }
    render = () => {
        this.pagination.reset_pagination();
    }
    link = () => {
        const self = this;
        $(document).on('click', '.js-link-info-block', function () {
            const id = $(this).attr('data-id');
            let querysting = `&type_content=detail&plan=${id}`
            self.count_rooms==="True"? querysting += `&is_studio=True`: querysting += `&rooms=${self.count_rooms}`
            const link = $('.js-link-plans').attr('href');
            location.href = `${link}${querysting}`;
        })
        $(document).on('click', '.js-link-plans', function (e) {
            e.preventDefault();
            location.href = `${$(this).attr('href')}&type_content=type`;
        })
    }
}

import {RangePrice, RangePersent, RangeYaerOrMounth} from './ranges';
import api from '../api/request';
import {format_price_full} from '../utils/format_price';
import {get_logo_bank} from '../templates/templates_banks';
import form_option from '../utils/trigget-filters';

/**
 * @class - абстрактный класс содержит логику для работы калькуляторов ипотеки, рассрочки, предложений банков
 * @method init_ranges - инициализирует фильтры ренжей
 * @method update - слушает событие update у ренжей, пересчитывает сумму первоначального взноса при изменение значения стоимости помещения
 * @method action_ranges - слушает событие change у ренжей и отправляет запрос
 * @method render - выводит клиенту результат запроса
 * @method update_ranges - обновляет данные в ренжах и пересчитывает первоначальный платеж
 */
class AbstractCalcPay {
  constructor() {
    this.range_price_wrap = {};
    this.range_first_pay_wrap = {};
    this.range_year_wrap = {};
    this.range_price = null;
    this.range_first_pay = null;
    this.range_year = null;
    this.default_persent = null;
    this.month_price = {};
  }

  init_ranges = () => {
    this.range_price_wrap && (
      this.range_price = new RangePrice({
        range: this.range_price_wrap,
      })) && this.range_price.init()
    this.range_first_pay_wrap && (
      this.range_first_pay = new RangePersent({
        range: this.range_first_pay_wrap,
      })) && this.range_first_pay_wrap.init()
    this.range_year_wrap && (
      this.range_year = new RangeYaerOrMounth({
        range: this.range_year_wrap,
      })) && this.range_year.init()
  }
  get = () => {
    const option = form_option.get_options_form(this.range_first_pay_wrap);
    this.request(option);
  }
  update = () => {
    const self = this;
    this.range_price &&
    this.range_price.get_range().noUiSlider &&
    this.range_price.get_range().noUiSlider.on('update', function (value) {
      let percent = null;
      if (self.default_persent) {
        if (typeof self.default_persent === 'string') {
          percent = Number((self.default_persent).replace(/,/, '.'));
        } else {
          percent = self.default_persent;
        }
        let min = (value[0] * percent) / 100;
        if (self.range_first_pay.get_range().noUiSlider) {
          self.range_first_pay.update_range(min, self.range_price.get_value());
          self.range_first_pay.get_range().noUiSlider.set(parseInt(value[0] * percent / 100));
        }
      }
    })
    self.range_first_pay &&
    self.range_first_pay.get_range().noUiSlider &&
    self.range_first_pay.get_range().noUiSlider.on('update', function (values) {
      self.range_first_pay.update(
        values[0],
        self.range_price.get_value());
    })
  }
  update_range_first_pay = (min_initial_payment) => {
    if (min_initial_payment) {
      return this.range_first_pay.update_percentage(min_initial_payment, this.range_price.get_value());
    }

    return false;
  }
  action_ranges = () => {
    const self = this;
    this.range_price &&
    this.range_price.get_range().noUiSlider &&
    this.range_price.get_range().noUiSlider.on('change', function (value) {
      const option = form_option.get_options_form(self.range_price.get_range());
      self.request(option);
    })
    this.range_first_pay.get_range().noUiSlider &&
    this.range_first_pay.get_range().noUiSlider.on('change', function (value) {
      const option = form_option.get_options_form(self.range_first_pay.get_range());
      self.request(option);
    })
    this.range_year.get_range().noUiSlider &&
    this.range_year.get_range().noUiSlider.on('change', function (value) {
      const option = form_option.get_options_form(self.range_year.get_range());
      self.request(option);
    })
  }
  request = async (option = {}) => {
    const results = await api.installment(option);
    this.render(results);
  }
  render = (data) => {
    if (data.length) {
      this.month_price.text(format_price_full.format(data[0].payments_per_month));

      if (data[0].min_initial_payment !== undefined && this.update_range_first_pay(data[0].min_initial_payment)) {
        this.get();
      }
    } else {
      this.month_price.text(parseInt(0));
    }
  }
  update_all = (data) => {
    this.range_year.update_range(data.day_max, data.day_max);
    this.range_year.update_range(data.day_min, data.day_max);
    this.range_price.update_range(data.price_min, data.price_max);
  }
}

/**
 * @class - класс калькулятора рассрочки
 */
export class CalcInstallment extends AbstractCalcPay {
  constructor(props) {
    super(props)
    this.range_price_wrap = $(".js-installment-price");
    this.range_first_pay_wrap = $(".js-installment-first-pay");
    this.range_year_wrap = $(".js-installment-month");
    this.range_price = null;
    this.range_first_pay = null;
    this.range_year = null;
    this.default_persent = this.range_first_pay_wrap.attr('data-value-max');
    this.month_price = $(".js-installment-pay");
  }

  init = () => {
    this.init_ranges();
    this.action_ranges();
    this.update();
  }
  render = (data) => {
    if (data.length) {
      this.month_price.text(format_price_full.format(data[0].monthly_payments));
    } else {
      this.month_price.text(parseInt(0));
    }
  }
}

/**
 * @class - класс калькулятора ипотеки
 */
export class CalcMortgage extends AbstractCalcPay {
  constructor(props) {
    super(props)
    this.range_price_wrap = $(".js-project-mortgage-price");
    this.range_first_pay_wrap = $(".js-project-mortgage-first-pay");
    this.range_year_wrap = $(".js-project-mortgage-year");
    this.filter_name = props.filter_name;
    this.range_price = null;
    this.range_first_pay = null;
    this.range_year = null;
    this.default_persent = this.range_first_pay_wrap.attr('data-value-max');
    this.month_price = $(".js-morgage-pay");
    this.percent_count = $(".js-morgage-percent");
    this.project_id = $(".js-id-project").val();
    this.logo_banks_wrap = $('.js-log-banks');
  }

  init = () => {
    this.init_ranges();
    this.action_ranges();
    this.update();
  }
  request = async (option = {}) => {
    option[this.filter_name] = this.project_id;
    const results = await api.mortgage(option);
    this.render(results);
  }
  render = (data) => {
    if (data[0]) {
      this.logo_banks_wrap.children().remove();
      this.month_price.text(format_price_full.format(data[0].monthly_payments));
      this.percent_count.text(`${data[0].min_rate} %`);
      if (data[0].banks_logo) {
        this.logo_banks_wrap.append(get_logo_bank(data[0].banks_logo));
      }

      if (data[0].min_initial_payment !== undefined && this.update_range_first_pay(data[0].min_initial_payment)) {
        this.get();
      }
    } else {
      this.month_price.text('');
      this.percent_count.text('');
    }
  }
}

/**
 * @class - класс калькулятора рассрочки для помещений
 */
export class CalcInstallmentRoom extends AbstractCalcPay {
  constructor(props) {
    super(props);
    this.range_first_pay_wrap = $(".js-installment-room-first-pay");
    this.range_year_wrap = $(".js-installment-room-month");
    this.range_price = null;
    this.range_first_pay = null;
    this.range_year = null;
    this.default_persent = this.range_first_pay_wrap.attr('data-value-max');
    this.month_price = $(".js-installment-room-pay");
  }

  init = () => {
    this.init_ranges();
    this.action_ranges();
    this.update();
    this.get();
  }
  update = () => {
    const self = this;
    self.range_first_pay.get_range().noUiSlider &&
    self.range_first_pay.get_range().noUiSlider.on('update', function (values) {
      const max = parseInt($(this.target).attr('data-value-max'));
      self.range_first_pay.update(
        values[0],
        max,
        values[0]);
    })
    const min_value = this.range_first_pay_wrap.attr('data-value-min');
    self.range_first_pay.get_range().noUiSlider.set(min_value);
  }
}

/**
 * @class - класс калькулятора ипотеки для помещения
 */
export class CalcMortgageRoom extends AbstractCalcPay {
  constructor(props) {
    super(props)
    this.range_first_pay_wrap = $(".js-mortgage-room-first-pay");
    this.range_year_wrap = $(".js-mortgage-year");
    this.range_price = null;
    this.range_first_pay = null;
    this.range_year = null;
    this.default_persent = this.range_first_pay_wrap.attr('data-value-max');
    this.month_price = $(".js-morgage-room-pay");
    this.percent_count = $(".js-morgage-room-percent");
  }

  init = () => {
    this.init_ranges();
    this.action_ranges();
    this.update();
    this.get();
  }
  update = () => {
    const self = this;
    self.range_first_pay.get_range().noUiSlider &&
    self.range_first_pay.get_range().noUiSlider.on('update', function (values) {
      const max = parseInt($(this.target).attr('data-value-max'));
      self.range_first_pay.update(
        values[0],
        max,
        values[0]);
    })
    const min_value = this.range_first_pay_wrap.attr('data-value-min');
    self.range_first_pay.get_range().noUiSlider.set(min_value);
  }
  request = async (option = {}) => {
    await api.mortgage(option)
      .then(data => {
        if (data) {
          if (data[0].payments_per_month) {
            this.month_price.text(format_price_full.format(data[0].payments_per_month));
          } else {
            this.month_price.text(0);
          }
          if (data[0].rate) {
            this.percent_count.text(data[0].rate);
          } else {
            this.percent_count.text(0);
          }
        }
      })
  }
}

/**
 * @class - класс предложений банков
 * @method pagination - инициализирует пагинацию и вызывает метод первого рендера
 */
export class CalcBanks extends AbstractCalcPay {
  constructor(props) {
    super(props)
    this.pagination_banks = props;
    this.range_price_wrap = $(".js-morgage-price");
    this.range_first_pay_wrap = $(".js-morgage-first-pay");
    this.range_year_wrap = $(".js-morgage-year");
    this.range_price = null;
    this.range_first_pay = null;
    this.range_year = null;
    this.default_persent = this.range_first_pay_wrap.attr('data-value-max');
    this.month_price = $(".js-morgage-pay");
    this.percent_count = $(".js-morgage-percent");
  }

  init = () => {
    this.init_ranges();
    this.action_ranges();
    this.update();
    this.pagination();
    this.select()
  }
  select = () => {
    const self = this;
    $('.js-morgage-project .js-dropdown-list-item').on('click', function (e) {
      const value = $(this).data('value')
      $('.js-morgage-project [name="project"]').val(value)

      let filters = ['price', 'initial_payment','loan_period', 'project'];
      const option = form_option.get_options_form(this,filters);

      self.update_values({...option}).then(() => {
        self.request({...option}, false);
      });
    })
  }

  update_values = async (option = {}) => {
    const self = this;
    const results = await api.mortgage(option);
    const data = results[0]

    const propertyMinPrice = parseInt(data.property_min_price ?? this.range_price_wrap.data('value-min'));
    const propertyMaxPrice = parseInt(data.property_max_price ?? this.range_price_wrap.data('value-max'));

    this.range_price.update_range(propertyMinPrice, propertyMaxPrice);
    this.range_first_pay.update_range(1, data.min_initial_payment);
    this.range_year.update_range(1, data.maximum_loan_period || data.max_loan_period);
    self.update()

    return results
  }

  pagination = () => {
    if (this.pagination_banks) {
      this.pagination_banks.init();
      this.pagination_banks.first_render();
    }
  }
  request = (option = {}, updateInfo = true) => {
    const promise = updateInfo ? api.mortgage(option) : new Promise((resolve) => resolve());
    promise.then(res => {
      if (res) {
        this.render(res);
      }

      if (this.pagination_banks) {
        this.pagination_banks.reset_pagination();
      }
    })
  }
}


import { get_catalog_select_item } from "../templates/templates_catalog";
import { custom_select } from "./custom-select";
/**
 * @class - перерендеривает значения фильтров
 */
export class SetValueCatalofFilters {
    constructor(){}
    render_values_filters = (data,filters) =>{
      for (let key in data) {
        const value = data[key]
        if (value){
         switch (key) {
          case 'project':
            $(`.js-dropdown-list[name=${key}]`).html(get_catalog_select_item({'':"Все проекты"}));
            this.create_select_values(key,value);
            break;
          case 'house':
            $(`.js-dropdown-list[name=${key}]`).html(get_catalog_select_item({'':"Все дома"}));
            this.create_select_values(key,value);
            break;
          case 'queue':
            $(`.js-dropdown-list[name=${key}]`).html(get_catalog_select_item({'':"Любая "}));
            this.create_select_values(key,value);
            break;
          case 'garage_or_carport':
          case 'development_quarter':
          case "facing":
          case 'material':
          case "bathroom":
          case 'heating_matters':
            $(`.js-dropdown-list[name=${key}]`).html(get_catalog_select_item({'':"Любой"}));
            this.create_select_values(key,value);
            break;
          case 'plot_area':
          case 'price':
          case 'area_total':
          case 'area_kitchen':
          case "floor":
            if (filters[key]) {
              this.update_ranges(filters[key],value);
            }
            break;
         }
        }
      }
    }
    create_select_values = (key,data) =>{
      $(`.js-dropdown-list[name=${key}]`).append(data.map(i=>get_catalog_select_item(i)));
      custom_select.install_value(key);
    }
    update_ranges = (item,values) =>{
      const new_value = values.split(",");
      item.update_range(Number(new_value[0]),Number(new_value[1]));
    }
  }

import api from '../api/request';
import { loader } from '../components/loader';
import { showModalError } from '../components/modal';
import FlyCardPrompt from '../components/fly_card_prompt';
import ZoomImage from '../components/zoom_image';
import { get_comparison_labels, get_template_comparison } from '../templates/tempates_comparison';
import { comparison_slider } from './section_sliders';
import { favorite } from '../components/favorite_actions';
import { set_local } from "../utils/storege";
import { API_DOWNLOAD_COMPARISON } from '../api/api_list';


export class ComparisonList {
  constructor() {
    this.count_comparison_wrap = $('.js-menu-comparisons-count');
    this.comparisons_wrap = $('.js-comparisons-wrap')
    this.comparisons_list = $('.js-comparison-section__list');
    this.comparison__labels = $('.js-comparison__labels');
    this.modal_error = $('.js-req-modal__error');
    this.propmt_wrap = $('.js-prompt-wrap');
    this.comparison_checkbox = $('.js-comparison-checkbox');
    this.comparison_item_remove = $('.js-comparison-item-remove');
    this.comparison_controls = $('.js-comparison-section__controls');
    this.comparison_control_prev = $('.js-comparison-section__btn--prev');
    this.comparison_control_next = $('.js-comparison-section__btn--next');
    this.comparison_checkbox_value = false
    this.count_comparison = $('.js-menu-comparison-count');
    this.apartments = [];
  }
  init = () => {
    this.get_comparison_request({only_differences: this.comparison_checkbox_value ? 1 : undefined});
    this.get_count_flat_request();
    this.propmt_block();
    this.active_zoom_image();
    this.on_deferent_value_item();
    this.remove_all_comparison();
    this.remove_comparison_item();
    this.action_favorit()
    this.check_child_wrap()
    this.on_resize_window()
    this.download_comparison()
  }

  on_resize_window = () => {
    $(window).resize(() => {
      if (this.comparison_control_prev.is(":disabled") && this.comparison_control_next.is(":disabled") && this.apartments.length <= 3) {
        this.comparison_controls.css({ "display": "none" })
      }else {
        this.comparison_controls.css({ "display": "flex" })
      }
    })
  }

  check_child_wrap = () => {
    if (!this.apartments.length) {
      $(".js-wrap-empty").css({ "display": "flex" })
      $(".section-header--comparison").css({"padding-bottom": "0", "border-bottom": "none"})
      $(".js-comparison-controls").css({ "display": "none" })
      $(".js-comparison-swiper").css({ "display": "none" })
      $(".js-comparison-item-remove").prop("disabled", true)
    }else {
      $(".js-wrap-empty").css({ "display": "none" })
      $(".section-header--comparison").css({"padding-bottom": "20px", "border-bottom": "1px solid #D7D9DB"})
      $(".js-comparison-controls").css({ "display": "flex" })
      $(".js-comparison-swiper").css({ "display": "block" })
      $(".js-comparison-item-remove").prop("disabled",false)
    }

    if (this.apartments.length <= 3) {
      this.comparison_controls.css({ "display": "none" })
    }else {
      this.comparison_controls.css({ "display": "flex" })
    }
  }
  get_count_flat_request = () => {
    api.count_comparison().then(
      data => {$('.js-comparison-count').text(data.total_count)}
    )
  }

  remove_all_comparison = () => {
    $('.js-btn-remove-comparison').on('click',  () => {
      api.delete_all_comparison()
      .then(() =>
          this.get_comparison_request()
      ).then(() => this.get_count_flat_request())
      .then(() =>  {
        set_local('comparison', '')
        this.count_comparison.text(`(0)`)
      })
    })
  }

  on_deferent_value_item = () =>{
    this.comparison_checkbox.on('change', (e) => {
        this.comparison_checkbox_value = e.target.checked
        this.get_comparison_request();
    })
  }

  set_comparison_id = (apartments) => {
    const ids = apartments.map(i => i.id);
    set_local('comparison', ids)
    this.count_comparison.text(`(${ids.length})`)
  }

  get_comparison_request = () => {
    const sort = {only_differences: this.comparison_checkbox_value ? 1 : undefined}
    loader.show_loader();
    api.get_comparison_properties(sort)
      .then(data => {
        this.apartments = data.apartments.items
        this.set_comparison_id(this.apartments)
        loader.hidden_loader();
        this.comparisons_wrap.addClass('show');
        this.comparisons_list.empty();
        this.comparisons_list.append(data.apartments.items.map(i => get_template_comparison(i)));
        this.comparison__labels.empty();
        if (data.apartments.items.length){
          this.comparison__labels.append(get_comparison_labels(data.apartments.items[0]));
        }
        this.check_active_like();
        this.check_child_wrap()
      })
      .then(() => comparison_slider.update())
      .catch(
        (err) => {
          switch (err?.response?.status) {
            case 404:
              this.comparisons_wrap.removeClass('show');
              showModalError(this.modal_error);
              break;
            case 500:
              this.comparisons_wrap.removeClass('show');
              showModalError(this.modal_error);
              break;
          }
        }
      )
  }

  remove_comparison_item = () => {
    $(document).on('click', '.js-comparison-item-remove', (e) => {
      e.preventDefault();
      const id = Number(e.currentTarget.id);
      api.delete_comparison({ id: id })
      .then(() =>
        this.get_comparison_request()
      )
      .then(() => this.get_count_flat_request())
    })
  }

  action_favorit = () => favorite.action_favorit();
  check_active_like = () => favorite.check_active_like();

  propmt_block = () => {
    const fly_propmt = new FlyCardPrompt({
      trigger_class: 'js-object-card__remove',
      text: 'Можете удалить из сравнения.'
    });
    fly_propmt.init();
  }
  active_zoom_image = () => {
    const zoom_image = new ZoomImage({ trigger_class: 'js-card__img' });
    zoom_image.init();
  }
  download_comparison = () => {
    $('.js-btn-download-comparison').on('click', (e)=> {
      const sort = {only_differences: this.comparison_checkbox_value ? 1 : undefined}
      const queryString = sort.only_differences !== undefined ? `/?${new URLSearchParams(sort).toString()}` : '';

      e.preventDefault();
      window.open(`${API_DOWNLOAD_COMPARISON}${queryString}`, '_blank').focus();
    })
  }
}



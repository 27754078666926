import { getAllUrlParams } from "../utils/parse_url";
/**
 * @class - класс содержит логику переключения path в свг на детальной странице проекта
 * @method hidden_all_wrap - скрывает все контейнеры
 * @method init_infrastructure - определяет нужно ли показывать радиокнопку переключения инфраструктуры
 * @method hide_infrastucture_swicher - скрывает радиокнопку инфраструктуры
 * @method toggle_infrastructure_path - слушает состояние радиокнопки инфраструктуры,  показывает или скрывает path или circle  инфраструктуры в svg
 * @method init_active_category - при загрузке страницы определяет paths с каким типом показывать и устанавливает активным таб
 * @method target_category - слушает состояние табов,  показывает paths  с выбранным типом
 * @method switcher_type_rooms - делает видимым выбранный контейнер и кэшет выбранное значние
 */

export class SwitchGenplan {
    constructor() {
        this.infrastructure_swicher = $('.js-infrastructure-target');
        this.free_swicher = $('.js-free-target');
        this.collection_paths = {
            all: $('.js-genplan [data-typepath=all]'),
            house: $('.js-genplan [data-typepath=house]'),
            unfree_house: $('.js-genplan [data-free=unfree]'),
            queue: $('.js-genplan [data-typepath=queue]'),
            commercial: $('.js-genplan [data-typepath=commercial]'),
            infrastructure: $(".js-genplan [data-typepath=infrastructure]")
        }
        this.wrap_radiobutton = {
            all: $(".js-category-all"),
            house: $(".js-category-house"),
            queue: $(".js-category-queue"),
            commercial: $(".js-category-commercial")
        }
        this.prev_paths = [];
        this.show_unfree = !$('.js-free-trigger').is(':checked');
    }
    init = () => {
        this.hidden_all_wrap();
        this.init_infrastructure();
        this.init_free();
        this.init_active_category();
        this.target_category();
    }
    hidden_all_wrap = () => {
        this.collection_paths.house.addClass('hidden-wrap');
        this.collection_paths.queue.addClass('hidden-wrap');
        this.collection_paths.commercial.addClass('hidden-wrap');
        this.collection_paths.all.addClass('hidden-wrap');
    }
    init_infrastructure = () => {
        if (this.collection_paths.infrastructure.length) {
            this.toggle_infrastructure_path();
        } else {
            this.hide_infrastucture_swicher();
        }
    }
    hide_infrastucture_swicher = () => {
        this.infrastructure_swicher.css({ 'display': 'none' });
    }
    toggle_infrastructure_path = () => {
        let toggle_checked = false;
        this.infrastructure_swicher.on('click', () => {
            toggle_checked = !toggle_checked;
            if (toggle_checked) {
                this.collection_paths.infrastructure.addClass('hidden-wrap');
            } else {
                this.collection_paths.infrastructure.removeClass('hidden-wrap');
            }
        });
        this.infrastructure_swicher.trigger('click');
    }
    init_free = () => {
        if (this.collection_paths.unfree_house.length) {
            this.toggle_free_path();
        } else {
            this.hide_free_swicher();
        }
    }
    hide_free_swicher = () => {
        this.free_swicher.css({ 'display': 'none' });
    }
    toggle_free_path = () => {
        this.free_swicher.on('click', () => {
            this.show_unfree = !this.show_unfree;
            $('.js-category-radio-wrap').trigger('click');
        });
    }
    init_active_category = () => {
        const params = getAllUrlParams();
        if (params.type) {
            this.wrap_radiobutton[params.type].find('input').attr('checked', true);
            this.switcher_type_rooms(params.type);
        } else {
            if(this.collection_paths.all.length) {
              this.wrap_radiobutton.all.find('input').attr('checked', true);
              this.switcher_type_rooms('all');
            }
            else if(this.collection_paths.house.length){
                this.wrap_radiobutton.house.find('input').attr('checked', true);
                this.switcher_type_rooms('house');
            } else if(this.collection_paths.queue.length){
                this.wrap_radiobutton.queue.find('input').attr('checked', true);
                this.switcher_type_rooms('queue');
            } else {
                this.wrap_radiobutton.commercial.find('input').attr('checked', true);
                this.switcher_type_rooms('commercial');
            }
        }
    }
    target_category = () => {
        $('.js-category-radio-wrap').on('click', (e) => {
            const value = $(e.target).val();
            this.prev_paths.length && this.prev_paths.addClass('hidden-wrap');
            this.switcher_type_rooms(value);
        })
    }
    switcher_type_rooms = (value) => {
        switch (value) {
            case 'queue':
                this.prev_paths = this.collection_paths.queue;
                break;
            case 'house':
                this.prev_paths = this.collection_paths.house;
                break;
            case 'commercial':
                this.prev_paths = this.collection_paths.commercial;
                break;
              case 'all':
                this.prev_paths = this.collection_paths.all;
                break;
        }

        let targetElements = this.prev_paths;
        if (!this.show_unfree) {
            targetElements = this.prev_paths.filter('[data-free=free]')
        }

        targetElements.removeClass('hidden-wrap');
    }
}

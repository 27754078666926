import api from "../api/request";
import { get_local, set_local } from "../utils/storege";


class ComparisonActions {
  constructor() {
    this.count_comparison = $(".js-menu-comparison-count");
  }
  check_active_comparison = () => {
    const comparison = get_local('comparison').split(',');
    const btns_comparisons = $('.js-btn_comparison');
    for (let i of btns_comparisons) {
      for (let j of comparison) {
        if (Number($(i).attr('data-id')) === Number(j)) {
          i.classList.add("btn_comparison-active");
        }
      }
    }
  }
  action_comparison = () => {
    const self = this
    $(document).on("click", ".js-btn_comparison", function (e) {
      e.preventDefault();
      let id = Number($(this).attr('data-id'));
      const comparison = get_local('comparison').split(',');
      if (comparison) {
        if (comparison.find(i => Number(i) === Number(id))) {
          self.delete({ id: id }, this);
        } else {
          self.add({ property_id: id }, this);
        }
      } else {
        self.add({ property_id: id }, this);
      }
    })
  }
  get = () => {
    api.count_comparison()
      .then(data => {
        set_local('comparison', data.items)
        this.count_comparison.text(`(${data.total_count})`);
        return data
      })
  }
  add = async (params, button_like = null) => {
    await api.post_comparison(params)
      .then(data => {
        set_local('comparison', data.items);
        this.count_comparison.text(`(${data.total_count})`);
        if (button_like) {
          $(button_like).addClass("btn_comparison-active");
        }
        return data;
      })
  }
  delete = async (params, button_like) => {
    await api.delete_comparison(params)
      .then(data => {
        set_local('comparison', data.items);
        this.count_comparison.text(`(${data.total_count})`);
        if (button_like) {
          $(button_like).removeClass("btn_comparison-active");
        }
        return data;
      })
  }
}

export const comparison = new ComparisonActions();
comparison.action_comparison();

import { ProjectDetailMap } from "./section_map";
import { ActionSvg } from '../components/action_svg';
import { CalcMortgage } from '../components/calculations';
import { SwitchTownhouseQueue } from '../components/switch_queue';
import api from '../api/request';
import { get_render_drop_townhouse } from "../templates/templates_dropdown_cards";
/**
 * @class -  класс содержит логику для секции "отображение очереди таунхаусов"
 * @method target_flat - выводит информацию при наведение на свг помещения таунхауса
 * @method target_schema - выводит информацию при наведение на помещения таунхауса в схеме очереди
 * @method switch_details - инициализирует переключает план очереди и схемы
 * @method link_to - при выборе помещения на этаже открывает детальную страницу помещения таунхауса
 * @method init_custom_radiobutton - инициализирует работу кастомных радиокнопок
 */
export class TownhouseQueue {
  constructor() {
    this.queue = document.querySelector(".js-plan-queue").getAttribute("data-queue")
    this.form_pagination = $(".js-filter-flat-plan");
    this.block_wrap_pagination = $(".js-flat-plan-wrap");
    this.switcher_wrap = $(".js-switch-wrap");
  }
  init = () => {
    this.target_flat();
    this.target_schema();
    this.switch_details();
    this.init_custom_radiobutton();
    this.link_to();
  }
  target_flat = () => {
    const paths_flat = new ActionSvg({
      project: $("const paths_flat.js-plan-queue"),
      project_item: ".js-plan-queue [data-flat]",
      object_filters: {
        flat: "flat"
      },
      template: get_render_drop_townhouse,
      api: api.townhouse_flat
    });
    paths_flat.init();
  }
  target_schema = () => {
    const schema_flat = new ActionSvg({
      project: $(".js-plan-chema"),
      project_item: ".js-plan-flat-chema",
      object_filters: {
        flat: "flat"
      },
      template: get_render_drop_townhouse,
      api: api.townhouse_flat
    });
    schema_flat.init();
  }
  link_to = () => {
    $(".js-plan-queue path[data-typepath='property']").on('click', (e) => {
      const links_url = $(e.target).attr('data-link');
      window.open(`${links_url}`, '_blank').focus();
    })
  }
  switch_details = () => {
    const switcher_on_townhouse_queue = new SwitchTownhouseQueue();
    switcher_on_townhouse_queue.init();
  }
  init_custom_radiobutton = () => {
    this.switcher_wrap.on('click', function (e) {
      let switcher = $(this).find(".input__switch");
      if (!switcher.is(":disabled")) {
        let checked = switcher.attr("checked");
        switcher.attr("checked", !checked);
      }
    })
  }
}
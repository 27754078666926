import "./section_sliders"
import "./section_construction"
import "./section_delail_object_schema"
import "./section_filter_flat_count"
import "./section_header"
import "./section_map"
import "./section_plan_flat"
import "./section_youtube"
import "./section_decor"
import "./section_advanse"
import "./section_banks_calculation"
import "./section_landing_booking"
import "./section_booking_events"
import "./section_catalog_list"
import "./section_installment_calculation"
import "./section_room_detail"
import "./section_house_detail"
import "./section_project_detail"
import "./section_townhouse_detail"
import "./section_townhouse_queue"
import "./section_favorit_list"
import "./section_project_home_list"
import "./section_news_list"
import "./section_offices_list"
import "./section_partners_list"
import "./section_projects_list"
import "./section_promotions_list"
import "./section_vacancy_list"
import "./section_room_calculation"
import "./section_block_info"
import "./section_faq"
import "./section_project_menu";
import "./section_news_and_promotions_detail";

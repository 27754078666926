import { HouseMap } from "./section_map";
import { ActionSvg } from '../components/action_svg';
import { CalcMortgage } from '../components/calculations';
import api from '../api/request';
import { get_render_drop_townhouse } from "../templates/templates_dropdown_cards";
/**
 * @class -  класс содержит логику для секции "отобрадения дома таунхуса"
 * @method target_flat - выводит информацию при наведение на свг помещения таунхауса
 * @method switch_details - инициализирует переключает план очереди и схемы
 * @method link_to - при выборе помещения на этаже открывает детальную страницу помещения таунхауса
 */
export class TownhouseDetail {
  constructor() {
    this.house = document.querySelector(".js-plan-section path") && document.querySelector(".js-plan-section path").getAttribute("data-house");
    this.form_pagination = $(".js-filter-flat-plan");
    this.block_wrap_pagination = $(".js-flat-plan-wrap");
  }
  init = () => {
    this.target_flat();
    this.link_to();
  }
  
  target_flat = () => {
    const paths_flat = new ActionSvg({
      project: $(".js-townhouse"),
      project_item: ".js-townhouse [data-flat]",
      object_filters: {
        flat: "flat"
      },
      template: get_render_drop_townhouse,
      api: api.townhouse_flat
    });
    paths_flat.init();
  }
  link_to = () => {
    $(".js-townhouse path").on('click', (e) => {
      const links_url = $(e.target).attr('data-link');
      window.open(`${links_url}`, '_blank').focus();
    })
  }
}
import FlyCardPrompt from '../components/fly_card_prompt';

export class SectionLikeFlat {
  constructor() {
  }
  init = () => {
    this.init_fly_card();
  }
  init_fly_card() {
    new FlyCardPrompt({
      trigger_class: 'js-card-blocked',
      text: 'Квартира забронирована. Но от неё еще могут отказаться.'
    }).init();
    new FlyCardPrompt({
        trigger_class: 'js-townhouse-card-blocked',
        text: 'Таунхаус забронирован. Но от него еще могут отказаться.'
    }).init();
    new FlyCardPrompt({
        trigger_class: 'js-office-card-blocked',
        text: 'Офис забронирован. Но от него еще могут отказаться.'
    }).init();
  }
}



import { WAY_UP_DEF_IMG } from '../const/way_up'
import { conjugation } from '../utils/conjugation'
import { format_price_intl } from "../utils/format_price"
export function get_house_card(data) {
  return `
    <div class="flat-map">
        <div class='object-plan-info-img'>
        ${data.project_preview ?
      `<img class="flat-map__img" src="${data.project_preview}" alt='project'>`
      : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
            ${data.sales_start ? `<div class="object-plan-info-img-label">Старт продаж</div>` : ''}
        </div>
        <div class='object-plan-info-content'>
        <h4 class="project-plan__house-name">${data.title}</р>
        ${data.properties_on_sale_map.apartments | data.properties_on_sale_map.office ?
      `<div class="project-plan__forsale">
          <div class="project-plan__forsale-top">
            ${data.properties_on_sale_map.apartments ? `<span>${conjugation(data.properties_on_sale_map.apartments, ['квартира', 'квартиры', 'квартир'])} </span>` : ''}
            ${data.properties_on_sale_map.office ? `<span>${conjugation(data.properties_on_sale_map.office, ['офис', 'офиса', 'офисов'])} </span>` : ''}
          </div>
          <div class="project-plan__forsale-bottom">в продаже</div>
        </div>` : ''}

        ${data.properties_on_sale_map.apartments ?
      `<div class="project-plan__flats">
        ${data.properties_on_sale_prices_from.room1 ? `<div class="project-plan__flat"><p class="project-plan__flat-name">1 комнатная</p><p class="project-plan__flat-price">от ${format_price_intl.format(data.properties_on_sale_prices_from.room1)}</p></div>` : ''}
        ${data.properties_on_sale_prices_from.room2 ? `<div class="project-plan__flat"><p class="project-plan__flat-name">2 комнатная</p><p class="project-plan__flat-price">от ${format_price_intl.format(data.properties_on_sale_prices_from.room2)}</p></div>` : ''}
        ${data.properties_on_sale_prices_from.room3 ? `<div class="project-plan__flat"><p class="project-plan__flat-name">3 комнатная</p><p class="project-plan__flat-price">от ${format_price_intl.format(data.properties_on_sale_prices_from.room3)}</p></div>` : ''}
        </div>`:
      `<p class="project-plan__flat-price">Все помещения проданы</p>`}
      ${data.minimum_price && data.properties_on_sale_map.apartments ? `<div class="project-plan__deadline text-bold"><p class="project-plan__forsale-bottom">Цена от </p><p class="project-plan__forsale-finish">${format_price_intl.format(data.minimum_price)}</p></div>`
      : ``}
        </div>
    </div>`
}
export function get_render_drop_queue_map(data) {
  return `
        <div class='townh-map'>
            <div class='object-plan-info-img'>
                ${data.project_preview ?
      `<img class="flat-map__img" src="${data.project_preview}" alt='queue'>`
      : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
                ${data.sales_start ? `<div class="object-plan-info-img-label">Старт продаж</div>` : ''}
            </div>
            <div class='object-plan-info-content'>
            <h4 class="project-plan__house-name">${data.title}</h4>
            ${data.queue ? `<div class="project-plan__details-item"><span class="project-plan__forsale-bottom">Очередь таунхаусов: </span><strong>${data.queue}</strong></div>` : ''}
            ${data.properties_on_sale_map.townhouses ?
      `<div class="project-plan__forsale">
              <div class="project-plan__forsale-top">
                ${data.properties_on_sale_map.townhouses ? `<span>${conjugation(data.properties_on_sale_map.townhouses, ['таунхаус', 'таунхауса', 'таунхаусов'])} </span>` : ''}
              </div>
              <div class="project-plan__forsale-bottom">в продаже</div>
            </div>`
      : `<p class="project-plan__flat-price">Все помещения проданы</p>`}
      ${data.minimum_price && data.properties_on_sale_map.townhouses ? `<div class="project-plan__deadline text-bold"><p class="project-plan__forsale-bottom">Цена от </p><p class="project-plan__forsale-finish">${format_price_intl.format(data.minimum_price)}</p></div>`
      : ``}
            </div>
        </div>`
}
export const get_render_drop_project_map = (data) => {
  return `<div class="object-plan-info">
        <div class='object-plan-info-img'>
            ${data.project_preview ?
      `<img class="flat-map__img" src="${data.project_preview}" alt='project'>`
      : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
            ${data.sales_start ? `<div class="object-plan-info-img-label">Старт продаж</div>` : ''}
        </div>
        <div class='object-plan-info-content'>
            <h4 class="project-plan__house-name">${data.title}</h4>
            ${data.properties_on_sale_map ?
      ` <div class="project-plan__forsale">
                <div class="project-plan__forsale-bottom">в продаже</div>
                <div class="project-plan__forsale-top">
                    ${data.properties_on_sale_map.apartments ? `<span>${conjugation(data.properties_on_sale_map.apartments, ['квартира', 'квартиры', 'квартир'])} </span>` : ''}
                    ${data.properties_on_sale_map.offices ? `<span>${conjugation(data.properties_on_sale_map.offices, ['офис', 'офиса', 'офисов'])} </span>` : ''}
                    ${data.properties_on_sale_map.townhouses ? `<span>${conjugation(data.properties_on_sale_map.townhouses, ['таунхаус', 'таунхауса', 'таунхаусов'])} </span>` : ''}
                </div>
            </div>`:
    `<p class="project-plan__forsale-bottom">Все помещения проданы</p>`}
      ${data.minimum_price && data.properties_on_sale_map ? `<div class="project-plan__deadline text-bold"><p class="project-plan__forsale-bottom">Цена от </p><p class="project-plan__forsale-finish">${format_price_intl.format(data.minimum_price)}</p></div>`
      : ``}
        </div>
    </div>`
}
export function get_house_realize(data) {
  return `
    <div class="flat-map">
        <div class='object-plan-info-img'>
          ${data.house_preview ?
          `<img class="flat-map__img" src="${data.house_preview}" alt='project'>`
          : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
        </div>
        <div class='object-plan-info-content'>
          <h4 class="project-plan__house-name">${data.title}</h4>
          ${data.address ? `<div class="project-plan__details-item"><span class="project-plan__forsale-bottom">Адрес: </span><strong>${data.address}</strong></div>` : '<div></div>'}
          ${data.development_term ? ` <div class="project-plan__deadline"><p class="project-plan__forsale-bottom">Срок сдачи </p><p class='project-plan__deadline-value'>${data.development_term}</p></div>` : ''}
        </div>
    </div>`
}

/**
 * @class - класс содержит логику лоадера 
 * @method show_loader - показывает лоадер, может принимать параметр data-loader {string} 
 * @method hidden_loader - скрывает лоадер, может принимать параметр data-loader {string} 
 */
class Loader {
    constructor() {
        this.loader = $(".js-loader-wrap");
    }
    show_loader = (loader_name) => {
        if (loader_name) {
            $(`.js-loader-wrap[data-loader=${loader_name}]`).css({ "display": '' });
        } else {
            this.loader.css({ "display": '' });
        }
    }
    hidden_loader = (loader_name) => {
        if (loader_name) {
            $(`.js-loader-wrap[data-loader=${loader_name}]`).css({ "display": 'none' });
        } else {
            this.loader.css({ "display": 'none' });
        }
    }
}

export const loader = new Loader()
import { format_price_intl} from "../utils/format_price";
import { get_promo_url } from '../utils/get_promo_url.js';

export const get_template_comparison = (data) => {
  const numberPrice = Number.isFinite(Number(data.price)) ? format_price_intl.format(data.price) : data.price
  const numberNewPrice = Number.isFinite(Number(data.new_price)) ? format_price_intl.format(data.new_price) : data.new_price
  const hupotecFrom = data.hypotec_from.split(' ').map(el =>  Number.isFinite(Number(el)) ? format_price_intl.format(el).split('₽')[0] : el).join('')

  return `
  <li class="comparison-section__item swiper-slide">
  <div class="comparison-section__item flat-details-img-center">
    <img class=" comparison-section__item-image max-h-full" src="${ data.get_picture_2d }"
      data-src="${ data.get_picture_2d }" alt="2d flat_plan" />
    <div class="flat-card__price comparison-section__item-price-wrap">
    ${ data.new_price !== null
      ?`<span class="comparison-section__item-price price_new">${numberNewPrice}</span>
        <span class="comparison-section__item-price price_old">${numberPrice}</span>
        <div class="flat-card_sale flat-card_sale--template">
          * Скидки предоставляются в соответствии с разделом 
          <a href="${get_promo_url()}" class="flat-card_link--template" >Акции</a>
        </div>
        `
      :`<span class="comparison-section__item-price price_new">${numberPrice}</span>`
    }
      ${ data.hypotec_from ? `<span class="comparison-section__item-price hipoteca">от ${hupotecFrom}</span>` : ``}
    </div>
    <div class="comparison-section__item-info">
      ${data.project_title ? `<span class="comparison-section__item-info__block info__project_title">${data.project_title}</span>`:``}
      ${data.house ? `<span class="comparison-section__item-info__block info__house">${data.house}</span>`:``}
      ${data.floor ? `<span class="comparison-section__item-info__block info__floor">${data.floor}</span>`:``}
      ${data.rooms_count ? `<span class="comparison-section__item-info__block info__rooms">${data.rooms_count}</span>`:``}
      ${data.area ? `<span class="comparison-section__item-info__block info__area">${data.area}</span>`:``}
      ${data.area_kitchen ? `<span class="comparison-section__item-info__block info__area-kitchen">${data.area_kitchen}</span>`:``}
      ${data.development_quarter ? `<span class="comparison-section__item-info__block info__quarter">${data.development_quarter}</span>`:``}
      <button type="button" class="comparison-section__item-remove js-comparison-item-remove" id="${data.id}">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L14 14" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M2 14L14 2" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
    <div class="comparison-section__item-link">
        <a href="${data.absolute_url}">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="25px" height="25px" viewBox="0 0 25 25">
            <path d="M12 5C5.63636 5 2 12 2 12C2 12 5.63636 19 12 19C18.3636 19 22 12 22 12C22 12 18.3636 5 12 5Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
    </div>
    <div class="comparison-section__item-favorite flat-card__favorite flat-card__favorite--z-0">
    <button class="btn btn_like js-btn_like" data-id="${data.id}">
      <i class="icon icon_like">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.4 4.73438C5.96995 4.73438 4 7.02994 4 9.86166C4 14.9889 9.2 19.6501 12 20.7344C14.8 19.6501 20 14.9889 20 9.86166C20 7.02994 18.0301 4.73438 15.6 4.73438C14.1119 4.73438 12.7963 5.59524 12 6.91293C11.2037 5.59524 9.88813 4.73438 8.4 4.73438Z" stroke="#8B98A7" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </i>
    </button>
  </div>
  </div>
</li>
`;
};

export const get_comparison_labels = (data) => {
  const labels = {
    project_title: 'Проект',
    house: 'Дом',
    floor: 'Этаж',
    rooms_count: 'Комнат',
    area: 'Площадь',
    area_kitchen: 'Площадь кухни',
    development_quarter: 'Срок сдачи',
  }

  return `
  <div class="comparison-section__labels info__labels">
    ${data.project_title ? `<span class="comparison-section__label">${labels.project_title}</span>` : ``}
    ${data.house ? `<span class="comparison-section__label">${labels.house}</span>` : ``}
    ${data.floor ? `<span class="comparison-section__label">${labels.floor}</span>` : ``}
    ${data.rooms_count ? `<span class="comparison-section__label">${labels.rooms_count}</span>` : ``}
    ${data.area ? `<span class="comparison-section__label">${labels.area}</span>` : ``}
    ${data.area_kitchen ? `<span class="comparison-section__label">${labels.area_kitchen}</span>` : ``}
    ${data.development_quarter ? `<span class="comparison-section__label">${labels.development_quarter}</span>` : ``}
  </div>
  `
}

import { Header } from "./sections/section_header"
import { SectionFlatCount } from "./sections/section_filter_flat_count"
import { SectionConstruction } from "./sections/section_construction"
import { SectionDecor } from "./sections/section_decor"
import { SectionFlatPlan } from "./sections/section_plan_flat"
import { ProjectHomeList } from "./sections/section_project_home_list"
import { OfficesList } from "./sections/section_offices_list"
import { ProjectsList } from "./sections/section_projects_list"
import { PromotionsList } from "./sections/section_promotions_list"
import { VacancyList } from "./sections/section_vacancy_list"
import { PartnersList } from "./sections/section_partners_list"
import { CatalogList } from "./sections/section_catalog_list"
import { NewsList } from "./sections/section_news_list"
import { FavoritList } from "./sections/section_favorit_list"
import { LandingBooking } from "./sections/section_landing_booking"
import { BookingEvents } from "./sections/section_booking_events"
import { RoomDetail } from "./sections/section_room_detail"
import { HouseDetail } from "./sections/section_house_detail"
import { ProjectDetail } from "./sections/section_project_detail"
import { InstallmentCalculation } from "./sections/section_installment_calculation"
import { BanksCalculation } from "./sections/section_banks_calculation"
import { TownhouseQueue } from "./sections/section_townhouse_queue"
import { TownhouseDetail } from "./sections/section_townhouse_detail"
import { RoomCalculation } from "./sections/section_room_calculation"
import { BlockInfo } from "./sections/section_block_info"
import { SectionFAQ } from "./sections/section_faq"
import { ProjectMenu } from "./sections/section_project_menu"
import { SectionYoutube } from "./sections/section_youtube"
import { get_city } from "./components/get-city"
import { SectionAdvanse } from "./sections/section_advanse"
import { SectionMap } from "./sections/section_map"
import { SectionProjectMortgage } from "./sections/section_project_mortgage"
import { ArticleDetail } from "./sections/section_news_and_promotions_detail";
import { SectionLikeFlat } from "./sections/section_like_flat";
import { ComparisonList } from "./sections/section_comparison_list"
/**
 * @class - в зависимости от добавленой верстки создает экземпляры классов с находящийся на открытой странице
 */
class App extends Header {
    constructor(props) {
        super(props)
        this.sections = {
            section_project_index: ProjectHomeList,
            section_projects: ProjectsList,
            section_offices: OfficesList,
            section_vacansies: VacancyList,
            section_partners: PartnersList,
            section_catalog: CatalogList,
            section_news: NewsList,
            section_promotions: PromotionsList,
            section_news_and_promotions_detail: ArticleDetail,
            section_favorite: FavoritList,
            section_comparison: ComparisonList,
            section_landing_booking: LandingBooking,
            section_booking_events: BookingEvents,
            section_project_detail: ProjectDetail,
            section_townhouse_queue: TownhouseQueue,
            section_house_detail: HouseDetail,
            section_townhouse_detail: TownhouseDetail,
            section_room_detatil: RoomDetail,
            section_like_flat: SectionLikeFlat,
            section_filter_flat_1: SectionFlatCount,
            section_filter_flat_2: SectionFlatCount,
            section_construction_slider: SectionConstruction,
            section_decor: SectionDecor,
            section_calc_request: RoomCalculation,
            section_credit: InstallmentCalculation,
            section_banks: BanksCalculation,
            section_flat_plans: SectionFlatPlan,
            section_block_info: BlockInfo,
            section_faq: SectionFAQ,
            section_project_nav: ProjectMenu,
            section_video_playlist: SectionYoutube,
            section_advantages:SectionAdvanse,
            section_map: SectionMap,
            section_project_mortgage:SectionProjectMortgage
        }
    }
    init = () => {
        this.init_header();
        get_city.init_city()
            .then(() => {
                const sections = $('.js-sections');
                sections.each((index, item) => {
                    const sections_name = $(item).attr('id');

                    if (this.sections[sections_name] != null) {
                        const section = new this.sections[sections_name]();
                        section.init();
                    }
                })
            })
    }
}

export const app = new App();
app.init()

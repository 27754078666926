
import { loader } from './loader';
import { debounce } from '../utils/debounce'
/**
 * @class - класс содержит логику появляющегося информации при наведение на path или circle в svg или помещение в схеме
 * @method init_fly_card_html - создает fly_card
 * @method move_mouse_event - слушает событие движение курсора и при наведение на path или circle в svg или помещение в схеме определяет положение
 * @method mouse_leave - слушает событие пропадание курсора с триггера для скрытия подсказки
 * @method init_position - передает координаты в fly_card
 * @method init_position_heigth - отпределяет координат y
 * @method init_position_wigth - определяет уоординату x
 * @method mouse_event - метод используемый из вне для подставления верстки в контейнер и проверки для исключения не нужного перерендера
 * @method show_content - показывает flycard и content
 * @method check_window_width - проверяет разрешение при котором расположение карточки прижато к левому краю, нужно для адаптива
 */

export class FlyCard {
  constructor(disableOnMobile = true) {
    this.loader_name = 'fly-card';
    this.prev_item = null;
    this.heigth_header = 110;
    this.disable_on_mobile = disableOnMobile;
  }
  init = () => {
    if (!this.fly_card) {
      this.init_fly_card_html();
      this.move_mouse_event();
    }
  }
  init_fly_card_html = () => {
    const flyCard = $(`<div class="fly-card js-fly-card" data-typepath='true'>
      <button class="fly-card__close">×</button>
      <div class="drop-wrap js-drop-wrap"></div>
      <div class="loader-wrap js-loader-wrap" data-loader='fly-card'>
        <div class="loader"></div>
      </div>
    </div>`);
    const wrapper = $('.js-drop-wrap', flyCard);

    this.fly_card = flyCard[0];
    this.wrap_content = wrapper[0];

    $('.root').append(flyCard);
  }
  move_mouse_event = () => {
    document.addEventListener("mousemove", (e) => {
      if (this.disable_on_mobile && window.innerWidth <= 810) {
        this.hide_card();
        return;
      }

      if (e.target?.dataset?.typepath || e.target?.classList?.contains('js-plan-flat-chema')) {
        debounce(this.init_position(e), 1000)
      } else {
        this.hide_card();
      }
    })
  }
  hide_card = () => {
    this.fly_card.classList.remove('show');
    this.wrap_content.classList.remove('show')
    loader.hidden_loader(this.loader_name);
  }
  init_position = (e) => {
    const window_width = document.documentElement.clientWidth;
    if (window_width >= 769) {
      this.fly_card.style.transform = `translate(${e.pageX}px, ${e.pageY}px)`;
      this.fly_card.style.left = this.init_position_wigth(e.pageX);
    } else {
      this.fly_card.style.transform = `translate(20px, ${e.pageY}px)`;
    }
    this.fly_card.style.top = this.init_position_heigth(e.pageY);
  }
  init_position_heigth = (x_position) => {
    const window_height = +document.documentElement.clientHeight;
    const scrollTop = +$(document).scrollTop();
    const height_windows = window_height + scrollTop;
    const drop_height = +this.fly_card.offsetHeight;
    const drop_position = x_position + drop_height - this.heigth_header;
    if (height_windows <= drop_position) {
      return `-${drop_height + 10}px`;
    } else {
      return `10px`;
    }
  }
  init_position_wigth = (y_position) => {
    const window_width = document.documentElement.clientWidth;
    const drop_width = this.fly_card.offsetWidth;
    const drop_position = y_position + drop_width;
    if (window_width <= drop_position) {
      return `-${drop_width + 20}px`;
    } else {
      return `10px`;
    }
  }
  mouse_event = (data, event) => {
    if (this.prev_item !== event.target) {
      this.prev_item = event.target;
      this.wrap_content.innerHTML = data;
      this.show_fly_card();
      debounce(this.init_position(event), 1000)
    }else{
      this.show_fly_card();
    }
  }
  show_fly_card = () => {
    this.fly_card.classList.add('show');
    if (this.wrap_content.textContent) {
      this.hidden_loader()
    }
  }
  show_loader = () => {
    this.wrap_content.classList.remove('show');
    loader.show_loader(this.loader_name);
  }
  hidden_loader = () =>{
    this.wrap_content.classList.add('show');
    loader.hidden_loader(this.loader_name);
  }
  check_window_width = (coord) => {
    if (document.documentElement.clientWidth <= 769) {
      return 0;
    } else {
      return coord;
    }
  }
}

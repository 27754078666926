export function getAllUrlParams(url) {
  return Object.fromEntries(
    new URL(url ?? window.location).searchParams.entries()
  );
}

export function parseOptionInOpject(options) {
  let new_options = options
  for (let key in options) {
    if (options[key].constructor === Array) {
      new_options[key] = options[key].join(',')
    }
  }
  return new_options
}

export function parseOptionInString(options) {
  let str = ''
  for (let key in options) {
    str += `${key}=${options[key]}&`
  }
  str = str.substring(0, str.length - 1)
  return str
}

// фильтрует список фильтров согласно согласно активному контенту
export const is_new_options = (optionObj) => {
  const new_options = {}
  for (let key in optionObj) {
    new_options[key] = optionObj[key]
  }
  return new_options
}
export const seporator_params_in_object = (params) =>{
  let new_params ={}
  for (let key in params) {
    if(params[key].includes(',')){
      new_params[key] = params[key].split(',')
    }else{
      new_params[key] = params[key]
    }
  }
  return new_params
}

import {
    get_template_booking_event,
    get_template_application_month,
    get_template_application_date,
    get_template_application_times,
    get_template_events,
    form_registration_template
} from "../templates/templates_booking";
import { loader } from "../components/loader";
import { dayjs_weekand } from '../utils/dayjs'
import api from "../api/request";
import { showModalError } from "../components/modal";
import { validation } from "../utils/inputmask";
import form_option from "../utils/trigget-filters";

/**
 * @class - класс содержит логику для секции блока "Онлайн запись"
 * @method get_events - запрашивает список ивенотов
 * @method render_events - рендерит ивенты
 * @method target_event - слушает событие выбора ивента, запрашивает список месяцев и дат
 * @method target_mounth - переключает контейнер дней при нажатие на таб месяца
 * @method target_date -  слушает событие выбора даты, запрашивает время на выбранную дату
 * @method target_time - слушает событие выбора времени
 * @method submit_form - слушает событие submit формы записи
 * @method step_back_on_list_events - слушает событие возврата к контейнеру списка ивентов
 * @method step_back_on_choice_date - слушает событие возврата к контейнеру списка дат и месяцев
 * @method step_back_on_choice_time - слушает событие возврата к контейнеру списка времени
 * @method show_list_events - делает активный контейнер ивентов и скрывает все остальные
 * @method show_choice_date - делает активный контейнер выбора дат и месяцев и скрывает все остальные
 * @method show_choice_time - делает активный контейнер выбора врени и скрывает все остальные
 * @method show_form_registration - делает активный контейнер с формой регистрации и скрывает все остальные
 * @method show_registration_finish - делает активный контейнер с информацией после регистрации и скрывает все остальные
 * @method get_application_chosen_dates - запрашивает месяца и даты
 * @method render_dates - рендерит месяцы и даты
 * @method get_application_chosen_times - запрашивает время
 * @method render_times - рендерит время
 * @method set_booking - отправляет запрос на регистрацию
 * @method results_request - отрабатывает ответ о успешной регистрации
 */
export class BookingEvents {
    constructor() {
        this.event_container = $(".js-application-events");
        this.write_container = $('.js-application-write');
        this.write_date_container = $('.js-application-date');
        this.write_time_container = $('.js-application-time');
        this.registration_container = $('.js-booking-registration')
        this.finish_container = $(".js-application-finish");
        this.form_registration = $(".js-form-registration");
        this.btn_choice_date = $(".js-new-date");
        this.btn_choice_time = $(".js-new-time");
        this.collection_events = $('.js-target-events');
        this.veiw_date = $('.js-application-chosen__date');
        this.veiw_time = $('.js-application-chosen__time');
        this.view_weekend = $(".js-application-chosen__week");
        this.chosen_event_wrap = $(".js-chosen-event-wrap");
        this.events_wrap = $('.js-booking-events');
        this.registration_wrap = $('.js-booking-registration-wrap');
        this.booking_wrap_times = $('.js-booking-wrap-times');
        this.booking_wrap_month = $('.js-booking-wrap-month');
        this.booking_wrap_dates = $('.js-booking-wrap-days');
        this.collection_options = {};
        this.type_event = null;
        this.days_week = [
            'Понедельник',
            'Вторник',
            'Среда',
            'Четверг',
            'Пятница',
            'Суббота',
            'Воскресенье',
        ];
        this.month = {
            1: "Января",
            2: "Февраля",
            3: "Марта",
            4: "Апреля",
            5: "Мая",
            6: "Июня",
            7: "Июля",
            8: "Августа",
            9: "Сентября",
            10: "Октября",
            11: "Ноября",
            12: "Декабря",
        }
        this.title_date = $('.js-booking-title-date');
        this.title_form = $('.js-booking-title-form');
        this.title_finish = $('.js-booking-title-finish');
        this.repite_info_wrap = $('.js-booking-repite-info');
        this.booking_empty = $(".js-wrap-empty");
        this.loader_wrap = $(".js-loader-wrap");
        this.modal_error = $('.js-req-modal__error');
        this.btn_submit_form = $('.js-booking-button-submit');
        this.empty_date = $(".js-booking-empty");
        this.day = null;
        this.time = null;
        this.month_value = null;
        this.year = new Date().getFullYear();
    }
    init = () => {
        this.get_events();
        this.target_event();
        this.target_mounth();
        this.target_date();
        this.target_time();
        this.submit_form();
        this.step_back_on_list_events();
        this.step_back_on_choice_date();
        this.step_back_on_choice_time();
    }
    select_event = (event) => {
        const eventId = event.attr('data-id');
        const eventType = event.attr('data-type-event');

        this.collection_options.event_pk = eventId;
        this.collection_options.event = eventId;
        this.type_event = eventType;
        this.get_application_chosen_dates();
        this.show_choice_date();

        window.location.assign('#event-' + eventId);
    }
    // переключает на выбор даты
    target_event = () => {
        const self = this;
        $(document).on('click', '.js-target-events', function (e) {
            self.select_event($(this));
        })
    }
    target_mounth = () => {
        $(document).on("click", '.js-target-month', (e) => {
            let value = $(e.target).val();
            $(".js-target-month").removeClass('month__active');
            $(e.target).addClass('month__active');
            $(`.js-trigger-month`).removeClass('show');
            $(`.js-trigger-month[data-mount=${value}]`).addClass('show');
        })
    }
    // переключает на выбор времени
    target_date = () => {
        $(document).on('click', ".js-radio-input[name=day]", (e) => {
            this.day = $(e.target).val();
            const month_target = $('input[name=month]:checked');
            this.month_value = month_target.val();
            this.year = $(month_target).data('year');
            if (this.day && this.month_value) {
                const days = dayjs_weekand(`${this.year}-${this.month_value}-${this.day}`).format('YYYY-MM-DD');
                const number_weekend = dayjs_weekand(days).weekday() - 1;
                const weekend = this.days_week[number_weekend];
                this.veiw_date.text(`${this.day} ${this.month[this.month_value]}`);
                this.view_weekend.text(weekend);
                this.get_application_chosen_times({
                    date: `${days}`
                })
                this.btn_submit_form.text('Записаться');
                this.btn_submit_form.prop('disabled', false);
                this.show_choice_time();
            }
        })
    }
    // переключает на форму заполнения данных для записи
    target_time = () => {
        $(document).on('click', ".js-radio-input[name=time]", (e) => {
            this.time = e.target.value;
            if (this.time) {
                this.veiw_time.text(`${this.time}`);
                this.collection_options.event_date = `${this.year}-${this.month_value}-${this.day}`
                this.collection_options.event_time = this.time;
                this.show_form_registration();
                validation.reset_input_init();
            }
        })
    }
    // отправляет данные формы и переключает на благодарность
    submit_form = () => {
        this.form_registration.on("submit", (e) => {
            e.preventDefault();
            $('.js-input-wrap').removeClass('form-error');
            $('.js-input-error').text('');
            const options = form_option.get_options_form(this.form_registration)
            delete this.collection_options.event_pk;
            this.collection_options = { ...this.collection_options, ...options }
            const input_first_name = this.form_registration.find('input[name=first_name]')[0]
            if (input_first_name) {
                this.collection_options.first_name = input_first_name.value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
            }
            const input_phone = this.form_registration.find('input[name=phone]')[0]
            if (input_phone) {
                this.collection_options.phone = input_phone.value.replace(/[^0-9]/ig, '');
            }
            const input_contruct = this.form_registration.find('input[name=contract_number]')[0]
            if (input_contruct && input_contruct.value.length < 1) {
                $(".js-input-wrap[data-input=contract_number]").addClass('form-error');
                $('.js-input-error[data-error=contract_number]').text('Номер договора не может быть пустым');
                return false
            }
            const input_flat = this.form_registration.find('input[name=flat_number]')[0]
            if (input_flat && input_flat.value.length < 1) {
                $(".js-input-wrap[data-input=flat_number]").addClass('form-error');
                $('.js-input-error[data-error=flat_number]').text('Номер квартиры не может быть пустым');
                return false
            }
            const input_email = this.form_registration.find('input[name=email]')[0]
            if (input_email && input_email.value.length < 1) {
                $(".js-input-wrap[data-input=email]").addClass('form-error');
                $('.js-input-error[data-error=email]').text('Электронная почта не может быть пустой');
                return false
            }
            this.set_booking();
        })
    }

    // возвращает на выбор мероприятия
    step_back_on_list_events = () => {
        $(document).on('click', '.js-back-events', () => {
            this.collection_options = {};
            this.show_list_events();
        })
    }
    //возвращает на выбор даты
    step_back_on_choice_date = () => {
        this.btn_choice_date.on('click', () => {
            this.show_choice_date();
        })
    }
    //возвращает на выбор времени
    step_back_on_choice_time = () => {
        this.btn_choice_time.on('click', () => {
            this.show_choice_time();
        })
    }
    // показывает контейнер мероприятий
    show_list_events = () => {
        this.event_container.addClass('show');
        this.write_container.removeClass('show');
        this.write_date_container.removeClass('show');
        this.write_time_container.removeClass('show');
        this.registration_container.removeClass('show');
        this.collection_events.removeClass('show');
        this.finish_container.removeClass('show');
        if (window.innerWidth <= 1024)
          document.getElementById("form_booking_events").scrollIntoView();
    }
    // показывает контейнер выбора даты
    show_choice_date = () => {
        this.empty_date.removeClass('show');
        this.event_container.removeClass('show');
        this.write_container.addClass('show');
        this.write_date_container.addClass('show');
        this.write_time_container.removeClass('show');
        this.registration_container.removeClass('show');
        this.title_date.addClass('show');
        this.title_form.removeClass('show');
        this.title_finish.removeClass('show');
        if (window.innerWidth <= 1024)
          document.getElementById("section_application_date").scrollIntoView();
    }
    // показывает контейнер выбора времени
    show_choice_time = () => {
        this.write_container.addClass('show');
        this.write_date_container.removeClass('show');
        this.write_time_container.addClass('show');
        this.registration_container.removeClass('show');
        this.title_date.addClass('show');
        this.title_form.removeClass('show');
        this.title_finish.removeClass('show');
        if (window.innerWidth <= 1024)
          document.getElementById("section_application_time").scrollIntoView();

    }
    // показывает форму отправки записи на мероприятие
    show_form_registration = () => {
        this.title_date.removeClass('show');
        this.title_finish.removeClass('show');
        this.write_time_container.removeClass('show');
        this.title_form.addClass('show');
        this.registration_container.addClass('show');
    }
    // показывает контейнер благодарности регистрации
    show_registration_finish = () => {
        this.title_date.removeClass('show');
        this.title_form.removeClass('show');
        this.title_finish.addClass('show');
        this.registration_container.removeClass('show');
        this.finish_container.addClass('show');
    }
    get_events = () => {
        api.get_events()
            .then(data => {
                loader.show_loader();
                if (data.length) {
                    loader.hidden_loader();
                    data.length && this.render_events(data);

                    const eventId = window.location.hash.split('event-')[1];
                    const event = $(`.js-target-events[data-id=${eventId}]`);
                    if (eventId && event.length > 0) {
                        this.select_event(event);
                    }
                } else {
                    loader.hidden_loader();
                    this.booking_empty.addClass("show");
                }
            })
            .catch(err => {
                switch (err?.response?.status) {
                    case 404:
                    case 500:
                        showModalError(this.modal_error);
                        break;
                }
            })
    }
    render_events = (data) => {
        this.events_wrap.append(data.map(i => get_template_events(i)));
    }
    get_application_chosen_dates = () => {
        api.get_application_chosen_dates(this.collection_options)
            .then(data => {
                this.render_dates(data)
                this.render_form(data.event_forms)
            })
            .then(() => this.active_first_mounth())
            .catch(err => {
                switch (err?.response?.status) {
                    case 404:
                    case 500:
                        showModalError(this.modal_error);
                        break;
                }
            })
    }
    render_dates = (data) => {
        this.booking_wrap_dates.children().remove();
        this.chosen_event_wrap.children().remove();
        this.booking_wrap_month.children().remove();
        if (data.events_dates.length) {
            this.booking_wrap_dates.append(data.events_dates.map(i => this.booking_wrap_dates.append(get_template_application_date(i))));
            this.booking_wrap_month.append(data.events_dates.map(i => get_template_application_month(i)));
        } else {
            this.empty_date.addClass('show');
        }
        this.chosen_event_wrap.append(get_template_booking_event(data));
        const form_elem = $('#section_application_date');
        window.scrollTo({
          top: form_elem.scrollTop,
          left: form_elem.scrollLeft
        });
    }
    render_form = (data) => {
        this.registration_wrap.html(form_registration_template(data))
    }
    active_first_mounth = () => {
        const first_mounth = $(".js-target-month")[0];
        let value = $(first_mounth).val();
        $(first_mounth).attr('checked', true);
        $(first_mounth).addClass('month__active');
        $(`.js-trigger-month[data-mount=${value}]`).addClass('show');
    }
    get_application_chosen_times = (dates) => {
        api.get_application_chosen_times(this.collection_options, dates)
            .then(data => this.render_times(data))
            .catch(err => {
                switch (err?.response?.status) {
                    case 404:
                    case 500:
                        showModalError(this.modal_error);
                        break;
                }
            })
    }
    render_times = (data) => {
        this.booking_wrap_times.children().remove();
        data.times_list.forEach(i => this.booking_wrap_times.append(get_template_application_times(i)));
    }
    set_booking = () => {
        api.set_booking(this.collection_options)
            .then(data => {
                this.results_request(data);
            })
            .catch(err => {
                const error = err.response.data;
                if (error.first_name && error.first_name[0]) {
                    $(".js-input-wrap[data-input=first_name]").addClass('form-error');
                    $('.js-input-error[data-error=first_name]').text(error.first_name[0]);
                }
                if (error.phone && error.phone[0]) {
                    $(".js-input-wrap[data-input=phone]").addClass('form-error');
                    $('.js-input-error[data-error=phone]').text(error.phone[0]);
                }
                if (error.contract_number && error.contract_number[0]) {
                    $(".js-input-wrap[data-input=contract_number]").addClass('form-error');
                    $('.js-input-error[data-error=contract_number]').text(error.contract_number[0]);
                }
                if (error.flat_number && error.flat_number[0]) {
                    $(".js-input-wrap[data-input=flat_number]").addClass('form-error');
                    $('.js-input-error[data-error=flat_number]').text(error.flat_number[0]);
                }
                if (error.email && error.email[0]) {
                    $(".js-input-wrap[data-input=email]").addClass('form-error');
                    $('.js-input-error[data-error=email]').text(error.email[0]);
                }
                if (error.non_field && error.non_field[0]) {
                    this.btn_submit_form.text(error.non_field[0]);
                    this.btn_submit_form.prop('disabled', true);
                }
            })
    }
    results_request = (data) => {
        if (data) {
            this.repite_info_wrap.text(data);
            this.show_registration_finish();
        }
    }
}

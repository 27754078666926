import './templates_construction';
import './templates_vacansies';
import './templates_office.js';
import './tempates_favorite.js';
import './templates_dropdown_cards.js';
import './templates_news_and_promotions.js';
import './templates_catalog.js';
import './templates_map_cards.js';
import './templates_partner.js';
import './templates_schema.js';
import './templates_booking.js';
import './templates_filters.js';
import './templates_search.js';
import './templates_banks.js';
import './templates_projects.js';
import './templates_decor.js'
import form_option from '../utils/trigget-filters';
/**
 * @class - класс содержит логику сортировки
 * @method init_sort - слушает событие нажатия на сортировку, определяет направление сортировки и может менять его
 * @method install_active_button - подсвечивает активную сортировку и меняет направление стрелки
 * @method reset_active_sort - убирает активное состояние с кнопки сортировки
 * @method get_options_request_sort - собирает qwery сроку для запроса
 */
export class AbstractSort {
    constructor(props) {
        this.btn_sort = props.btn_sort;
        this.wrap_content = props.wrap_content;
        this.form = props.filter;
        this.sort_value = true;
        this.id_plan = '';
    }
    init_sort = () => {
        const self = this;
        let prev_order = '';
        this.btn_sort.on('click', function() {
            self.wrap_content.children().remove();
            const order = this.getAttribute("data-sort");
            if (order) {
                if (prev_order === order) {
                    self.sort_value = !self.sort_value;
                } else {
                    prev_order = order;
                    self.sort_value = true;
                }
                self.install_active_button(this);
                const options = self.get_options_request_sort(order);
                console.log('sort', options)
                self.request(options, self.id_plan);
            }
        })
    }
    install_active_button = (button) => {
        this.btn_sort.removeClass('active_sort');
        $(button).addClass('active_sort');
        if (this.sort_value) {
            $(button).removeClass('rotate_icon');
        } else {
            $(button).addClass('rotate_icon');
        }
    }
    reset_active_sort = () => {
        this.btn_sort?.removeClass('active_sort');
        this.btn_sort?.removeClass('rotate_icon');
    }
    get_options_request_sort = (sort_name) => {
        const order = this.sort_value ? sort_name : `-${sort_name}`;
        const options = form_option.get_qwery_string(this.form);
        return { ...options, ordering: order, page: 1, page_size: this.page_size };
    }
    request = () => { }
}

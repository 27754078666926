import { get_local } from '../utils/storege';
import {
    get_template_offices,
    get_template_filter_city_offices,
    get_template_menager
} from '../templates/templates_office';
import { OfficeMap } from './section_map';
import api from '../api/request';
import { slider_manager } from './section_sliders';
import { loader } from '../components/loader';

/**
 * @class  класс содержит логику для секции "списка офисов, слайдера менеджеров в офисах и отображает офисы на карте" 
 * @method render_filter_city - устанавливает активный город
 * @method target_city - слушает события выбора города
 * @method get_city_request_api - запрашивает список городов
 * @method render_city - рендерит табы городов
 * @method request_api_offices - отправляет запрос на получение офисов и менеджеров, исключет не нужный перерендер и обновляет слайдер
 * @method render_office - рендерит офисы 
 * @method render_manager_slider -рендерит менеджеров 
 * @method render_page - кэшит данные предыдушего запроса рапускает рендер офисов и карты
 */

export class OfficesList {
    constructor() {
        this.offices_wrap = $('.js-offices-wrap');
        this.offices_content = $('.js-offices-content');
        this.managers_wrap = $('.js-managers-wrap');
        this.managers_container = $(".js-managers-container")
        this.manager_controlls = $(".js-manager-controlls")
        this.wrap_city_offices = null;
        this.map = null;
        this.prev_data = null;
        this.map_wrap = $('#js-map')
        this.empty_wrap = $(".js-wrap-empty")
        this.city = get_local('city');
    }
    init = () => {
        this.render_filter_city();
        this.target_city();
    }
    render_filter_city = () => {
        this.get_city_request_api();
        $(".js-checked-city").map((ind, i) => {
            i.value === city && i.setAttribute('checked', '');
        })
        this.request_api_offices({ city: get_local("city") })
        this.wrap_city_offices = $(".js-wrap-city-offices");
    }
    target_city = () =>{
        $(document).on('click', ".js-checked-city", (e) => {
            this.city = e.target.value;
            this.request_api_offices({ city: this.city });
        })
    }
    get_city_request_api = () => {
        api.city()
            .then(data => this.render_city(data))
    }
    render_city = (data) => {
        this.wrap_city_offices = $(".js-wrap-city-offices");
        if (data.cities.length) {
            this.wrap_city_offices.append(data.cities.map(i => get_template_filter_city_offices(i)));
            $(".js-checked-city").each((i, item) => {
                Number(item.value) === Number(this.city) && item.setAttribute('checked', 'true');
            })
        }
    }
    request_api_offices = (props) => {
        this.show_loader();
        api.get_office(props).then(data => {
            if (this.prev_data) {
                if (JSON.stringify(data[0]) === JSON.stringify(this.prev_data)) {
                    this.hidden_loader()
                }
                this.render_page(data);
            } else {
                this.render_page(data);
            }

        })
            .then(() => slider_manager.update())
    }
    render_page = (data) => {
        this.prev_data = data[0];
        this.render_office(data);
    }
    render_office = (data) => {
        this.remove_content();
        if (data.length) {
            this.show_contents();
            this.offices_content.append(data.map(i => get_template_offices(i)));
            this.render_manager_slider(data)
        } else {
            this.show_empty()
        }
    }
    show_loader = () =>{
        this.offices_wrap.removeClass('show');
        this.empty_wrap.removeClass('show');
        this.managers_wrap.addClass('hidden-wrap');
        loader.show_loader();
    }
    hidden_loader = () =>{
        this.offices_wrap.addClass('show');
        this.empty_wrap.addClass('show');
        this.managers_wrap.removeClass('hidden-wrap');
        loader.hidden_loader();
    }
    remove_content = () => {
        this.offices_content.children().remove();
        this.managers_wrap.children().remove();
        this.map_wrap && this.map_wrap.children().remove();
    }
    show_contents = () => {
        this.offices_wrap.addClass('show');
        this.map_wrap.css({ "display": "" });
        this.managers_container.removeClass('hidden-wrap');
        this.managers_wrap.removeClass('hidden-wrap');
        this.empty_wrap.removeClass('show');
        loader.hidden_loader();
    }
    show_empty = () => {
        this.offices_wrap.removeClass('show');
        this.managers_container.addClass('hidden-wrap');
        this.map_wrap.css({ "display": "none" });
        this.empty_wrap.addClass('show');
        loader.hidden_loader();
    }
    render_manager_slider = (data) => {
        let manager_array = []
        data.forEach((i) => {
            manager_array = [...manager_array, ...i.manager]
        })
        if (manager_array.length) {
            this.managers_wrap.append(manager_array.map(j => get_template_menager(j)));
            this.managers_container.removeClass('hidden-wrap');
            if (manager_array.length < 2) {
                this.manager_controlls.addClass('hidden-wrap')
            } else {
                this.manager_controlls.removeClass('hidden-wrap')
            }
        } else {
            this.managers_container.addClass('hidden-wrap');
        }
    }
}

import api from "../api/request";
import { favorite } from "../components/favorite_actions";
import { search_empty, search_item } from "../templates/templates_search";
import { get_local, set_local } from "../utils/storege";
import { debounce } from '../utils/debounce'
import { comparison } from "../components/comparison_actions";
/**
 * @class -  класс содержит логику для управления компонентов в header
 * @method set_cookie - устанавливает куки если пользователь подтвердил согласие
 * @method mobile_menu_header - слушает события в header мобильного меню
 * @method mobile_menu_footer - слушает события в footer мобильного меню
 * @method error_modal - слушает событие кнопки "перезагрузки стрнаницы" в модалке ошибки
 * @method search_modal - слушает события отправки запроса в модалке поиска
 * @method search_request - отправляет запрос в модалке поиска
 * @method show_search_results - обрабатывает ответ в модалке поиска
 * @method active_page - выделяет активную страницу в ссылках меню
 * @method action_drop_menu - отпределяет находимся ли в сылках подменю "как купить" если нет то слушает события наведения и размера окна
 * @method show_drop_menu - показывает меню на определенное время и прирывает set_time_out_drop_menu
 * @method set_time_out_drop_menu - устанавливает задерку по которой скрывается выпадающие меню
 * @method get_likes_counts_request - запрашивает кол-во помещений в избранном
 * @method return_up - перемещает в начало страницы
 */
export class Header {
    constructor() {
        this.csrf = document.cookie.replace(/(?:(?:^|.*;\s*)csrftoken\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        this.href_active_page = $(location).attr('pathname');
        this.active_link = $(`.js-header-menu-item a[href="${this.href_active_page}"]`);
        this.how_buy_menu_item = $('.js-header-menu-buy');
        this.how_buy_menu = $('.js-header-how-buy');
        this.consentCookie = $(".js-consent-cookie");
        this.consentConfirmation = $(".js-consent-confirmation");
        this.yes_cookie = get_local('consent-cookie');
        this.count_favorite = $(".js-menu-favorites-count");
        this.header_wrap = $(".js-header");
        this.mobile_header_wrap = $(".js-header-menu-small");
        this.btn_open_mobile_menu = $(".js-header-mini-open");
        this.btn_close_mobile_menu = $('.js-header-mini-close');
        this.mobile_footer_wrap = $(".js-footer-menu-mini");
        this.btn_open_mobile_menu_footer = $(".js-footer-mini-open");
        this.btn_close_mobile_menu_footer = $('.js-footer-mini-close');
        this.reload_btn = $('.js-reload-page');
        this.time_out_menu = null;
        this.current_page = 1;
        this.isNextPage = true;
        this.results = [];
        this.isSearchLoad = false
        this.currentValue = '';
    }
    init_header = () => {
        this.set_cookie();
        this.mobile_menu_header();
        this.mobile_menu_footer();
        this.active_page();
        this.action_drop_menu();
        this.return_up();
        this.error_modal();
        this.search_modal();
        this.get_likes_counts_request();
        this.get_comparisons_counts_request();
    }
    mobile_menu_header = () => {
        window.addEventListener('resize', () => {
            if (parseInt(window.innerWidth) >= 1024.98) {
                if (this.header_wrap.hasClass("hidden-wrap")) {
                    $('body').removeClass('scroll-lock');
                }
            } else {
                if (this.header_wrap.hasClass("hidden-wrap")) {
                    $('body').addClass('scroll-lock');
                }
            }
        })
        this.btn_open_mobile_menu.on('click', () => {
            this.mobile_header_wrap.addClass('show');
            this.header_wrap.addClass("hidden-wrap");
            $('body').toggleClass('scroll-lock');
        })

        this.btn_close_mobile_menu.on('click', () => {
            this.mobile_header_wrap.removeClass('show');
            this.header_wrap.removeClass("hidden-wrap");
            $('body').toggleClass('scroll-lock');
        })
    }
    mobile_menu_footer = () => {
        this.btn_open_mobile_menu_footer.on('click', () => {
            this.mobile_footer_wrap.addClass('show');
            this.header_wrap.addClass("hidden-wrap");
            $('body').toggleClass('scroll-lock');
        })

        this.btn_close_mobile_menu_footer.on('click', () => {
            this.mobile_footer_wrap.removeClass('show');
            this.header_wrap.removeClass("hidden-wrap");
            $('body').toggleClass('scroll-lock');
        })

        // Expand mobile menu items.
        const menuItems = $(`.js-footer-menu-item`);
        menuItems.on("click", function(e) {
          if ($(e.currentTarget).is(this) && $(e.currentTarget).hasClass('show')) {
            $(e.currentTarget).removeClass('show');
          } else {
            $(e.currentTarget).addClass("show");
          }
        })
    }
    error_modal = () => {
        this.reload_btn.on('click', () => {
            location.reload();
        })
    }
    search_modal = () => {
        $(document).on("keydown", ".js-search-form__input input", (e) => {
            if (e.key === "Enter") {
                this.results = [];
                this.current_page = 1;
                $(".js-req-modal__search .search-results").remove();
                if (e.target.value === '') {
                    this.show_search_results(e.target.value);
                } else {
                    this.search_pagination({ q: e.target.value })
                }
            }
        })
        $(".js-search-form").on('submit', (e) => {
            e.preventDefault();
            this.results = [];
            this.current_page = 1;
            const value = $(".js-search-form__input input").val();
            $(".js-req-modal__search .search-results").remove();
            this.currentValue = value;
            if (value === '') {
                this.show_search_results(value);
            } else {
                this.search_pagination({ q: value });
            }
        })

        $('.js-search-form__list').scroll(()=>{
            const value = this.currentValue;
            const search = document.querySelector('.js-search-form__list')
            let ch = search.clientHeight;
            let sh = search.scrollHeight;
            let st = search.scrollTop;
            if ((sh - ch) <= st && this.isNextPage) {
                debounce(this.search_request({ q: value, page: this.current_page}), 1000);
            }
        })
    }
    search_pagination = (params, data={}) =>{
        const { results = [] } = data;
        this.results = [].concat(this.results, results);
        this.show_search_results(this.results);
        if (this.current_page === 1) {
            this.search_request({ q: params.q, page: this.current_page});
        };

    }
    search_request = (params) => {
        if(!this.isSearchLoad) {
            this.isSearchLoad = true
            api.search(params)
            .then(data => {
                this.isSearchLoad = false
                this.current_page += 1
                this.isNextPage = !!data.next
                this.search_pagination(params, data);
            })
            .catch(err => {
                switch (err?.response?.status) {
                    case 404:
                    case 500:
                        showModalError($('.js-req-modal__error'));
                        this.hidden_btn();
                        break;
                }
            })
        }
    }
    show_search_results = (data) => {
        const wrap = $(".js-search-form__list");
        wrap.children().remove();
        if (data.length) {
            wrap.append(data.map(item => search_item(item)));
        } else {
            wrap.append(search_empty());
        }
        wrap.addClass("show");
    }
    active_page = () => {
        const active_link = $(`.js-header-menu-item a[href="${this.href_active_page}"]`);
        active_link && active_link.parent().addClass('active-page');
    }
    action_drop_menu = () => {
        if (this.how_buy_menu.find('.active-page').length) {
            this.how_buy_menu.addClass('show-header-how-buy');
            this.how_buy_menu_item.addClass('active-page');
        } else {
            $(document).on('mouseenter', ".js-header-menu-buy", (e) => {
                this.show_drop_menu($(e.currentTarget).data('id'));
            })
            $(document).on('mouseleave', ".js-header-menu-buy", (e) => {
                this.set_time_out_drop_menu();
            })
            $(this.how_buy_menu).on('mouseenter', () => {
                this.show_drop_menu();
            })
            $(this.how_buy_menu).on('mouseleave', () => {
                this.set_time_out_drop_menu();
            })
        }
    }
    show_drop_menu = (id) => {
        this.time_out_menu && clearTimeout(this.time_out_menu);
        this.time_out_menu = null;
        this.how_buy_menu.each((index, elem) => {
          if ($(elem).data('id') === id)
            $(elem).addClass('show-header')
        });
        $(".dark_background").addClass('dark_background_show');
    }
    set_time_out_drop_menu = () => {
        const self = this;
        if (!this.time_out_menu) {
            this.time_out_menu = setTimeout(function () {
                self.how_buy_menu.removeClass('show-header');
                $(".dark_background").removeClass('dark_background_show');
                self.time_out_menu = null;
                clearTimeout(this);
            }, 500)
        }
    }
    return_up = () => {
        const up = $(".js-btn-up-item");
        $(window).on("scroll", function () {
            if (+this.scrollY >= +this.screen.height - 300) {
                up.css({ "bottom": "5vh" });
            } else {
                up.css({ "bottom": "-30px" });
            }
        })
    }
    set_cookie = () => {
        if (this.yes_cookie !== 'yes') {
            this.consentCookie.css({ "display": "" })
        }
        this.consentConfirmation.on("click", (e) => {
            e.preventDefault();
            this.consentCookie.css({ "display": "none" });
            set_local('consent-cookie', 'yes');
        });
    }
    get_likes_counts_request = () => favorite.get();
    get_comparisons_counts_request = () => comparison.get();
}

import Inputmask from "inputmask/bundle.js";

export class Validation {
    constructor(props) {
        this.phone = document.querySelectorAll(".js-phone");
        this.email = document.querySelectorAll(".js-email");
        this.name = document.querySelectorAll(".js-name");
    }
    init_mask = () => {
        this.phone && this.validation_phone()
        this.email && this.validation_email()
        this.name && this.validation_name()
    }
    reset_input_init = () => {
        this.phone = document.querySelectorAll(".js-phone");
        this.email = document.querySelectorAll(".js-email");
        this.name = document.querySelectorAll(".js-name");
        this.init_mask();
    }

    validation_phone = () => {
        const self = this
        Inputmask({
            mask: "+7 (999) 999-9999",
            clearIncomplete: true,
            greedy: false,
            onincomplete: function () {
                self.err_state(this, "Введите корректный номер телефона");
            },
            oncomplete: function (e) {
               self.normalize_state(this);
            }
        }).mask(this.phone);
    }
    validation_email = () => {
        const self = this
        Inputmask({
            mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,2}*{0,20}.+{2,3}",
            greedy: false,
            onBeforePaste: function (pastedValue, opts) {
                pastedValue = pastedValue.toLowerCase();
                return pastedValue.replace("mailto:", "");
            },
            clearIncomplete: true,
            showMaskOnHover: false,
            definitions: {
                '*': {
                    validator: "[^@.]"
                },
                '+': {
                    validator: "[^@]"
                }
            },
            onincomplete: function () {
                self.err_state(this, "Введите корректную электронную почту");
            },
            oncomplete: function () {
                self.normalize_state(this);
            }
        }).mask(this.email)
        this.email.forEach(input => {
            input.addEventListener('change', function() {
                self.normalize_state($(this));
            })
        });
    }
    validation_name = () => {
        const self = this
        $(this.name).on('change', function() {
            if ($(this).val().length < 3) {
                self.err_state(this, "Имя должно содержать более 3 символов");
            } else {
                self.normalize_state(this);
            }
        })
    }
    normalize_state = (input) =>{
        const form = input.closest('form')
        const inputWrap = input.closest('.js-input-wrap');
        const inputLabel = $(inputWrap).find('.js-input-error');
        const submitBtn = $(form).find('button');
        $(inputWrap).removeClass("form-error");
        $(inputLabel).text("");
        $(submitBtn).attr('disabled', false)
    }
    err_state = (input, text) => {
        const form = input.closest('form')
        const inputWrap = input.closest('.js-input-wrap');
        const inputLabel = $(inputWrap).find('.js-input-error');
        const submitBtn = $(form).find('button');
        $(inputWrap).addClass('form-error');
        $(inputLabel).text(text);
        $(submitBtn).attr('disabled', true)
    }
}

export const validation = new Validation()
validation.init_mask();

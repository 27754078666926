export const get_template_house_schema = (section, data) => {
    if (Object.keys(data).length) {
        let text = `<div class="swiper-slide schema-slide">
        <div class="schema-item">
            <h2 class="schema-item__title">
                ${section}
            </h2>
            <div class="schema-floor__wrap">`;
        Object.entries(data).reverse().forEach(([key, value]) => {
            if (value.length) {
                text += `<div class="schema-floor__wrap_floor">
                <p class= "schema-floor-col-numder">${key}</p>`;
                value
                    .sort((item1, item2) => parseInt(item1.number) - parseInt(item2.number))
                    .forEach((j) => {
                        if (j.status) {
                            const type = j.type === 2 ? 'О' : j.studio ? 'С' : j.rooms;
                            const status = j.status.toLowerCase();
                            switch (status) {
                                case "available":
                                    text += `<div class="row schema-plan-content-info-blok free">
                                            <a class="js-plan-flat-chema"  data-flat="${j.id}" href="${j.absolute_url}" target="_blank">${type}</a>
                                        </div>`
                                    break;
                                case 'sold': case 'execution':
                                    text += `<div class="row schema-plan-content-info-blok sold js-plan-flat-chema" data-flat="${j.id}"> </div>`
                                    break;
                                case "booked":
                                    text += `<div class="row schema-plan-content-info-blok blocked">
                                                <a class="js-plan-flat-chema" data-flat="${j.id}" href="${j.absolute_url}" target="_blank"></a>
                                                </div>`
                                    break;
                                case "sale":
                                    text += `<div class="row schema-plan-content-info-blok sale ">
                                            <a class="js-plan-flat-chema"  data-flat="${j.id}" href="${j.absolute_url}" target="_blank">${type}</a>
                                        </div>`
                                    break;
                            }
                        }
                    })
                return text += '</div>';
            }
        })
        return text += `</div></div></div>`;
    }
}

export const get_template_townhouse_schema = (slide) => {
    if (slide && slide.length) {
        let html = `<div class="swiper-slide schema-slide">
            <div class="schema-townhouse-item">`;
        slide.forEach(house => {
            if (house) {
                html += `<div class="schema-townhouse__wrap">
                        <h2 class="schema-townhouse-item__title">${house.name}</h2>
                        <div class="schema-townhouse-floor__wrap">`;
                if (house.flat.length) {
                    house.flat.map(flat => {
                        const status = flat.status.toLowerCase();
                        switch (status) {
                            case "available":
                                html += `<div class="row schema-plan-content-info-blok free">
                                        <a class="js-plan-flat-chema" data-flat="${flat.id}" href="${flat.link}" target="_blank"></a>
                                    </div>`;
                                break;
                            case 'sold': case 'execution':
                                html += `<div class="row schema-plan-content-info-blok sold js-plan-flat-chema" data-flat="${flat.id}"> </div>`;
                                break;
                            case "booked":
                                html += `<div class="row schema-plan-content-info-blok blocked">
                                            <a class="js-plan-flat-chema" data-flat="${flat.id}" href="${flat.link}" target="_blank"></a>
                                        </div>`;
                                break;
                            case "sale":
                                html += `<div class="row schema-plan-content-info-blok sale ">
                                        <a class="js-plan-flat-chema"  data-flat="${flat.id}" href="${flat.link}" target="_blank"></a>
                                    </div>`
                        }
                    })
                }
                html += `</div></div>`;
            }
        })
        return html += `</div></div>`;
    }

}

import InitPositionFlyWrap from "../utils/update_position_wrap";
/**
 * @class - класс содержит логику отображение подсказок
 * @param trigger_class - триггер класс, который будет слушать
 * @param text - текст который нужно вывести
 * @method render_prompt_wrap - создает контейнер для подсказки
 * @method mouse_enter - слушает событие наведения на триггер для появления подсказки
 * @method mouse_leave - слушает событие пропадание курсора с триггера для скрытия подсказки
 * @method init_position - инициализирует и создает экземпляр класса отслеживающий положение подсказки и изменяет координаты в зависимости от края экрана
 */

export default class FlyCardPrompt {
    constructor(props) {
        this.trigger_class = props.trigger_class;
        this.text = props.text;
        this.wrap = null;
        this.wrap_item = null;
        this.img = null;
    }
    init = () => {
        this.render_prompt_wrap();
        this.wrap = $(`.js-prompt-wrap[data-trigger='${this.trigger_class}']`);
        this.mouse_enter();
        this.mouse_leave();
        this.init_position();
    }
    render_prompt_wrap = () => {
        $('.root').append(`<div class="prompt-wrap js-prompt-wrap" data-trigger="${this.trigger_class}"><p class="js-prompt-text">${this.text}</p></div>`);
    }
    mouse_enter = () => {
        const self = this;
        $(document).on('mousemove', `.${this.trigger_class}`, function (e) {
            self.wrap_item = this;
            self.wrap.addClass('show');
        })
    }
    mouse_leave = () => {
        const self = this;
        $(document).on('mouseleave', `.${this.trigger_class}`, function (e) {
            self.wrap_item = this;
            self.wrap.removeClass('show');
        })
    }
    init_position = () => new InitPositionFlyWrap({
        fly_wrap: this.wrap[0],
        trigger_class: this.trigger_class,
        event: 'mousemove',
        padding: '10',
        dinamic_position: true
    })

}

import InitPositionFlyWrap from "../utils/update_position_wrap";
/**
 * @class - класс содержит логику отображения увеличиного фото
 * @param trigger_class - триггер класс, который будет слушать 
 * @method init_zoom_wrap - создает контейнер
 * @method click_trigger_img - слушает событие нажатие на картинку
 * @method close_zoom - слушает событие закрытия увеличеного фото
 * @method init_picture - подставляет фотографию в контейнер
 * @method init_position - инициализирует и создает экземпляр класса отслеживающий положение подсказки и изменяет координаты в зависимости от края экрана
 */
export default class ZoomImage {
    constructor(props) {
        this.trigger_class = props.trigger_class;
        this.wrap = null;
        this.wrap_item = null;
        this.img = null;
    }
    init = () => {
        this.init_zoom_wrap();
        this.click_trigger_img();
        this.close_zoom();
        this.init_position();
    }
    init_zoom_wrap = () => {
        $('.root').append(`<div class="zoom-wrap js-zoom-wrap"><div class='zoom-close js-zoom-close'>&#215;</div><img class="js-zoom-image" scr alt='zoom'></div>`);
        this.wrap = $(".js-zoom-wrap");
        this.img = $(".js-zoom-image");
    }
    click_trigger_img = () => {
        const self = this;
        $(document).on('click', function (e) {
            const parent = $(e.target).closest(`.${self.trigger_class}`);
            const check_wrap_image = $(e.target).closest(`.js-zoom-image`);
            if (parent.length) {
                self.init_picture(parent);
                self.show_wrap();
            } else if (!check_wrap_image.length) {
                self.hide_wrap();
            }
        })
    }
    close_zoom = () => {
        $(".js-zoom-close").on('click', () => {
            this.hide_wrap();
        })
    }
    init_picture = (parent) => {
        const img = parent.find('img');
        const src = $(img).attr('src');
        this.img.attr('src', src);
    }
    show_wrap = () => {
        this.wrap.addClass('show');
    }
    hide_wrap = () => {
        this.wrap.removeClass('show');
    }
    init_position = () => new InitPositionFlyWrap({
        fly_wrap: this.wrap[0],
        trigger_class: this.trigger_class,
        event: 'click',
        padding: '10',
        dinamic_position: true
    })
}


import api from '../api/request';
import { loader } from '../components/loader';
import { showModalError } from '../components/modal';
import FlyCardPrompt from '../components/fly_card_prompt';
import { SortFavorite } from '../components/sort_favorite';
import {
  get_template_flat_favorite,
  get_template_townhouse_favorite,
  get_template_office_favorite
} from '../templates/tempates_favorite';
import ZoomImage from '../components/zoom_image';
import { favorite } from '../components/favorite_actions';
/**
 * @class -  класс содержит логику для секции "Избранное"
 * @method get_favorite_request - запрос на получшение помещений в избранном
 * @method check_child_wrap - проверяет наличие помещений в контейнерах и при отсутствие показывает сообщение
 * @method switch_template_favorit - определяет тип помещения, рендерит в соответствующий контейнер 
 * @method get_count_flat_request - запрашивает кол-во помещений в избранном
 * @method sort_table_* - инициализирует сортировку для квартир, таунхаусов, офисов
 * @method propmt_block - инициализирует подсказку
 * @method active_zoom_image -- инициализирует увеличиное фото
 * @method remove_favorite_item -- удаляет из избранного
 */

export class FavoritList {
  constructor() {
    this.count_favorite_wrap = $('.js-menu-favorites-count');
    this.favorites_wrap = $('.js-favorites-wrap')
    this.modal_error = $('.js-req-modal__error');
    this.sort_btn = $('.js-sort-table');
    this.wraps_flat = $(".js-objects-table");
    this.wraps_townhouse = $(".js-townhouse-table");
    this.wraps_commerce = $(".js-office-table");
    this.wraps_flat_content = $(`.js-favorit-table-content#apartments_info`);
    this.wraps_townhouse_content = $(`.js-favorit-table-content#townhouses_info`);
    this.wraps_commerce_content = $(`.js-favorit-table-content#commercial_info`);
    this.propmt_wrap = $('.js-prompt-wrap');
  }
  init = () => {
    this.get_favorite_request();
    this.get_count_flat_request();
    this.sort_table_house();
    this.sort_table_commerce();
    this.sort_table_townhouse();
    this.propmt_block();
    this.active_zoom_image();
    this.remove_favorite_item();
  }
  check_child_wrap = () => {
    if (!this.wraps_flat_content.children().length &
      !this.wraps_townhouse_content.children().length &
      !this.wraps_commerce_content.children().length) {
      $(".js-wrap-empty").css({ "display": "flex" }
      )
    }
  }
  switch_template_favorit = (data) => {
    if (data.apartments_info.length) {
      this.wraps_flat_content.append(data.apartments_info.map(i => get_template_flat_favorite(i)));
    } else {
      this.wraps_flat.css('display', 'none');
    }
    if (data.townhouses_info.length) {
      this.wraps_townhouse_content.append(data.townhouses_info.map(i => get_template_townhouse_favorite(i)));
    } else {
      this.wraps_townhouse.css('display', 'none');
    }
    if (data.commercial_info.length) {
      this.wraps_commerce_content.append(data.commercial_info.map(i => get_template_office_favorite(i)));
    } else {
      this.wraps_commerce.css('display', 'none');
    }
    if (data.apartments_info.length || data.townhouses_info.length || data.commercial_info.length) {
      $(".flat-card_sale.flat-card_sale--template.hide").css({ "display": "block" })
    }
  }
  get_count_flat_request = () => {
    api.flat_count({ type: 0, page_size: 1 }).then(
      data => $('.js-count-flat').text(data.count)
    )
  }
  remove_favorite_item = () => {
    $(document).on("click", ".js-object-card__remove", (e) => {
      e.preventDefault();
      this.propmt_wrap.removeClass('show');
      e.currentTarget.parentNode.parentNode.remove();
      let id = Number(e.currentTarget.id);
      favorite.delete({ id: id })
      if (!this.wraps_flat_content.children().length) {
        this.wraps_flat.css('display', 'none');
      }
      if (!this.wraps_townhouse_content.children().length) {
        this.wraps_townhouse.css('display', 'none');
      }
      if (!this.wraps_commerce_content.children().length) {
        this.wraps_commerce.css('display', 'none');
      }
      this.check_child_wrap();
    })
  }
  get_favorite_request = (sort = {}) => {
    loader.show_loader();
    api.get_favorite_properties(sort)
      .then(data => {
        loader.hidden_loader();
        this.favorites_wrap.addClass('show');
        this.switch_template_favorit(data);
      })
      .then(() => this.check_child_wrap())
      .catch(
        (err) => {
          switch (err?.response?.status) {
            case 404:
              this.favorites_wrap.removeClass('show');
              showModalError(this.modal_error);
              break;
            case 500:
              this.favorites_wrap.removeClass('show');
              showModalError(this.modal_error);
              break;
          }
        }
      )
  }
  sort_table_house = () => {
    const sort_house_flat = new SortFavorite({
      wrap_content: this.wraps_flat_content,
      api: api.get_favorite_properties,
      template: get_template_flat_favorite,
      btn_sort: $(".js-sort-table-flat"),
      filter: $(".js-filter-house"),
      wrap_name: "apartments_info"
    });
    sort_house_flat.init();
  }
  sort_table_townhouse = () => {
    const sort_townhouse_flat = new SortFavorite({
      wrap_content: this.wraps_townhouse_content,
      api: api.get_favorite_properties,
      template: get_template_townhouse_favorite,
      btn_sort: $(".js-sort-table-townhouse"),
      filter: $(".js-filter-townhouse"),
      wrap_name: "townhouses_info"
    });
    sort_townhouse_flat.init();
  }
  sort_table_commerce = () => {
    const sort_house_commerce = new SortFavorite({
      wrap_content: this.wraps_commerce_content,
      api: api.get_favorite_properties,
      template: get_template_office_favorite,
      btn_sort: $(".js-sort-table-commerce"),
      filter: $(".js-filter-commerce"),
      wrap_name: "commercial_info"
    })
    sort_house_commerce.init();
  }
  propmt_block = () => {
    const fly_propmt = new FlyCardPrompt({
      trigger_class: 'js-object-card__remove',
      text: 'Можете удалить из избранного.'
    });
    fly_propmt.init();
  }
  active_zoom_image = () => {
    const zoom_image = new ZoomImage({ trigger_class: 'js-card__img' });
    zoom_image.init();
  }
}
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

require('dayjs/locale/ru')
const dayjs_localized = dayjs.extend(localizedFormat);
const dayjs_weekand = dayjs.extend(weekday);
const dayjs_quarter_of_year = dayjs.extend(quarterOfYear)

const get_dayjs_localized_ru = (data) =>{
    return dayjs_localized(data).locale('ru').format('DD MMMM YYYY г.');
}

export {
    dayjs_quarter_of_year,
    dayjs_weekand,
    get_dayjs_localized_ru
}
    //Booking modal
    const $triggerModalBooking = $('.js-booking-flat');
    const $modalBooking = $('.js-req-modal__booking');

    //Search modal
    const $triggerSearchFormBtn = $('.js-search-form__btn');
    const $modalSearchForm = $('.js-req-modal__search');
    const $formInput = $(".js-search-form__input input")
    //City modal
    const $triggerCityFormBtn = $('.js-switch-city-active');
    const $modalCityForm = $('.js-req-modal__city');

    //Error modal
    const $modalErrorForm = $('.js-req-modal__error');

    export const killModal = (modal) => {
        $(modal).removeClass('req-modal__show')
        const $modalContent = $(modal).find('.js-req-modal__content')
        const $modalWrapper = $(modal).find('.js-req-modal__wrapper')
        $($modalWrapper).removeClass('req-modal-wrapper-show')
        $($modalContent).removeClass('req-modal__opened')
        $('body').removeClass('hidden-body')
    }

    const closeModal = (modal) => {
        const $closeBtn = $(modal).find('.js-req-modal__close');
        $($closeBtn).on('click', function () {
            killModal(modal);
        })
    }

    export const showModal = (modal) => {
        $(modal).addClass('req-modal__show')
        $(modal).find('form').on('submit', function (e) {
            e.preventDefault()
        })
        const $modalContent = $(modal).find('.js-req-modal__content')
        const $modalWrapper = $(modal).find('.js-req-modal__wrapper')
        $($modalWrapper).addClass('req-modal-wrapper-show')
        $($modalContent).addClass('req-modal__opened')
        $('body').addClass('hidden-body')
        $(modal).find('.req-modal__overlay').on('click', function () {
            killModal(modal)
        });

        $(document).keyup(function (e) {
            if (e.key === "Escape") {
                killModal(modal)
            }
        });
    }

    const showModalSearchForm = (modal) => {
        $(modal).addClass('req-modal__show')
        $(modal).find('form').on('submit', function (e) {
            e.preventDefault()
        })
        const $modalContent = $(modal).find('.js-req-modal__content')
        const $modalWrapper = $(modal).find('.js-req-modal__wrapper')
        $($modalWrapper).addClass('req-modal-wrapper-show')
        $($modalContent).addClass('req-modal__opened')
        $('body').addClass('hidden-body')
        $(modal).find('.req-modal__overlay').on('click', function () {
            $formInput.val();
            killModal(modal)
        });
    }

    export const showModalError = (modal) => {
        $(modal).addClass('req-modal__show')
        const $modalContent = $(modal).find('.js-req-modal__content')
        const $modalWrapper = $(modal).find('.js-req-modal__wrapper')
        $($modalWrapper).addClass('req-modal-wrapper-show')
        $($modalContent).addClass('req-modal__opened')
        $('body').addClass('hidden-body')
        $(modal).find('.req-modal__overlay').on('click', function () {
            killModal(modal)
        });
    }

    const closeModalSearchForm = (modal) => {
        const $closeBtn = $(modal).find('.js-search-form__close')
        $closeBtn.on('click', function () {
            $formInput.val();
            killModal(modal);
        })
    }

    const closeModalError = (modal) => {
        const $closeBtn = $(modal).find('.js-search-form__close')
        $closeBtn.on('click', function () {
            killModal(modal)
        })
    }
    

    const openModal = (trigger, modal, callback) => {
        $(trigger).on('click', function () {
            callback(modal)
        })
    }

    const hideBody = () => {
        $('.req-modal__btn').on('click', function () {
            const $modal = $(this).closest('.js-js-req-modal')
            const $modalBody = $($modal).find('.js-req-modal__body')
            const $modalSuccess = $($modal).find('.js-req-modal__success')
            $($modalBody).addClass('req-modal__body-hidden')
            $($modalSuccess).addClass('req-modal__success-show')
        })
    }

    const defModal = () => {
        $('.req-modal__success-btn').on('click', function () {
            const $modal = $(this).closest('.js-req-modal')
            const $modalBody = $($modal).find('.js-req-modal__body')
            const $modalSuccess = $($modal).find('.js-req-modal__success')
            $($modalBody).removeClass('req-modal__body-hidden')
            $($modalSuccess).removeClass('req-modal__success-show')

            const $modalCloseBtn = $($modal).find('.js-req-modal__close')
            $($modalCloseBtn).trigger('click')
        })
    }

    hideBody()
    defModal()

    //Booking modal form
    openModal($triggerModalBooking, $modalBooking, showModal);
    closeModal($modalBooking);

    //Search modal form
    openModal($triggerSearchFormBtn, $modalSearchForm, showModalSearchForm);
    closeModalSearchForm($modalSearchForm);

    //City modal form
    openModal($triggerCityFormBtn, $modalCityForm, showModal);
    closeModal($modalCityForm);

     //Error modal
    closeModalError($modalErrorForm)
    closeModal($modalErrorForm);
import { CalcInstallmentRoom, CalcMortgageRoom } from '../components/calculations';
/**
 * @class  класс содержит логику для секции "калькулятор ипотеки и рассрочки" на детальной странице помещения 
 */
export class RoomCalculation {
  constructor() { }
  init = () => {
    if ($('.js-calc-installment').length) {
      const calculation_installment = new CalcInstallmentRoom();
      calculation_installment.init();
    }
    if ($('.js-calc-mortgage').length) {
      const calculation_Mortgage = new CalcMortgageRoom();
      calculation_Mortgage.init();
    }
  }
}
import { API_DOWNLOAD_ROOM } from '../api/api_list';
import api from '../api/request';
import { comparison } from '../components/comparison_actions';
import { favorite } from '../components/favorite_actions';
import FlyCardPrompt from '../components/fly_card_prompt';
import { showModalError } from '../components/modal';
import { format_price_intl } from '../utils/format_price';
import { RoomCalculation } from './section_room_calculation';
/**
 * @class  класс содержит логику для секции "информационный блок о помещение"
 * @method set_booking - слушает событие sybmit формы бронирования помещения
 * @method request_booking - отравляет запрос на бронирование помещения
 * @method set_print - формирует ссылку печать информации о помещение
 * @method action_favorit - вызывает метод crud  для добавления и удаления из избранного
 * @method check_active_like - проверят находится ли помещение в избранном
 * @method reload_calculation - обновляет калькуляторы, т.к. после вызова печати они не становятся не активными
 * @method get_sum_this_discount - отправляет данные для пересчета суммы в зависимости от первоначального взноса
 * @method switch_photo - случшает события нажатия на табы и переключает контейнеры
 * @method is_active_flat_slider - скрывает контейненры и делает активный выбранный
 * @method drop_contents - расскрывает полную инфу о помещение
 * @method download_room - формирует ссылку в новом окне для скачивания инфы о помещение
 * @method init_twentytwenty - инициализирует "сравнение 2d и 3d" фотографий
 */
export class RoomDetail {
  constructor() {
    this.count_favorite = $(".js-menu-favorites-count");
    this.property_id = $(".js-active-flat").val();
    this.favorite = null;
    this.twentytwenty = {};
    this.collection_options = {};
    this.calc_installment = {};
    this.calc_mortgage = {};
    this.discounts = {};
  }
  init = () => {
    this.set_print();
    this.set_booking();
    this.switch_photo();
    this.action_favorit();
    this.check_active_like();
    this.action_comparison();
    this.check_active_comparison();
    this.get_sum_this_discount();
    this.init_twentytwenty();
    this.drop_contents();
    this.download_room();
    this.init_fly_card_room()
  }
  set_booking = () => {
    $(".js-req-modal__success").css({ "display": 'none' });
    $(document).on('submit', '.js-booking-flat-form', (e) => {
      e.preventDefault();
      this.collection_options.flat_id = this.property_id;
      this.collection_options.phone = $('input[name=phone]').val();
      this.collection_options.email = $('input[name=email]').val();
      this.collection_options.name = $('input[name=first_name]').val();
      $(".js-req-modal__body").css({ "display": '' });
      $(".js-req-modal__success").css({ "display": 'none' });
      this.request_booking();
    })
  }

  init_fly_card_room() {
    new FlyCardPrompt({
      trigger_class: "js-card-favorite",
      text: "Добавить в избранное",
    }).init();
    new FlyCardPrompt({
      trigger_class: "js-card-comparison",
      text: "Добавить в сравнение",
    }).init();
    new FlyCardPrompt({
      trigger_class: "js-card-share",
      text: "Поделиться",
    }).init();
    new FlyCardPrompt({
      trigger_class: "js-card-print",
      text: "Печать",
    }).init();
    new FlyCardPrompt({
      trigger_class: "js-card-download",
      text: "Скачать планировку",
    }).init();
  }

  request_booking = () => {
    api.set_booking_flat(this.collection_options)
      .then(data => {
        $(".js-req-modal__body").css({ "display": 'none' });
        $(".js-req-modal__success").css({ "display": '' });
      })
      .catch(err => {
        switch (err?.response?.status) {
          case 404:
          case 500:
            showModalError($('.js-req-modal__error'));
            this.hidden_btn();
            break;
          case 400:
            if (error.name && error.name[0]) {
              $(".js-input-wrap[data-input=name_user]").addClass('form-error');
              $('.js-input-error[data-error=name_user]').text(error.name[0]);
            }
            if (error.phone_number && error.phone_number[0]) {
              $(".js-input-wrap[data-input=phone_number]").addClass('form-error');
              $('.js-input-error[data-error=phone_number]').text(error.phone_number[0]);
            }
            if (error.email && error.email[0]) {
              $(".js-input-wrap[data-input=email]").addClass('form-error');
              $('.js-input-error[data-error=email]').text(error.email[0]);
            }
            this.hidden_btn();
        }
      });
  }
  set_print = () => {
    $(document).on('click', ".js-print-window", (e) => {
      const printContents = document.querySelector('#section_room').innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      this.reload_calculation()
    })
  }
  reload_calculation = () => {
    $(".noUi-base").remove();
    $(".active_filter").removeClass('active_filter');
    new RoomCalculation().init();
  }
  get_sum_this_discount = () => {
    let value = $(".js-discount:checked").val();
    this.request_sum_this_discount(value);

    if (value) {
      $(document).on('click', ".js-discount", (e) => {
        value = $(e.target).val();
        this.update_discount_value(value);
      })
    }
  }
  request_sum_this_discount = (initVal) => {
    const default_price = $(".js-price").text();
    api.get_price_this_discount({ property: this.property_id, first_pay: "15,25,50,100" })
      .then(data => {
        if (data.new_price) {
          $(".js-price").text(format_price_intl.format(data.new_price));
          $(".js-price-old").text(default_price);
        }

        this.discounts = {
          15: data["15"],
          25: data["25"],
          50: data["50"],
          100: data["100"],
        }

        this.update_discount_value(initVal);
      })
  }
  update_discount_value = (value) => {
    const discountValue = this.discounts[value];
    if (isNaN(parseInt(discountValue))) {
      $(".js-discount-description").hide();
      $(".js-discount-not-found").text(discountValue)
    } else {
      $(".js-discount-description").show();
      $(".js-fisrtpay-discount").text(discountValue);
      $(".js-discount-not-found").text('');
    }
  }
  switch_photo = () => {
    const self = this;
    if ($(".flat-details-scheme").length) {
      const first_tab = $(".js-flat-details-radio")[0];
      self.is_active_flat_slider(first_tab);
    }
    $(document).on("click", ".js-flat-details-radio", function () {
      self.is_active_flat_slider(this);
    })
  }
  is_active_flat_slider = (active_tab) => {
    $(`.js-flat-details-slider-right`).css({ "display": "none" });
    if (active_tab) {
      $(active_tab).attr("checked", "checked");
      $(`.js-flat-details-slider-right#${active_tab.value}`).css({ "display": "" });
    }
  }

  drop_contents = () => {
    $(document).on("click", ".js-drop-trigger", (e) => {
      const arrow = $(e.currentTarget).find(".js-drop-more__trigger-icon");
      let sub_content = $(e.currentTarget).children(".js-drop-more-content-sub");
      arrow.toggleClass("rotate");
      $(".js-content-prew").toggleClass("show");
      sub_content.slideToggle(400);
    })
  }

  action_favorit = () => favorite.action_favorit();
  check_active_like = () => favorite.check_active_like();
  action_comparison = () => comparison.action_comparison();
  check_active_comparison = () => comparison.check_active_comparison();
  init_twentytwenty = () => $("#scroll-range-image").twentytwenty()
  download_room = () => {
    const self = this;
    $('.js-download-room').on('click', function (e) {
      e.preventDefault();
      const id = $(this).attr('data-id');
      window.open(`${API_DOWNLOAD_ROOM}/${id}/`, '_blank').focus();
    })
  }
}

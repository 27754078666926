import { showModalError } from "./modal"
import { loader } from "./loader";
import { AbstractSort } from "./sort";
import form_option from "../utils/trigget-filters";
/**
 * @class - класс содержит логику сортировки в избранном
 */
export class SortFavorite extends AbstractSort{
    constructor(props) {
        super(props);
        this.wrap_content = props.wrap_content;
        this.template = props.template;
        this.api = props.api;
        this.filter = props.filter;
        this.btn_sort = props.btn_sort;
        this.wrap_name = props.wrap_name;
        this.sort_filter = props.sort_filter;
        this.sort_value = false;
    }
    init = () => {
        this.init_sort();
    }
    get_options_request_sort = (sort_name) => {
        const order = this.sort_value ? sort_name : `-${sort_name}`;
        const options = form_option.get_options_form(this.form);
        return { ...options, ordering: order };
    }
    request = (params) => {
        loader.show_loader(this.wrap_name);
        this.api(params)
            .then(data => {
                loader.hidden_loader(this.wrap_name);
                this.render(data);
            })
            .catch(err => {
                switch (err?.response?.status) {
                    case 404:
                    case 500:
                        showModalError($('.js-req-modal__error'));
                        this.hidden_btn();
                        break;
                }
            })
    }
    render = (data) => {
        this.wrap_content.html(data[this.wrap_name].map(i => this.template(i)));
    }
}
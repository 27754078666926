import { format_price_intl, getPrice } from "../utils/format_price"
import { WAY_UP_DEF_IMG } from "../const/way_up"
import { is_with_facing } from "../utils/facing";
import { get_promo_url } from '../utils/get_promo_url.js';

export const get_template_flat_favorite = (data) => {
  return `
      <div class="objects-card ${data.status === "BOOKED" ? `card-blocked` : ''} ">
        <div class="objects-card__blocked">
          <div class="objects-card__blocked__icon js-card-blocked">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="4" y="11" width="16" height="10" rx="2" stroke="white" stroke-width="1.3" stroke-linejoin="round"></rect>
              <path d="M7 11V7.44444C7 4.98985 9.23858 3 12 3C14.7614 3 17 4.98985 17 7.44444V11" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M12 15V17" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </div>
      <div class="objects-table-top objects-table-item">
        <div class="object-card__img js-card__img"><i class="icon icon_lg"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" stroke="#59B039" stroke-width="1.3" stroke-linejoin="round"></path>
          <path d="M16.0748 16.0746L20.8242 20.8241" stroke="#59B039" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M10 6V14" stroke="#59B039" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M6 10H14" stroke="#59B039" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg></i>
          ${data.get_picture_2d ?
      `<img src="${data.get_picture_2d}" alt="layout">`
      : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
          </div>
        <div class="object-card__name">
        ${data.rooms_count ? `<div class="object-card__title">${data.rooms_count}</div>` : '<div></div>'}
          ${is_with_facing(data.facing) ? `<div class="flat-card__decor">С отделкой</div>` : ''}
        </div>
        ${data.area ? `<div class="object-card__area">${data.area}</div>` : '<div></div>'}
        ${data.floor_of ? `<div class="object-card__floor">${data.floor_of}</div>` : '<div></div>'}
        ${data.number ? `<div class="object-card__number">${data.number}</div>` : '<div></div>'}
        ${data.project_title ? `<div class="object-card__project">${data.project_title}</div>` : '<div></div>'}
        ${data.house ? `<div class="object-card__address">${data.house}</div>` : '<div></div>'}
        ${data.development_quarter ? `<div class="object-card__deadline">${data.development_quarter}</div>` : '<div></div>'}
        ${data.new_price ?
      `<div class="flat-card__price">
            <span class="flat-card_sale">Цена со скидкой *</span>
            <div class="flat-card__price_new">${format_price_intl.format(data.new_price)}</div>
            <div class="flat-card__price_old">${format_price_intl.format(data.price)}</div>
          </div>`
      :
      `<div class="flat-card__price">
              <div class="flat-card__price_new">${getPrice(data.price)}</div>
            </div`}
            <div class="favorite_button_wrap">
            <button class="btn object-card__remove js-object-card__remove" id="${data.id}">
                <i class="icon icon_remove">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2L14 14" stroke="#8B98A7" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M2 14L14 2" stroke="#8B98A7" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </i>
            </button>
        </div>
      <div class="objects-table-link">
        <a class="" href="${data.absolute_url}"></a>
      </div>
    </div>`
}
export const get_template_townhouse_favorite = (data) => {
  return `
      <div class="objects-card ${data.status === "BOOKED" ? `card-blocked` : ''} ">
        <div class=" objects-card__blocked">
          <div class="objects-card__blocked__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="4" y="11" width="16" height="10" rx="2" stroke="white" stroke-width="1.3" stroke-linejoin="round"></rect>
              <path d="M7 11V7.44444C7 4.98985 9.23858 3 12 3C14.7614 3 17 4.98985 17 7.44444V11" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M12 15V17" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </div>
        <div class="object-card townhouse-table-top">
          <div class="object-card__img js-card__img"><i class="icon icon_lg"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" stroke="#59B039" stroke-width="1.3" stroke-linejoin="round"></path>
            <path d="M16.0748 16.0746L20.8242 20.8241" stroke="#59B039" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M10 6V14" stroke="#59B039" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M6 10H14" stroke="#59B039" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg></i>
            ${data.get_picture_2d ?
      `<img src="${data.get_picture_2d}" alt="layout">`
      : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
            </div>
          <div class="object-card__name">
            ${data.number ? ` <div class="object-card__number">№ ${data.number}</div>` : ''}
            <div class="object-card__title">Таунхаус</div>
             ${is_with_facing(data.facing) ? `<div class="flat-card__decor">С отделкой</div>` : ''}
          </div>
          ${data.area ? `<div class="object-card__area">${data.area}</div>` : '<div></div>'}
          ${data.area_district ? `<div class="object-card__plot">${data.area_district}</div>` : '<div></div>'}
          ${data.development_quarter ? `<div class="object-card__deadline">${data.development_quarter}</div>` : '<div></div>'}
          ${data.project_title ? `<div class="object-card__project">${data.project_title}</div>` : '<div></div>'}
          ${data.queue ? `<div class="object-card__queue">${data.queue}</div>` : '<div></div>'}
          ${data.house ? `<div class="object-card__address">${data.house}</div>` : '<div></div>'}
          ${data.new_price ?
      `<div class="flat-card__price">
          <span class="flat-card_sale">Цена со скидкой *</span>
          <div class="flat-card__price_new">${format_price_intl.format(data.new_price)}</div>
          <div class="flat-card__price_old">${format_price_intl.format(data.price)}</div>
        </div>`
      :
      `<div class="flat-card__price">
            <div class="flat-card__price_new">${getPrice(data.price)}</div>
          </div`}
        <div class="favorite_button_wrap">
          <button class="btn object-card__remove js-object-card__remove" id="${data.id}">
              <i class="icon icon_remove">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 2L14 14" stroke="#8B98A7" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M2 14L14 2" stroke="#8B98A7" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
              </i>
          </button>
        </div>
        <div class="objects-table-link">
          <a class="" href="${data.absolute_url}"></a>
        </div>
      </div>`
}
export const get_template_office_favorite = (data) => {
  return `
      <div class="objects-card ${data.status === "BOOKED" ? `card-blocked` : ''} ">
        <div class="objects-card__blocked">
          <div class="objects-card__blocked__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="4" y="11" width="16" height="10" rx="2" stroke="white" stroke-width="1.3" stroke-linejoin="round"></rect>
              <path d="M7 11V7.44444C7 4.98985 9.23858 3 12 3C14.7614 3 17 4.98985 17 7.44444V11" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M12 15V17" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </div>
          <div class="object-card commercial-table-top">
            <div class="object-card__img js-card__img"><i class="icon icon_lg"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" stroke="#59B039" stroke-width="1.3" stroke-linejoin="round"></path>
              <path d="M16.0748 16.0746L20.8242 20.8241" stroke="#59B039" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M10 6V14" stroke="#59B039" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M6 10H14" stroke="#59B039" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg></i>
              ${data.get_picture_2d ?
      `<img src="${data.get_picture_2d}" alt="layout">`
      : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
            </div>
            <div class="object-card__name">
              ${data.number ? `<div class="object-card__number">№${data.number}</div>` : ''}
              <div class="object-card__title">Офис</div>
            </div>
            ${data.area ? `<div class="object-card__area">${data.area}</div>` : '<div></div>'}
            ${data.project_title ? `<div class="object-card__project">${data.project_title}</div>` : '<div></div>'}
            ${data.house ? `<div class="object-card__address">${data.house}</div>` : '<div></div>'}
            ${data.development_quarter ? `<div class="object-card__deadline">${data.development_quarter}</div>` : '<div></div>'}
            ${data.new_price ?
      `<div class="flat-card__price">
              <span class="flat-card_sale">Цена со скидкой *</span>
              <div class="flat-card__price_new">${format_price_intl.format(data.new_price)}</div>
              <div class="flat-card__price_old">${format_price_intl.format(data.price)}</div>
            </div>`:
      `<div class="flat-card__price">
              <div class="flat-card__price_new">${getPrice(data.price)}</div>
            </div`}
            <div class="favorite_button_wrap">
                <button class="btn object-card__remove js-object-card__remove" id="${data.id}">
                    <i class="icon icon_remove">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 2L14 14" stroke="#8B98A7" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M2 14L14 2" stroke="#8B98A7" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </i>
                </button>
            </div>
          <div class="objects-table-link">
            <a class="" href="${data.absolute_url}"></a>
          </div>
      </div>`
}

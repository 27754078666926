import {
    get_construction_img,
    get_construction_content,
    get_construction_month
} from '../templates/templates_construction';
import { custom_select } from '../components/custom-select'
import api from '../api/request';
import form_option from '../utils/trigget-filters';
import { asyncImg } from '../utils/loadingAsyncImg.js';
import { slider_construction } from './section_sliders';

/**
 * @class - класс содержит логику для секции блока "Ход строительства"
 * @method target_select_year - событие перключения года
 * @method target_select_month - событие перключения месяцев
 * @method start_section - вызывает первый запрос на получения данных хода строительства
 * @method request - запрос на получение данных хода строителства и фильтра месяцы
 * @method render - обновляет верстку в контейнере и обновляет слайдер
 */

export class SectionConstruction {
    constructor() {
        this.houses_id = $('.js-get-house').val();
        this.wrap_month = $('.js-list-mouth');
        this.wrap_img = $(".js-construction-img");
        this.wrap_content = $(".js-constructionslider-content");
        this.control_wrap = $(".js-construction-slider-control");
        this.first_render = true;
    }
    init = () =>{
        custom_select.install_first_value('year');
        this.target_select_year();
        this.target_select_month();
        this.start_section();
    }
    start_section = () => {
        const option = form_option.get_options_form(this.wrap_month);
        this.request({ ...option, page_size: 12 }, true);
    }
    target_select_year = () => {
        const self = this;
        $(document).on('click', '.js-select-year li', function () {
            const option = form_option.get_options_form(this, ["house", "queue", "year"]);
            self.request({ ...option, page_size: 12 }, true);
        })

    }
    target_select_month = () => {
        const self = this;
        $(document).on('click', '.js-select-month li', function () {
            const option = form_option.get_options_form(this);
            self.request({ ...option, page_size: 12 }, false);
        })
    }
    request = (params, rerender_mounth) => {
        api.constructions_process(params)
            .then(data => {
                if (this.first_render) {
                    this.first_render = false;
                    if (Object.keys(data.item).length === 0) {
                        $("#section_construction_slider").addClass('hidden-wrap');
                        return false;
                    }
                }
                if (data) {
                    this.render(data, rerender_mounth)
                    if (Object.keys(data.item).length) {
                        if (data.item.pictures.length < 2) {
                            this.control_wrap.addClass("hidden-wrap");
                        } else {
                            this.control_wrap.removeClass("hidden-wrap");
                        }
                    }
                }
            })
    }
    render = (data, rerender_mounth) => {
        this.wrap_img.children().remove();
        this.wrap_content.children().remove();
        if (rerender_mounth) {
            this.wrap_month.html(data.months.map(i => get_construction_month(i)));
            custom_select.install_first_value('month');
        }
        this.wrap_content.append(get_construction_content(data.item));
        if (Object.keys(data.item).length) {
            this.wrap_img.append(data.item.pictures.map(i => get_construction_img(i)));
            asyncImg.initOnWrap(this.wrap_img);
            slider_construction.update();
            slider_construction.slideTo(0);
        }
    }
}

import { get_card_news_and_promotions, get_news_year, get_news_month, get_news_category, get_news_category_radio } from '../templates/templates_news_and_promotions';
import { DefaultPagination } from "../components/pagination";
import { get_city } from "../components/get-city";
import { custom_select } from "../components/custom-select";
import api from "../api/request";
import form_option from "../utils/trigget-filters";
import { get_history, set_history } from "../utils/history_store";
import { get_tempalte_select_option } from '../templates/templates_filters';
/**
 * @class  класс содержит логику для секции "список новостей"
 * @method init_default_category - устанавлевает начальное состояние для спискакатегорий на "Все категории"
 * @method target_* - слушатель фильтра, содержит логику с обновлением фильтров
 * @method pagination_news - создает экземпляр класса пагинации
 * @method set_value_category - метод связывает работу радиокновок и кастомного селекта категорий
 * @method request_filters - запрос на данных для фильтров
 */
export class NewsList {
    constructor() {
        this.city_wrap = $(".js-set-city");
        this.form_pagination = $(".js-news-filters");
        this.block_wrap_pagination = $(".js-news-wrap");
        this.year_wrap = $(".js-year-list");
        this.month_wrap = $(".js-month-list");
        this.category_wrap = $(".js-category-list");
        this.category_radio = $(".js-category-radio-wrap");
        this.select_category = $(".js-select-category");
        this.select_month = $(".js-select-month");
        this.select_year = $(".js-select-year");
        this.radio_button_category = $(".js-news-category");
        this.pagination = {};
    }
    init = () => {

        this.first_render();
        this.target_city();
        this.target_year();
        this.target_month();
        this.target_category();
        this.pagination_news();
    }
    first_render = () => {
        get_city.render_city_select(this.city_wrap, true)
            .then(() => {
                const params = get_history();
                this.request_filters(params, true);
            })
    }
    init_default_category = () => {
        $(`.js-news-category`)[0].prop("checked", true);
        custom_select.install_value('category');
    }
    target_city = () => {
        const self = this;
        $(document).on('click', '.js-set-city li', function () {
            const params = form_option.get_options_form(self.form_pagination);
            set_history(params);
            self.city = $(this).attr("data-value");
            if (self.city) {
                self.request_filters(params, true);
            } else {
                self.request_filters({}, true);
            }
        })
    }
    target_year = () => {
        const self = this;
        $(document).on('click', '.js-year-list li', function () {
            const params = form_option.get_options_form(self.form_pagination);
            set_history(params);
            const year = $(this).attr("data-value");
            if (year) {
                self.request_filters(params, false);
            } else {
                self.request_filters(params, false);
            }

        })
    }
    target_month = () => {
        $(document).on('click', '.js-month-list li', () => {
            const params = form_option.get_options_form(this.form_pagination)
            set_history(params)
            this.pagination.reset_pagination()
            this.request_filters(params, false)
        })
    }
    target_category = () => {
        $(document).on('click', '.js-news-category', (e) => {
            const value = $(e.target).val();
            this.set_value_category(value);
            this.pagination.reset_pagination();
        })
        $(document).on('click', '.js-select-category li', (e) => {
            const value = $(e.target).attr("data-value");
            this.set_value_category(value);
            this.pagination.reset_pagination();
        })
    }

    set_value_category = (value) => {
        let item = null;
        const params = form_option.get_options_form(this.form_pagination)
        this.radio_button_category = $(`.js-news-category`);
        this.radio_button_category.prop('checked', false);
        if (value) {
            item = $(`.js-news-category[value=${value}]`);
            if (item.length) {
                custom_select.install_value('category', value);
            } else {
                item = $(".js-news-category")[0];
                custom_select.install_value('category', '');
                set_history({ ...params, category: '' })
            }
        } else {
            item = $(".js-news-category")[0];
            custom_select.install_value('category', '');
        }
        $(item).prop("checked", true);
    }

    request_filters = (params, render_year) => {
        const new_params = { ...params }
        if (new_params.category) {
            delete new_params.category
        }
        api.get_news_filters(new_params)
            .then(data => {
                if (data) {
                    if (data.categories) {
                        this.select_category.css({ 'display': '' })
                        this.category_radio.removeClass('hidden-wrap');
                    } else {
                        this.select_category.addClass('hidden-wrap');
                        this.category_radio.addClass('hidden-wrap');
                    }
                    data.months ? this.select_month.removeClass('hidden-wrap') : this.select_month.addClass('hidden-wrap');
                    data.years ? this.select_year.removeClass('hidden-wrap') : this.select_year.addClass('hidden-wrap');
                    this.render_filters(data, render_year, params)
                } else {
                    this.hidden_filters()
                }
            })
            .then(() => {
                custom_select.install_value('month', params['month']);
                custom_select.install_value('year', params['year']);
                custom_select.install_value('city', params['city'])
                this.set_value_category(params['category'])
            })
            .then(() => this.pagination.reset_pagination())
            .catch(err => {
                switch (err?.response?.status) {
                    case 400:
                    case 404:
                    case 500:
                        this.block_wrap_pagination.children().remove();
                        $(".favorites-empty").addClass('show');
                        this.pagination.hidden_btn();
                        this.hidden_filters();
                        break;
                }
            })
    }
    hidden_filters = () => {
        this.category_radio.addClass('hidden-wrap');
        this.select_category.css({ 'display': 'none' });
        this.select_month.addClass('hidden-wrap');
        this.select_year.addClass('hidden-wrap');
    }
    render_filters = (data, render_year) => {
        this.category_wrap.html(get_news_category([{ category__pk: '', category__title: 'Все' }, ...data.categories]));
        this.category_radio.html(get_news_category_radio([{ category__pk: '', category__title: 'Все' }, ...data.categories]));
        if (render_year) {
            this.year_wrap.html(get_tempalte_select_option({ id: "", title: 'Все года' }));
            this.year_wrap.append(get_news_year(data.years));
        }
        this.month_wrap.html(get_news_month(['', ...data.months]));
    }
    pagination_news = () => {
        this.pagination = new DefaultPagination({
            form: this.form_pagination,
            wrap_content: this.block_wrap_pagination,
            template: get_card_news_and_promotions,
            api: api.news
        });
        this.pagination.init();
    }
}

import { WAY_UP_DEF_IMG } from '../const/way_up'
import {conjugation} from "../utils/conjugation";
export const get_card_project_index = (item) => {
    return `
    <div class="project-card card">
        <a href="${item.absolute_url}">
            <div class="project-card__img_wrap">
                <div class="project-card__img">
                ${item.project_preview ?
                `<img class="js-async-image" src="${item.mini_project_preview}" data-src="${item.project_preview}" alt="project">`
                : `<img class="flat-map__img" src="${WAY_UP_DEF_IMG}" alt='default'>`}
                </div>
                <div class="project-card__img-hover-wrap project-card__img-hover">
                    ${item.project_preview_on_map ?
                `<img class="js-async-image" src="${item.mini_project_preview_on_map}" data-src="${item.project_preview_on_map}" alt="project on project">`
                : `<img class="flat-map__img" src="${WAY_UP_DEF_IMG}" alt='default'>`}
                    <span class="link link_primary main-filters__btn project-link"><span class="main-count-rooms">${conjugation(item.properties_count, ['квартира', 'квартиры', 'квартир'])}</span>
                </div>
            </div>
            <div class="project-card_info">
                <h3 class="project-card__title">${item.title}</h3>
                <div class="project-card-details">
                    <div class="project-card-details_left">
                        <div class="project-card-location">
                            ${item.address_from ? `<div class="project-card__address">${item.address_from}</div>` : ``}
                            <div class="icon icon_left">
                                ${(item.move) === 'afoot' ?
                                `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.7393 3.16601C9.86499 3.16601 9.15626 2.45727 9.15626 1.58301C9.15626 0.708736 9.86499 0 10.7393 0C11.6135 0 12.3223 0.708736 12.3223 1.58301C12.3223 2.45727 11.6135 3.16601 10.7393 3.16601Z" fill="#2A2A30"/>
                                    <path d="M1.77489 9.95435C3.43457 10.925 4.43001 9.50089 5.17988 8.12999C5.21255 7.99799 5.26077 7.86414 5.33007 7.72897C5.91052 6.59995 6.51627 5.48225 7.15021 4.38195C5.9121 3.98699 4.77991 4.34769 3.8896 5.5945C3.16555 6.6089 1.47189 5.63613 2.20437 4.60986C3.92755 2.19662 6.49703 1.59772 9.06942 3.12487C9.17639 3.18837 9.26229 3.26214 9.33105 3.34303C9.37005 3.35832 9.40878 3.37518 9.44699 3.3931C9.55159 3.41417 9.65935 3.45449 9.76817 3.51904C11.3673 4.46837 12.8533 4.28841 13.9631 2.73386C14.6877 1.71945 16.3808 2.69223 15.6481 3.7185C14.2321 5.70173 12.2441 6.45661 10.1526 5.81582C9.49626 6.943 8.87312 8.0902 8.27449 9.24953C9.15926 9.70193 9.97843 10.2647 10.7399 10.9274C10.7728 10.9561 10.8021 10.9859 10.8297 11.0157C10.9955 11.1008 11.1417 11.2444 11.2447 11.4573C11.7564 12.5151 12.2686 13.5725 12.7803 14.6304C13.3299 15.7665 11.5614 16.5902 11.0139 15.4593C10.5275 14.4544 10.0406 13.4481 9.55396 12.4437C9.48809 12.4066 9.42275 12.3623 9.36004 12.3073C8.47684 11.5384 7.46533 10.9153 6.35739 10.5293C6.30285 10.5103 6.25305 10.4876 6.20615 10.4623C4.84974 12.1837 2.9574 13.1317 0.691449 11.8074C-0.502392 11.1097 0.57736 9.25454 1.77489 9.95435Z" fill="#2A2A30"/>
                                </svg>` :
                                (item.move) === 'by_car' ?
                                `<svg width="16" height="16" viewBox="0 0 16 16" fill="#2A2A30" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0)">
                                        <path d="M14.794 6.1662L13.6719 2.42578C13.416 1.57275 12.646 1 11.7559 1H4.24414C3.354 1 2.58398 1.57275 2.32812 2.42578L1.20599 6.1662C0.497433 6.47412 0 7.17926 0 8V12C0 12.5522 0.447754 13 1 13H1.00006L1 13.9999C1 14.5522 1.44769 15 2 15H3.00006C3.55231 15 4.00006 14.5522 4.00006 14L3.99963 13H12L11.9999 13.9999C11.9999 14.5522 12.4476 15 12.9999 15H14C14.5523 15 15 14.5522 15 14V13.0001L14.9996 13H15C15.5522 13 16 12.5522 16 12V8C16 7.17926 15.5026 6.47412 14.794 6.1662L14.794 6.1662ZM4.24411 3H11.7558L12.6559 6H3.34408L4.24411 3H4.24411ZM3.99997 10.5C3.44766 10.5 2.99997 10.0522 2.99997 9.5C2.99997 8.94769 3.44766 8.5 3.99997 8.5C4.55228 8.5 4.99997 8.94769 4.99997 9.5C4.99997 10.0522 4.55228 10.5 3.99997 10.5ZM12 10.5C11.4477 10.5 11 10.0522 11 9.5C11 8.94769 11.4477 8.5 12 8.5C12.5523 8.5 13 8.94769 13 9.5C13 10.0522 12.5523 10.5 12 10.5Z" fill="#2A2A30"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="16" height="16" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>`:
                                `<p></p>`}
                            </div>
                            ${item.road ? `<span>${item.road}</span>
                        </div>` : ``}
                    </div>
                    <div class="project-card-details_right">
                        ${item.minimum_price_millions ? `<div class="project-card__price">от ${item.minimum_price_millions} млн. ₽</div>` : `<div class="project-card__price">Продано</div>`}
                    </div>
                </div>
            </div>
        </a>
    </div>`
}

export const get_card_project = (item) => {
    return `
    <a class="project-card card js-project-link" href="${item.absolute_url}">
        <div class="project-card__img_wrap">
            <div class="project-card__img">
               ${item.project_preview ?
                `<img class="js-async-image" src="${item.mini_project_preview}" data-src="${item.project_preview}" alt="project">`
                : `<img class="flat-map__img" src="${WAY_UP_DEF_IMG}" alt='default'>`}
            </div>
        </div>
        <div class="project-card_info">
            <h3 class="project-card__title">${item.title}</h3>
            <div class="project-card-details">
                <div class="project-card-details_left">
                    <div class="project-card-location">
                        ${item.address_from ? `<div class="project-card__address">${item.address_from}</div>` : ``}
                        <div class="icon icon_left">
                            ${(item.move) === 'afoot' ?
                            `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.7393 3.16601C9.86499 3.16601 9.15626 2.45727 9.15626 1.58301C9.15626 0.708736 9.86499 0 10.7393 0C11.6135 0 12.3223 0.708736 12.3223 1.58301C12.3223 2.45727 11.6135 3.16601 10.7393 3.16601Z" fill="#2A2A30"/>
                                <path d="M1.77489 9.95435C3.43457 10.925 4.43001 9.50089 5.17988 8.12999C5.21255 7.99799 5.26077 7.86414 5.33007 7.72897C5.91052 6.59995 6.51627 5.48225 7.15021 4.38195C5.9121 3.98699 4.77991 4.34769 3.8896 5.5945C3.16555 6.6089 1.47189 5.63613 2.20437 4.60986C3.92755 2.19662 6.49703 1.59772 9.06942 3.12487C9.17639 3.18837 9.26229 3.26214 9.33105 3.34303C9.37005 3.35832 9.40878 3.37518 9.44699 3.3931C9.55159 3.41417 9.65935 3.45449 9.76817 3.51904C11.3673 4.46837 12.8533 4.28841 13.9631 2.73386C14.6877 1.71945 16.3808 2.69223 15.6481 3.7185C14.2321 5.70173 12.2441 6.45661 10.1526 5.81582C9.49626 6.943 8.87312 8.0902 8.27449 9.24953C9.15926 9.70193 9.97843 10.2647 10.7399 10.9274C10.7728 10.9561 10.8021 10.9859 10.8297 11.0157C10.9955 11.1008 11.1417 11.2444 11.2447 11.4573C11.7564 12.5151 12.2686 13.5725 12.7803 14.6304C13.3299 15.7665 11.5614 16.5902 11.0139 15.4593C10.5275 14.4544 10.0406 13.4481 9.55396 12.4437C9.48809 12.4066 9.42275 12.3623 9.36004 12.3073C8.47684 11.5384 7.46533 10.9153 6.35739 10.5293C6.30285 10.5103 6.25305 10.4876 6.20615 10.4623C4.84974 12.1837 2.9574 13.1317 0.691449 11.8074C-0.502392 11.1097 0.57736 9.25454 1.77489 9.95435Z" fill="#2A2A30"/>
                            </svg>` :
                            (item.move) === 'by_car' ?
                            `<svg width="16" height="16" viewBox="0 0 16 16" fill="#2A2A30" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path d="M14.794 6.1662L13.6719 2.42578C13.416 1.57275 12.646 1 11.7559 1H4.24414C3.354 1 2.58398 1.57275 2.32812 2.42578L1.20599 6.1662C0.497433 6.47412 0 7.17926 0 8V12C0 12.5522 0.447754 13 1 13H1.00006L1 13.9999C1 14.5522 1.44769 15 2 15H3.00006C3.55231 15 4.00006 14.5522 4.00006 14L3.99963 13H12L11.9999 13.9999C11.9999 14.5522 12.4476 15 12.9999 15H14C14.5523 15 15 14.5522 15 14V13.0001L14.9996 13H15C15.5522 13 16 12.5522 16 12V8C16 7.17926 15.5026 6.47412 14.794 6.1662L14.794 6.1662ZM4.24411 3H11.7558L12.6559 6H3.34408L4.24411 3H4.24411ZM3.99997 10.5C3.44766 10.5 2.99997 10.0522 2.99997 9.5C2.99997 8.94769 3.44766 8.5 3.99997 8.5C4.55228 8.5 4.99997 8.94769 4.99997 9.5C4.99997 10.0522 4.55228 10.5 3.99997 10.5ZM12 10.5C11.4477 10.5 11 10.0522 11 9.5C11 8.94769 11.4477 8.5 12 8.5C12.5523 8.5 13 8.94769 13 9.5C13 10.0522 12.5523 10.5 12 10.5Z" fill="#2A2A30"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>`:
                            `<p></p>`}
                        </div>
                        ${item.road ? `<span>${item.road}</span>
                    </div>` : ``}
                </div>
                <div class="project-card-details_right">
                    ${item.minimum_price_millions ? `<div class="project-card__price">от ${item.minimum_price_millions} млн. ₽</div>` : `<div class="project-card__price">Продано</div>`}
                </div>
            </div>
        </div>
    </a>`
}

export const get_card_project_realize = (item) => {
    return `
    <a class="project-card card js-project-link" href="${item.absolute_url}">
        <div class="project-card__img_wrap">
            <div class="project-card__img">
               ${item.house_preview ?
                `<img class="js-async-image" src="${item.mini_house_preview}" data-src="${item.house_preview}" alt="project">`
                : `<img class="flat-map__img" src="${WAY_UP_DEF_IMG}" alt='default'>`}
            </div>
        </div>
        <div class="project-card_info">
            <h3 class="project-card__title">${item.title}</h3>
            <div class="project-card-details">
                <div class="project-card-details_left">
                    <div class="project-card-location">
                        ${item.address ? `<div class="project-card__address">${item.address}</div>` : ``}
                    </div>
                </div>
                <div class="project-card-details_right">
                    ${item.development_term ? `<div class="project-card__deadline">${item.development_term}</div>` : `<div class="project-card__deadline"></div>`}
                </div>
            </div>
        </div>
    </a>`
}

export const get_tempalte_vacancies = (data) => {
    let html = `<div class="vacancy-card drop-more js-drop-trigger">
    <div class="vacancy-card-header">
        ${data.position ? `<h4 class="vacancy-card__title">${data.position}</h4>` : ''}
        ${data.salary ? `<div class="vacancy-card__salary">${data.salary}</div>` : ''}
    </div>
    ${data.city_name ? `<div class="vacancy-card__city">г. ${data.city_name}</div>` : ''}
    ${data.short_description ? `<p class="vacancy-card__description js-content-prew show">${data.short_description}</p>` : ""}
    <div class="vacancies-drop-content drop-more-content-sub js-drop-more-content-sub">
        ${data.description ?
            `<div class="vacancies-card__about-description">
        ${data.description}
        </div>` : ''}
        ${data.requirements ?
            `<strong class="vacancies-card__about-requirement ">
            Требования:
        </strong>
        <div class="vacancies-card__about-requirements vacancies-list">
            ${data.requirements}
        </div>` : ''}
        ${data.conditions ?
            `<strong class="vacancies-card__about-condition">
            Условия:
        </strong>
        <div class="vacancies-card__about-conditions vacancies-list">
            ${data.conditions}
        </div>` : ''}
        <div class="vacancies-card__about-bottom">`
    if (data.address.length) {
        html += ` <strong class="vacancies-card__about-address">
                Обращаться по адресу
            </strong>`
        data.address.map(i => html += `<div class="vacancies-card__about-addresses"> ${i} </div>`)
    }
    if (data.managers.length) {
        html += `
        <strong class="vacancies-card__about-contact">
             Контакты:
        </strong>
        <ul class="vacancies-card__about-contacts">`
        data.managers.map(i => html +=
            `<li class="vacancies__item-contacts">
                <div>
                    ${i.phone ? `<a class="vacancies-card__about-phone" href="tel:${i.phone}">${i.phone}</a>` : ''}
                    ${i.name ? `<span class="vacancies-card__about-name">${i.name}</span>` : ''}
                </div>
                ${i.email ? ` <a class="vacancies-card__about-mail" href="mailto:${i.email}">${i.email}</a>` : ''}
            </li>`
        )
        html += `</ul>`
    }

    return html += `</div></div>
            <div class="drop-more-btn-horizontal">
                <div class="drop-more__trigger drop-more-vacansies js-drop-more">
                    <span class="drop-more__trigger-text">Подробно</span>
                    <i class="icon drop-more__trigger-icon js-drop-more__trigger-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 7L8 11L4 7" stroke="#59B039" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </i>
                </div>
                <script data-b24-form="click/11/zrjms9" data-skip-moving="true"> (function(w,d,u){ var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0); var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h); })(window,document,'https://portal.sk-continent.ru/upload/crm/form/loader_11_zrjms9.js'); </script>
                <button class="link link_primary vacancy-card__apply" type='button'>Откликнуться</button>
            </div>
        </div>`
}
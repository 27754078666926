/**
 * @class - класс содержит логику переключения из списка проектов на карту, обновление разрешения карты и изменения активного таба
 * @method init_target_params - определяет активный таб и делает видимым нужный контейнер
 * @method switch_map - слушает события нажатия на таб и переключает контейнер
 * @method update_map_location - обновляет zoom карты
 * @method show_map - скрывает все контейнеры и делает видимым выбранный
 */

export class SwitcherMap {
    constructor(props) {
        this.map = props.map;
        this.btn_switch_map = $(".js-switch-map");
        this.project_wrap = $(".js-switch-map-wrap");
    }
    init = () =>{
        this.init_active_wrap();
        this.switch_wrap();
    }
    init_active_wrap = () => {
        this.active_flilter = this.btn_switch_map.find("input[checked]").parent()
    }
    switch_wrap = () => {
        const self = this;
        self.btn_switch_map.on("click", function (e) {
            if ($(e.target).val() === "map") {
                self.show_wrap(this, 'map');
                // self.update_map_location();
            } else if ($(e.target).val() === "list") {
                self.show_wrap(this, "list");
            }
        })
    }
    update_map_location = () => {
        const self = this;
        if (this.map) {
            setTimeout(function () {
                self.map.update_location();
                clearTimeout(this)
            }, 1000)
        }
    }
    show_wrap = (e, wrap) => {
        this.active_flilter.removeClass('active_radio');
        this.active_flilter = $(e).addClass('active_radio');
        this.project_wrap.removeClass("show");
        $(`#${wrap}`).addClass("show");
    }
}

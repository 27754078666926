import { HouseMap, OfficeMap, ProjectDetailMap } from "../components/map";
import api from "../api/request";
import { get_local } from "../utils/storege";
export class SectionMap {
    constructor() {
        this.page = $('.js-location-map').attr('data-page');
        this.id = $('.js-location-map').attr('data-id');
        this.types_map = {
            'Project': {
                api: api.project_map,
                options: { id: this.id },
                id: this.id,
                class_map: ProjectDetailMap
            },
            'House': {
                api: api.house_map,
                options: { house: this.id },
                class_map: HouseMap
            },
            'DevelopmentQueue': {
                api: api.queue_map,
                options: { id: this.id },
                class_map: ProjectDetailMap
            },
            'SalesOfficeListPage': {
                api: api.get_office,
                options: { city: get_local("city") },
                class_map: OfficeMap,
                filter: $(".js-wrap-city-offices")
            },
            'LandingPage': {
                api: api.house_map,
                options: { id: this.id },
                class_map: HouseMap
            },
        }
        this.types_map['KovrovProject'] = this.types_map['Project'];
        this.types_map['VladimirProject'] = this.types_map['Project'];
        this.types_map['DoneHouse'] = this.types_map['House'];
    }
    init = () => {
        const object_map = this.types_map[this.page]
        if (object_map) {
            const map = new object_map.class_map({
                mapName: "map",
                filter: object_map.filter,
                api: object_map.api,
                options: object_map.options
            });
            map.init(map.createInitialMap);
        } else {
            this.hidden_map();
        }
    }
    hidden_map = () => {
        $('#section_map').addClass('hidden-wrap');
    }
}

/**
 * @class - класс содержит экземпляр слайдера для увеличеных фотографий 
 */
class FuncyBox {
  constructor() { }
  init = () => {
    $.fancybox.defaults.buttons = [
      'slideShow',
      'close'
    ],
      $('[data-fancybox]').fancybox({
        keyboard: true,
        width: 'auto',
        height: 'auto',
        arrows: true,
        backFocus: false,
      })
  }
}
new FuncyBox().init()
import api from "../api/request";
import { get_local, set_local } from "../utils/storege";
/**
 * @class - класс содержит логику работы с избранным
 * @method check_active_like - проверяет наличие помещений добавленных в избранное и делает их активными
 * @method action_favorit - слушает событие добавления в избранное, в зависимости от наличия в избранном помещения добавляет его или удаляет из избранного
 * @method get - отправляет запрос на получение списка помещений в избранном
 * @method add - отправляет запрос на добавление в избранное
 * @method delete - отправляет запрос на удаление в избранное
 */

class FavoriteActions {
  constructor() {
    this.count_favorite = $(".js-menu-favorites-count");
  }
  check_active_like = () => {
    const favorite = get_local('favorite').split(',');
    const btns_like = $('.js-btn_like');
    for (let i of btns_like) {
      for (let j of favorite) {
        if (Number($(i).attr('data-id')) === Number(j)) {
          i.classList.add("btn_like-active");
        }
      }
    }
  }
  action_favorit = () => {
    const self = this
    $(document).on("click", ".js-btn_like", function (e) {
      e.preventDefault();
      let id = Number($(this).attr('data-id'));
      const favorite = get_local('favorite').split(',');
      if (favorite) {
        if (favorite.find(i => Number(i) === Number(id))) {
          self.delete({ id: id }, this);
        } else {
          self.add({ id: id }, this);
        }
      } else {
        self.add({ id: id }, this);
      }
    })
  }
  get = () => {
    api.get_favorite()
      .then(data => {
        set_local('favorite', data.favorite_list);
        this.count_favorite.text(`(${data.count})`);
        return data
      })
  }
  add = async (params, button_like = null) => {
    await api.post_favorite(params)
      .then(data => {
        set_local('favorite', data.favorite_list);
        this.count_favorite.text(`(${data.count})`);
        if (button_like) {
          $(button_like).addClass("btn_like-active");
        }
        return data;
      })
  }
  delete = async (params, button_like) => {
    await api.delete_favorite(params)
      .then(data => {
        set_local('favorite', data.favorite_list);
        this.count_favorite.text(`(${data.count})`);
        if (button_like) {
          $(button_like).removeClass("btn_like-active");
        }
        return data;
      })
  }
}

export const favorite = new FavoriteActions();
favorite.action_favorit();

export default class InitPositionFlyWrap{
    constructor(props){
        this.wrap = props.fly_wrap;
        this.trigger_class = props.trigger_class;
        this.event = props.event
        this.padding = Number(props.padding);
        this.dinamic_position = props.dinamic_position;
        this.window_height = Number(window.innerHeight);
        this.window_width = Number(window.innerWidth)
        this.coord_x = 0;
        this.coord_y = 0;
        this.init()
    }

    init=()=>{
        $(document).on(`${this.event}`,`.${this.trigger_class}`,(e)=>{
            this.coord_x = e.pageX;
            this.coord_y = e.pageY;
            this.update_scroll();
            if(this.window_width <= 769){
                this.wrap.style.transform =`translate(${(this.window_width-280)/2}px, ${this.coord_y}px)`;
                this.wrap.style.width = `280px`;
            }else{
                this.wrap.style.width = '';
                this.wrap.style.transform =`translate(${this.dinamic_position_wigth()}, ${this.dinamic_position_heigth()})`;
            }
        })  
 
        $(window).on('scroll',()=>{
            this.update_scroll();
        })
        .on('resize',()=>{
            this.window_height = Number(window.innerHeight);
            this.window_width = Number(window.innerWidth)
            if(this.window_width <= 769){
                this.wrap.style.transform =`translate(${(this.window_width-280)/2}px, ${this.coord_y}px)`;
                this.wrap.style.width = `280px`
            }
        })
    }

    dinamic_position_heigth = () =>{
        const height_windows =  this.window_height;
        const fly_wrap_height =  this.wrap.getBoundingClientRect().height;
        const fly_wrap_position = this.coord_y+fly_wrap_height;
        if (height_windows <= fly_wrap_position) {
            return `${this.coord_y-fly_wrap_height-this.padding}px`;
        } else if (height_windows >= fly_wrap_position) {
            return `${this.coord_y+this.padding}px`;
        }
    }

    dinamic_position_wigth = () =>{
        const fly_wrap_width =  Math.round(this.wrap.getBoundingClientRect().width);
        const fly_wrap_position_rigth = this.coord_x + fly_wrap_width;
        if (fly_wrap_position_rigth >= this.window_width) {
           return `${this.coord_x - fly_wrap_width - this.padding}px`;
        } else if (fly_wrap_position_rigth <= this.window_width) {
           return `${this.coord_x+this.padding}px`;
        }
    }
 
    update_scroll = () =>{
        const fly_wrap_height = Math.round(this.wrap.getBoundingClientRect().height);
        const fly_wrap_position_top = Math.round(this.wrap.getBoundingClientRect().top);
        const fly_wrap_position_bottom = Math.round(fly_wrap_height+fly_wrap_position_top);
        const position_window_bottom = Math.round(this.window_height-fly_wrap_height*2);
        if (fly_wrap_position_top <= 100) {
            this.wrap.style.top =`${this.padding}px`;
        } else if (fly_wrap_position_bottom >= position_window_bottom) {
            this.wrap.style.top =`-${fly_wrap_height}px`;
        }
    }
}
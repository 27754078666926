import { ActionSvg } from '../components/action_svg';
import api from '../api/request';
import { SwitchGenplan } from '../components/switch_genplan';
import { get_render_drop_project_house, get_template_infrast, get_render_drop_project_commerce, get_render_drop_project_queue, get_render_drop_townhouse_process } from "../templates/templates_dropdown_cards";

/**
 * @class -  класс содержит логику для секции "отображения проекта"
 * @method render_map - при наличие координат отмечает проект на карте
 * @method target_house - выводит информацию при наведение на свг многоэтажного дома
 * @method target_queue - выводит информацию при наведение на свг очереди таунхауса
 * @method target_townhouse - выводит информацию при наведение на свг помещения таунхауса в проекте с 1 очередью
 * @method target_commercial - выводит информацию при наведение на свг дома с коммерцией
 * @method target_infrastructure - выводит информацию при наведение на свг инфраструктуры
 * @method switch_project_detail - инициализирует переключает свг на плане проекта
 * @method link_to - при выборе свг на этаже открывает детальную страницу дома или очереди
 * @method target_commercial_link - при выборе свг коммерческого дома фломирует qwery строку и переводит на страницу дома
 * @method init_custom_radiobutton - инициализирует работу кастомных радиокнопок
 */

export class ProjectDetail {
  constructor() {
    this.project = document.querySelector(".js-genplan").getAttribute("data-project");
    this.paths_house = $(".js-genplan [data-typepath=house]");
    this.paths_all = $(".js-genplan [data-typepath=all]");
    this.paths_queue = $(".js-genplan [data-typepath=queue]");
    this.paths_townhouse = $(".js-genplan [data-typepath=townhouse]");
    this.paths_commercial = $(".js-genplan [data-typepath=commercial]");
    this.switcher_wrap = $(".js-switch-wrap");
  }
  init = () => {
    this.init_category();
    this.target_all();
    this.target_house();
    this.target_queue();
    this.target_townhouse();
    this.target_commercial();
    this.target_infrastructure();
    this.switch_project_detail();
    this.init_custom_radiobutton();
    this.link_to();
    this.init_svg_scroll_position();
  }
  init_category = () => {
    let count_show_category = 3;
    if (!this.paths_all.length) {
      $(".js-category-all").remove();
    }
    if (!this.paths_house.length) {
      $(".js-category-house").remove();
      count_show_category--;
    }
    if (!this.paths_queue.length) {
      $(".js-category-queue").remove();
      count_show_category--;
    }
    if (!this.paths_commercial.length) {
      $(".js-category-commercial").remove();
      count_show_category--;
    }
    if (count_show_category <= 1) {
      $('.js-category-radio-wrap').addClass('hidden-wrap');
    }
  }
  //модалка инфо блока и перехода на следующую деталку
  target_house = () => {
    const paths_house = new ActionSvg({
      project: $(".js-genplan"),
      project_item: ".js-genplan [data-typepath=house]",
      api: api.project_inf,
      object_filters: {
        project: "id",
        house: "house"
      },
      template: get_render_drop_project_house
    });
    paths_house.init();
  }

  target_all = () => {
    const paths_house = new ActionSvg({
      project: $(".js-genplan"),
      project_item: ".js-genplan [data-all=house]",
      api: api.project_inf,
      object_filters: {
        project: "id",
        house: "house"
      },
      template: get_render_drop_project_house
    });
    paths_house.init();

    const paths_queue = new ActionSvg({
      project: $(".js-genplan"),
      project_item: ".js-genplan [data-all=queue]",
      api: api.queue_props,
      object_filters: {
        queue: "queue"
      },
      template: get_render_drop_project_queue
    });
    paths_queue.init();
  }

  target_queue = () => {
    const paths_queue = new ActionSvg({
      new_window: false,
      project: $(".js-genplan"),
      project_item: ".js-genplan [data-typepath=queue]",
      api: api.queue_props,
      object_filters: {
        queue: "queue"
      },
      template: get_render_drop_project_queue
    });
    paths_queue.init();
  }
  target_townhouse = () => {
    const paths_townhouse = new ActionSvg({
      project: $(".js-genplan"),
      project_item: ".js-genplan [data-typepath=townhouse]",
      object_filters: {
        project: "id",
        house: "house"
      },
      api: api.project_inf,
      template: get_render_drop_townhouse_process
    });
    paths_townhouse.init();
  }
  target_commercial = () => {
    const paths_commercial_house = new ActionSvg({
      project: $(".js-genplan"),
      project_item: ".js-genplan [data-typepath=commercial]",
      api: api.project_inf,
      object_filters: {
        project: "id",
        house: "house"
      },
      template: get_render_drop_project_commerce
    });
    paths_commercial_house.init();
  }
  target_infrastructure = () => {
    const paths_infrastructure = new ActionSvg({
      project: $(".js-genplan"),
      project_item: ".js-genplan [data-typepath=infrastructure]",
      object_filters: {
        project: "id",
        infrastructure: "infrastructure"
      },
      api: api.infrastructure_inf,
      template: get_template_infrast
    });
    paths_infrastructure.init();
  }

  link_to = () => {
    $(document).on("click", ".js-genplan [data-typepath=house], .js-genplan [data-typepath=all], .js-genplan [data-typepath=queue]", (e) => {
      const links_url = $(e.target).attr('data-link');
      if (links_url) {
        document.location.href = links_url;
      }
    })
    this.paths_commercial.on('click', (e) => {
      const links_url = $(e.target).attr('data-link');
      if (links_url) {
        document.location.href = `${links_url}?property=commercial`;
      }
    })
  }

  switch_project_detail = () => {
    const switcher_on_genplan = new SwitchGenplan();
    switcher_on_genplan.init();
  }
  init_custom_radiobutton = () => {
    this.switcher_wrap.on('click', function (e) {
      let switcher = $(this).find(".input__switch");
      if (!switcher.is(":disabled")) {
        let checked = switcher.attr("checked");
        switcher.attr("checked", !checked);
      }
    })
  }
  init_svg_scroll_position = () => {
    if (window.innerWidth <= 768) {
      const element = document.querySelector(".js-project-detail-svg");
      const image = element.querySelector('.veiw_plan_content_img img');
      const fn = () => {
        element.scrollLeft = (element.scrollWidth - element.clientWidth) / 2;
      }

      // Только если оригинальное изображение загружено, а не изображение-заглушка в плохом качестве.
      if (image.complete && image.src.split(image.dataset.src)[1] === '') {
        fn();
      } else {
        image.addEventListener('load', fn);
      }
    }
  }
}

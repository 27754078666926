/**
 * @class - класс содержит логику для секции блока с раскрывающией информацией
 * @method init_content - Опеределяет кол-во символов переданых в шаблон, если большее 1000 то запускает метод target если нет то скрывается кнопку "Подробнее"
 * @method target - слушает событие по назание на кнопку  раскрывающего меню, в зависимости от состояния меняет текст кнопки и направление стрелки
 */

export class BlockInfo{
    constructor(){
      this.content_inf = $(".js-content-inf")
      this.content_prev = this.content_inf.children(".js-content-prew");
      this.content_all_wrap = this.content_inf.children(".js-drop-trigger-content");
      this.content_all = this.content_all_wrap.children(".js-content-all");
      this.trigger_btn = this.content_all_wrap.children(".js-drop-trigger-btn");
      this.btn_text = this.content_all_wrap.find(".js-drop-more__trigger-text")
      this.arrow = this.content_all_wrap.find(".js-drop-more__trigger-icon");
    }
    init = () =>{
      this.init_content();
    }
    init_content = () =>{
      if(this.content_all.text().length > 1000 || this.content_all.data('trigger-expand')){
        this.target();
      }else{
        this.content_all_wrap.css({'display':"none"});
      }
    }
    target = () =>{
      this.trigger_btn.on("click",()=>{
        if( this.btn_text.text() === "Подробнее"){
          this.btn_text.text('Скрыть')
        }else{
          this.btn_text.text('Подробнее')
        }
        this.content_all.toggleClass('show');
        this.content_prev.toggleClass('show');
        this.arrow.toggleClass("rotate");
      })
    }
  }

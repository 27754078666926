import { conjugation } from '../utils/conjugation';
import { WAY_UP_DEF_IMG } from '../const/way_up'
import { get_status } from '../utils/get_status';
import { format_price_intl, getPrice } from '../utils/format_price';
import { get_promo_url } from '../utils/get_promo_url.js';

const promo_url = get_promo_url()

export const get_render_drop_project_house = (data) => {
  return `<div class='object-plan-info-content'>
    <h6 class="project-plan__house-name">Дом ${data.title}</h6>
    ${data.is_new ? `<div class="project-plan__label">New</div>` : ''}

    ${data.properties_on_sale.apartments || data.properties_on_sale.townhouses || data.properties_on_sale.offices ?
        `<div class="project-plan__forsale">
      <div class="project-plan__forsale-top">
        ${data.properties_on_sale.apartments ? `<span>${conjugation(data.properties_on_sale.apartments, ['квартира', 'квартиры', 'квартир'])} </span><br>` : ''}
        ${data.properties_on_sale.offices ? `<span>${conjugation(data.properties_on_sale.offices, ['офис', 'офиса', 'офисов'])} </span><br>` : ''}
        ${data.properties_on_sale.townhouses ? `<span>${conjugation(data.properties_on_sale.townhouses, ['квартира', 'квартиры', 'квартир'])} </span>` : ''}
      </div>
      <div class="project-plan__forsale-bottom">в продаже</div>
    </div>` : ''}
    ${data.properties_on_sale.apartments || data.properties_on_sale.townhouses || data.properties_on_sale.offices ?
      `<div class="project-plan__flats">
    ${data.apartments_prices.studios && data.apartments_on_sale.studio ? `<div class="project-plan__flat"><p class="project-plan__flat-name">Студия</p><p class="project-plan__flat-price">от ${data.apartments_prices.studios} млн. ₽</p></div>` : ''}
    ${data.apartments_prices.room1 && data.apartments_on_sale.room1 ? `<div class="project-plan__flat"><p class="project-plan__flat-name">1 комнатная</p><p class="project-plan__flat-price">от ${data.apartments_prices.room1} млн. ₽</p></div>` : ''}
    ${data.apartments_prices.room2 && data.apartments_on_sale.room2 ? `<div class="project-plan__flat"><p class="project-plan__flat-name">2 комнатная</p><p class="project-plan__flat-price">от ${data.apartments_prices.room2} млн. ₽</p></div>` : ''}
    ${data.apartments_prices.room3 && data.apartments_on_sale.room3 ? `<div class="project-plan__flat"><p class="project-plan__flat-name">3 комнатная</p><p class="project-plan__flat-price">от ${data.apartments_prices.room3} млн. ₽</p></div>` : ''}
    ${data.apartments_prices.townhouses && data.apartments_on_sale.townhouses ? `<div class="project-plan__flat"><p class="project-plan__flat-price">от ${data.apartments_prices.townhouses} млн. ₽</p></div>` : ''}
    </div>`:
      `<p class="project-plan__flat-price">Все помещения проданы</p>`}
    ${data.is_done ?
      `<div class="project-plan__deadline"><p class="project-plan__forsale-bottom">Срок сдачи </p><p class="project-plan__forsale-finish">Дом сдан</p></div>`
      :`${data.development_term ? `<div class="project-plan__deadline"><p class="project-plan__forsale-bottom">Срок сдачи </p><p class='project-plan__deadline-value'>${data.development_term}</p></div>` : ''}`}
  </div>`;
}
export const get_render_drop_project_commerce = (data) => {
  return `<div class='object-plan-info-content'>
    <h6 class="project-plan__house-name">Дом ${data.title}</h6>
    ${data.is_new ? `<div class="project-plan__label">New</div>` : ''}
    ${data.properties_on_sale.offices ?
        `<div class="project-plan__forsale">
      <div class="project-plan__forsale-top">
       <div class="project-plan__forsale-top">
        ${data.properties_on_sale.offices ? `<span>${conjugation(data.properties_on_sale.offices, ['офис', 'офиса', 'офисов'])} </span><br>` : ''}
      </div>
      <div class="project-plan__forsale-bottom">в продаже</div>
    </div>` : ''}
    ${data.properties_on_sale.offices ?
      `<div class="project-plan__flats">
    ${data.apartments_prices.offices && data.apartments_on_sale.offices ? `<div class="project-plan__flat"><p class="project-plan__flat-name">Офис</p><p class="project-plan__flat-price">от ${data.apartments_prices.offices} млн. ₽</p></div>` : ''}
    </div>`:
      `<p class="project-plan__flat-price">Все помещения проданы</p>`}
      ${data.is_done?
      `<div class="project-plan__deadline"><p class="project-plan__forsale-bottom">Срок сдачи </p><p class="project-plan__forsale-finish">Дом сдан</p></div>`
      :`${data.development_term ? `<div class="project-plan__deadline"><p class="project-plan__forsale-bottom">Срок сдачи </p><p class='project-plan__deadline-value'>${data.development_term}</p></div>` : ''}`}
  </div>`
}
export const get_render_drop_project_queue = (data) => {
  return `<div class='object-plan-info-content'>
    <div class="project-plan__details-item">
    <h6 class="project-plan__house-name">Очередь таунхаусов ${data.queue}</h6>
    ${data.is_new ? `<div class="project-plan__label">New</div>` : ''}
    ${data.properties_on_sale.townhouses ?
       `<div class="project-plan__forsale">
      <div class="project-plan__forsale-top">
       <div class="project-plan__forsale-top">
        ${data.properties_on_sale.townhouses ? `<span>${conjugation(data.properties_on_sale.townhouses, ['квартира', 'квартиры', 'квартир'])} </span>` : ''}
      </div>
      <div class="project-plan__forsale-bottom">в продаже</div>
    </div>` : ''}
    ${data.properties_on_sale.townhouses ?
      `<div class="project-plan__flats">
    ${data.apartments_prices.townhouses && data.properties_on_sale.townhouses ? `<div class="project-plan__flat"><p class="project-plan__flat-name">Квартира</p><p class="project-plan__flat-price">от ${data.apartments_prices.townhouses} млн. ₽</p></div>` : ''}
    </div>`:
      `<p class="project-plan__flat-price">Все помещения проданы</p>`}
      ${data.is_done?
      `<div class="project-plan__deadline"><p class="project-plan__forsale-bottom">Срок сдачи </p><p class="project-plan__forsale-finish">Дом сдан</p></div>`
      :`${data.development_term ? `<div class="project-plan__deadline"><p class="project-plan__forsale-bottom">Срок сдачи </p><p class='project-plan__deadline-value'>${data.development_term}</p></div>` : ''}`}
  </div>`
}
export const get_render_drop_project_all = (data) => {
  return `<div class='object-plan-info-content'>
    <h6 class="project-plan__house-name">Дом ${data.title}</h6>
    ${data.is_new ? `<div class="project-plan__label">New</div>` : ''}

    ${data.properties_on_sale.apartments || data.properties_on_sale.townhouses || data.properties_on_sale.offices ?
        `<div class="project-plan__forsale">
      <div class="project-plan__forsale-top">
        ${data.properties_on_sale.apartments ? `<span>${conjugation(data.properties_on_sale.apartments, ['квартира', 'квартиры', 'квартир'])} </span><br>` : ''}
        ${data.properties_on_sale.offices ? `<span>${conjugation(data.properties_on_sale.offices, ['офис', 'офиса', 'офисов'])} </span><br>` : ''}
        ${data.properties_on_sale.townhouses ? `<span>${conjugation(data.properties_on_sale.townhouses, ['квартира', 'квартиры', 'квартир'])} </span>` : ''}
      </div>
      <div class="project-plan__forsale-bottom">в продаже</div>
    </div>` : ''}
    ${data.properties_on_sale.apartments || data.properties_on_sale.townhouses || data.properties_on_sale.offices ?
      `<div class="project-plan__flats">
    ${data.apartments_prices.studios && data.apartments_on_sale.studio ? `<div class="project-plan__flat"><p class="project-plan__flat-name">Студия</p><p class="project-plan__flat-price">от ${data.apartments_prices.studios} млн. ₽</p></div>` : ''}
    ${data.apartments_prices.room1 && data.apartments_on_sale.room1 ? `<div class="project-plan__flat"><p class="project-plan__flat-name">1 комнатная</p><p class="project-plan__flat-price">от ${data.apartments_prices.room1} млн. ₽</p></div>` : ''}
    ${data.apartments_prices.room2 && data.apartments_on_sale.room2 ? `<div class="project-plan__flat"><p class="project-plan__flat-name">2 комнатная</p><p class="project-plan__flat-price">от ${data.apartments_prices.room2} млн. ₽</p></div>` : ''}
    ${data.apartments_prices.room3 && data.apartments_on_sale.room3 ? `<div class="project-plan__flat"><p class="project-plan__flat-name">3 комнатная</p><p class="project-plan__flat-price">от ${data.apartments_prices.room3} млн. ₽</p></div>` : ''}
    ${data.apartments_prices.townhouses && data.apartments_on_sale.townhouses ? `<div class="project-plan__flat"><p class="project-plan__flat-price">от ${data.apartments_prices.townhouses} млн. ₽</p></div>` : ''}
    </div>`:
      `<p class="project-plan__flat-price">Все помещения проданы</p>`}
    ${data.is_done ?
      `<div class="project-plan__deadline"><p class="project-plan__forsale-bottom">Срок сдачи </p><p class="project-plan__forsale-finish">Дом сдан</p></div>`
      :`${data.development_term ? `<div class="project-plan__deadline"><p class="project-plan__forsale-bottom">Срок сдачи </p><p class='project-plan__deadline-value'>${data.development_term}</p></div>` : ''}`}
  </div>`;
}
export const get_template_infrast = (data) => {
  return `<div class="flat-plan-info">
  <div class="scheme-card__info">
    <h6 class="scheme-card__info-title"> ${data.title}</h6>
    <div class="scheme-card__row scheme-card__info-size">
      <div class="scheme-card__info-area"> ${data.description}</div>
    </div>
  </div>
    <div class="scheme-card__image flat-plan-info-img">
    ${data.image ?
      `<img src="${data.image}" alt="infrastructure">`
      : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
    </div>
</div>`
}
export const get_render_drop_house = (data) => {
  return `<div class='object-plan-info-content'>
      <div class="object-plan__house-name">
        <h6 class="project-plan__flat-title"> ${data[0].number ? data[0].number : ''}</h6>
        ${data[0].properties_on_sale ? `<span>${conjugation(data[0].properties_on_sale, ['квартира', 'квартиры', 'квартир'])} в продаже</span>` : '<span>Квартир в продаже нет</span>'}
      </div>
    ${data[0].prices.studios && data[0].apartments_on_sale.studio ? `<div class="project-plan__flat"><p class="project-plan__flat-name">Студия</p><p class="project-plan__flat-price">от ${data[0].prices.studios} млн. ₽</p></div>` : ''}
    ${data[0].prices.room1 && data[0].apartments_on_sale.room1 ? `<div class="project-plan__flat"><p class="project-plan__flat-name">1 комнатная</p><p class="project-plan__flat-price">от ${data[0].prices.room1} млн. ₽</p></div>` : ''}
    ${data[0].prices.room2 && data[0].apartments_on_sale.room2 ? `<div class="project-plan__flat"><p class="project-plan__flat-name">2 комнатная</p><p class="project-plan__flat-price">от ${data[0].prices.room2} млн. ₽</p></div>` : ''}
    ${data[0].prices.room3 && data[0].apartments_on_sale.room3 ? `<div class="project-plan__flat"><p class="project-plan__flat-name">3 комнатная</p><p class="project-plan__flat-price">от ${data[0].prices.room3} млн. ₽</p></div>` : ''}
      </div>
    </div>`;
}
export const get_render_drop_floor = (data) => {
  return `
    <div class='object-plan-info-content'>
      <div class="object-plan__house-name">
        <h6 class="project-plan__flat-title"> Этаж ${data.number ? data.number : ''}</h6>
        ${data.properties_on_sale ? `<span>${conjugation(data.properties_on_sale, ['квартира', 'квартиры', 'квартир'])} в продаже</span>` : '<span>Квартир в продаже нет</span>'}
      </div>
      <div class="object-plan__flats">
        ${data.prices.studios && data.apartments_on_sale.studio ? `<div class="object-plan__flat">
          <p class="object-plan__flat-name">Студия</p><p class="object-plan__flat-name">от ${data.prices.studios} млн. ₽</p>
        </div>` : ''}
        ${data.prices.room1 && data.apartments_on_sale.room1 ? `<div class="object-plan__flat">
          <p class="object-plan__flat-name">1 комнатная</p><p class="object-plan__flat-name">от ${data.prices.room1} млн. ₽</p>
        </div>` : ''}
        ${data.prices.room2 && data.apartments_on_sale.room2 ? `<div class="object-plan__flat">
          <p class="object-plan__flat-name">2 комнатная</p><p class="object-plan__flat-name">от ${data.prices.room2} млн. ₽</p>
        </div>` : ''}
        ${data.prices.room3 && data.apartments_on_sale.room3 ? `<div class="object-plan__flat">
          <p class="object-plan__flat-name">3 комнатная</p><p class="object-plan__flat-name">от ${data.prices.room3} млн. ₽</p>
        </div>` : ''}
      </div>
    </div>`;
}
export const get_template_office = (data) => {
  const picture_2d_layout = data.picture_simple_2d ?? data.get_picture_2d
  return `
  <div class="flat-plan-info">
  <div class="scheme-card__info">
    <h6 class="scheme-card__info-title"> Офис №${data.number ? data.number : ''}</h6>
    <div class="scheme-card__row scheme-card__info-size">
    ${data.area ? `<div class="scheme-card__info-area"> <span class="scheme-card__info-subtitle">Площадь</span><span class="scheme-card__info-value">${data.area}</span></div>` : ''}
    ${data.self_floor ? `<div class="scheme-card__info-area"> <span class="scheme-card__info-subtitle">Этаж</span><span class="scheme-card__info-value">${data.self_floor}</span></div>` : ''}
    </div>
    ${data.project_title ? `<div class="scheme-card__row"><span class="scheme-card__info-subtitle">Проект</span><span class="scheme-card__info-value">${data.project_title}</span></div>` : ''}
    ${data.house ? `<div class="scheme-card__row"><span class="scheme-card__info-subtitle">Дом</span><span class="scheme-card__info-value">${data.house} </span></div>` : ''}
    ${data.new_price ?
      `<div class=" scheme-card__row-price">
          <span class="scheme-card__info-price scheme-card__info-price-discount">${format_price_intl.format(data.price)}</span>
          <span class="scheme-card__info-price">${format_price_intl.format(data.new_price)}</span>
        </div>
        <div class="flat-card_sale">
            * Скидки предоставляются в соответствии с разделом
            <a href="${promo_url}" class="flat-card_sale-link">Акции</a>
        </div>`
      : `<div class=" scheme-card__row-price">
        <span class="scheme-card__info-price">${getPrice(data.price)}</span>
     </div>`
    }
  </div>
    <div class="scheme-card__image flat-plan-info-img">
    ${picture_2d_layout ?
      `<img src="${picture_2d_layout}" alt="Планировка">`
      : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
    </div>
</div>`
}
export const get_render_drop_flat = (data) => {
  const picture_2d_layout = data.picture_simple_2d ?? data.get_picture_2d
  return `
      <div class="flat-plan-info">
        <div class="scheme-card__info">
          <div class="scheme-card__row">
            <h6 class="scheme-card__info-title"> ${data.rooms_count} ${data.number ? `№${data.number}` : ''}</h6>
          </div>

          <div class="scheme-card__row">
            <div class = "scheme-card__row-sub">
            ${data.status_title ? `<div class="scheme-card__info-status"> <span class="scheme-card__info-value scheme-card__info-status-${data.schema_status}">${data.status_title}</span></div>` : ''}
            </div>
            <div class="scheme-card__row-sub scheme-card__info-size">
            ${data.area ? `<div class="scheme-card__info-area"> <span class="scheme-card__info-subtitle">Площадь</span><span class="scheme-card__info-value">${data.area}</span></div>` : ''}
            ${data.floor ? `<div class="scheme-card__info-area"> <span class="scheme-card__info-subtitle">Этаж</span><span class="scheme-card__info-value">${data.floor}</span></div>` : ''}
            </div>
            ${data.project_title ? `<div class="scheme-card__info-area"><span class="scheme-card__info-subtitle">Проект</span><span class="scheme-card__info-value">${data.project_title}</span></div>` : ''}
            ${data.house ? `<div class="scheme-card__info-area"><span class="scheme-card__info-subtitle">Дом</span><span class="scheme-card__info-value">${data.house} </span></div>` : ''}
          </div>
          ${data.new_price ?
          `<div class=" scheme-card__row-price">
            <span class="scheme-card__info-price scheme-card__info-price-discount">${format_price_intl.format(data.price)}</span>
            <span class="scheme-card__info-price">${format_price_intl.format(data.new_price)}</span>
          </div>
          <div class="flat-card_sale">
            * Скидки предоставляются в соответствии с разделом
            <a href="${promo_url}" class="flat-card_sale-link">Акции</a>
          </div>`
        : `<div class=" scheme-card__row-price">
            <span class="scheme-card__info-price">${getPrice(data.price)}</span>
           </div>`}
        </div>
        <div class="scheme-card__image flat-plan-info-img">
          ${picture_2d_layout ?
      `<img src="${picture_2d_layout}" alt="Планировка">`
      : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
         </div>
      </div>`;
};
export const get_render_drop_townhouse_process = (data) => {
  return `
    <div class='object-plan-info-content'>
      <div class='scheme-card__row'>
        <h6 class="project-plan__house-title">Таунхаус</h6>
      </div>
      <div class='scheme-card__row'>
        <div class="project-plan__details">
          ${data.title ? `<div class="project-plan__details-item"><span class="project-plan__forsale-bottom"><strong>Дом: </strong></span><strong>${data.title}</strong></div>` : ''}
          ${data.queue ? `<div class="project-plan__details-item"><span class="project-plan__forsale-bottom">Очередь таунхаусов: </span><strong>${data.queue}</strong></div>` : ''}
          ${data.project_title ? `<div class="project-plan__details-item"><span class="project-plan__forsale-bottom">Проект: </span><strong>${data.project_title}</strong></div>` : ''}
        </div>
      </div>
      <div class="scheme-card__row">
        <div class="scheme-card__info-size">
        ${data.area ? `<div class="object-plan__flat"><p class="project-plan__flat-name">Площадь дома</p><p class="project-plan__flat-price">${data.area} м²</p></div>` : ''}
        ${data.area_district ? `<div class="object-plan__flat"><p class="project-plan__flat-name">Площадь земля</p><p class="project-plan__flat-price">${data.area_district} сот.</p></div>` : ''}
        </div>
      </div>
      ${data.new_price ?
      `<div class=" scheme-card__row-price">
          <span class="scheme-card__info-price scheme-card__info-price-discount">${format_price_intl.format(data.price)}</span>
          <span class="scheme-card__info-price">${format_price_intl.format(data.new_price)}</span>
        </div>
        <div class="flat-card_sale">
          * Скидки предоставляются в соответствии с разделом
          <a href="${promo_url}" class="flat-card_sale-link">Акции</a>
        </div>`
      : `<div class=" scheme-card__row-price">
          <span class="scheme-card__info-price">${getPrice(data.price)}</span>
         </div>`}
         ${data.is_done ?
         `<div class="project-plan__deadline"><p class="project-plan__forsale-bottom">Срок сдачи </p><p class="project-plan__forsale-finish">Очередь сдана</p></div>`
         :`${data.development_term ? `<div class="project-plan__deadline"><p class="project-plan__forsale-bottom">Срок сдачи </p><p class='project-plan__deadline-value'>${data.development_term}</p></div>` : ''}`}
    </div>`;
};
export const get_render_drop_townhouse = (data) => {
  const picture_2d_layout = data.picture_simple_2d ?? data.get_picture_2d
  return `
      <div class="flat-plan-info">
        <div class="scheme-card__info">
          <div class="scheme-card__row">
            <h6 class="project-plan__house-title"> Квартира №${data.number ? data.number : ''}</h6>
            ${data.status_property ? `<div class="scheme-card__row-sub"><div class="project-plan__progress-top-progress"> ${get_status(data.status_property.toUpperCase())}</div></div>` : ''}
          </div>
          <div class="scheme-card__row">
            <div class="scheme-card__row-sub scheme-card__info-size">
              ${data.area ? `<div class="scheme-card__info-area"><span class="scheme-card__info-subtitle">Площадь дома</span><span class="scheme-card__info-value">${data.area}</span></div>` : ''}
              ${data.area_district ? `<div class="scheme-card__info-area"> <span class="scheme-card__info-subtitle">Земля</span><span class="scheme-card__info-value">${data.area_district} сот.</span></div>` : ''}
            </div>
            ${data.address ? `<div class="scheme-card__info-area"><span class="project-plan__forsale-bottom"><strong>Дом</strong></span><span class="scheme-card__info-value"><strong>${data.address}</strong></span></div>` : ''}
            ${data.queue ? `<div class="scheme-card__info-area"><span class="project-plan__forsale-bottom">Очередь таунхаусов</span><span class="scheme-card__info-value">${data.queue}</span></div>` : ''}
            ${data.project_title ? `<div class="scheme-card__info-area"><span class="scheme-card__info-subtitle">Проект</span><span class="scheme-card__info-value">${data.project_title}</span></div>` : ''}
            ${data.check_box_development ?
              `<div class="scheme-card__info-area">
            ${data.development_quarter ? `<div class="scheme-card__info-area"><span class="project-plan__forsale-bottom">Срок сдачи дома</span><span class="scheme-card__info-value">${data.development_quarter}</span></div>` : ''}
            </div>`:
              `<p class="project-plan__flat-price">Срок сдачи не указан</p>`}
          </div>
          ${data.new_price ?
            `<div class="scheme-card__row-price">
              <span class="scheme-card__info-price scheme-card__info-price-discount ">${format_price_intl.format(data.price)}</span>
              <span class="scheme-card__info-price">${format_price_intl.format(data.new_price)}</span>
            </div>
            <div class="flat-card_sale">
              * Скидки предоставляются в соответствии с разделом
              <a href="${promo_url}" class="flat-card_sale-link">Акции</a>
            </div>
            `
      : `<div class="scheme-card__row-price">
            <span class="scheme-card__info-price">${getPrice(data.price)}</span>
          </div>`}
        </div>
        <div class="scheme-card__image flat-plan-info-img">
        ${picture_2d_layout ?
      `<img src="${picture_2d_layout}" alt="Планировка">`
      : `<img src="${WAY_UP_DEF_IMG}" alt="default">`}
        </div>
      </div>`;
};

export const get_render_drop_section_inf = (data) => {
  return `
  <div class='object-plan-info-content'>
    <div class="object-plan-info">
      <p class="decor_description">${data}</p>
    </div
  </div>
  `
}


import { CalcMortgage } from '../components/calculations';

export class SectionProjectMortgage {
    constructor() {
        this.page_name = $('.js-id-project').attr('data-page');
        this.types_page = {
            'Project': "project",
            'KovrovProject': "project",
            'VladimirProject': "project",
            'House':"house",
            'DevelopmentQueue': "queue"
        }
    }
    init = () => {
        const calculation_mortage = new CalcMortgage({ filter_name: this.types_page[this.page_name] })
        calculation_mortage.init();
        calculation_mortage.get();
    }
}

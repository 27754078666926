const format_price_intl = {
  format: (value) => new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB', minimumFractionDigits: 0, maximumFractionDigits: 0}).format(Math.floor(value))
}
const format_price = new Intl.NumberFormat('ru-RU', { maximumSignificantDigits: 3 })
const format_price_full = new Intl.NumberFormat('ru-RU');
const format_price_original = new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'})

export const getPrice = (price) => {
  return isNaN(parseFloat(price))
    ? price
    : format_price_intl.format(price)
}

export {
    format_price_intl,
    format_price,
    format_price_full,
    format_price_original,
}

import { get_local } from "../utils/storege";
import { get_tempalte_vacancies } from '../templates/templates_vacansies';
import { DefaultPagination } from "../components/pagination";
import { get_city } from "../components/get-city";
import api from "../api/request";

/**
 * @class  класс содержит логику для секции "список вакансий" 
 * @method city_filter - рендерит города в фильтр города
 * @method target_city - слушатель фильтр города и отправляет запрос
 * @method pagination_vacancy - создает экземпляр класса пагинации 
 * @method request_project - запрос списка проектов в городе
 * @method drop_contents - раскрывает полную информацию о вакансии
 */

export class VacancyList {
    constructor() {
        this.city = get_local('city')
        this.form_pagination = $(".js-vacancies-filters");
        this.block_wrap_pagination = $(".js-vacancies-wrap");
        this.select_city_vacansies = $(".js-set-city-vacancies");
        this.pagination = null;
    }
    init = () => {
        this.city_filter();

        this.target_city();
        this.drop_contents();
    }
    city_filter = async () => {
        get_city.render_city_select($(".js-set-city")).then(() => this.pagination_vacancy());
    }
    target_city = () => {
        const self = this
        $(document).on('click', '.js-custom-select-wrap li', function () {
            self.pagination.reset_pagination();
        })
    }
    pagination_vacancy = () => {
        this.pagination = new DefaultPagination({
            form: this.form_pagination,
            wrap_content: this.block_wrap_pagination,
            template: get_tempalte_vacancies,
            city: this.city,
            api: api.get_vacancy
        })
        this.pagination.init();
        this.pagination.first_render();
    }
    drop_contents = () => {
        $(document).on("click", ".js-drop-more", function () {
            const arrow = $(this).find(".js-drop-more__trigger-icon");
            const parent = $(this).closest(".js-drop-trigger");
            const sub_content = parent.children(".js-drop-more-content-sub");

            $(".js-content-prew").toggleClass("show");
            arrow.toggleClass("rotate");
            sub_content.slideToggle(400);
        })
    }

}
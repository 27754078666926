import { getAllUrlParams } from '../utils/parse_url';
import noUiSlider from 'nouislider'
import wNumb from 'wnumb';
import { dayjs_quarter_of_year } from '../utils/dayjs';

class AbstractRanges {
  constructor(props) {
    this.range = props.range;
    this.step = 0.1;
    this.range_type = 'duble';
    this.round_value = props.round;
    this.options = getAllUrlParams();
    this.rangeItem = {};
    this.values = {};
    this.default_value = {};
  }
  init = () => {
    this.create($(this.range));
  }
  create = (range) => {
    const self = this;
    self.rangeItem = $(range)[0];
    const value = self.init_value(range);
    if (value) {
      noUiSlider.create(self.rangeItem, self.getOptions(value[0], value[1], self.step));
      self.target_value(range, self.rangeItem);
      const parentRange = $(this.rangeItem).closest(".input.range");
      if(value[0] === value[1]){
        parentRange.find(".noUi-base").last().css("display", "none");
      }else{
        parentRange.find(".noUi-base").last().css("display", "");
      }
      self.rangeItem.noUiSlider.on("update", function (values) {
        self.update(values);
      });
    }
  }
  update_range = (min, max) => {
    const min_value = Number(Math.floor(min * 10).toFixed(1) / 10);
    const max_value = Number(Math.ceil(max * 10).toFixed(1) / 10);
    if (this.rangeItem && this.rangeItem.noUiSlider) {
      if (max_value >= min_value) {
        const parentRange = $(this.rangeItem).closest(".input.range")

        this.default_value = {
          min: min_value,
          max: max_value
        }
        this.rangeItem.noUiSlider.updateOptions({
          animate: true,
          animationDuration: 1600,
          range: {
            min: min_value,
            max: max_value
          }
        });
        if(max_value === min_value){
          parentRange.find(".noUi-base").last().css("display", "none");
        }else{
          parentRange.find(".noUi-base").last().css("display", "");
        }
        if (Array.isArray(this.getStartValue(min_value, max_value))) {
          this.rangeItem.noUiSlider.set([min_value, max_value]);
        }
      } else {
        this.rangeItem.noUiSlider.updateOptions({
          animate: true,
          animationDuration: 1600,
          range: {
            min: 0,
            max: 1
          }
        })
        this.rangeItem.setAttribute('disabled', true);
        this.rangeItem.noUiSlider.set([0, 1]);
      }
    }
  }
  update = (values) => {
    const parentRange = $(this.rangeItem).closest(".input.range");
    const inputMin = parentRange.find(".input__number-min");
    const inputMax = parentRange.find(".input__number-max");
    inputMin.val(parseFloat(values[0]).toFixed(1));
    inputMax.val(parseFloat(values[1]).toFixed(1));
    this.defaultRangeSlirdersLight(parentRange, values);
    return [values[0], values[1]];
  }
  init_value = (range) => {
    let valueMax = null;
    let valueMin = null;
    valueMin = Number(Math.floor(this.serializeNumber(range.data().valueMin) * 10).toFixed(1) / 10);
    valueMax = Number(Math.ceil(this.serializeNumber(range.data().valueMax) * 10).toFixed(1) / 10);
    this.default_value =
    {
      min: valueMin,
      max: valueMax
    };
    if (!isNaN(valueMax) & !isNaN(valueMin)) {
      return [valueMin, valueMax];
    }
  }
  get_range = () => {
    return this.rangeItem;
  }
  set = (props) => {
    const { max, min } = props;
    this.default_value =
    {
      min: min,
      max: max
    }
    this.update(min, max);
  }
  getOptions = (min, max, step, percent) => {
    let ceil = 1
    this.round_value && (ceil = 0)
    const settings = {
      animate: true,
      animationDuration: 1600,
      connect: true,
      range: {
        min,
        max,
      },
      step,
      format: wNumb({
        decimals: ceil
      }),
      start: this.getStartValue(min, max),
    }

    return settings;
  }
  getStartValue(min, max) {
    const startValue = this.range.attr('data-value-start');
    if (startValue == null) {
      switch (this.range_type) {
        case 'duble':
          return [min, max];
        case 'one':
          return [min];
        case 'precent':
          return [max];
      }
    }

    return startValue;
  }
  //устанавливает выбранное значение
  target_value = (slider, sliderElem) => {
    let key = slider.attr("id")
    if (this.options[key]) {
      let targetStart = this.options[key][0]
      let targetEnd = this.options[key][1]
      return sliderElem.noUiSlider.set([targetStart, targetEnd]);
    }
  }
  // делает активным у сингл ренж если он не в дефолтном значение
  rangeSlirdersLight = (slider, value) => {
    // if (+value <= +this.default_value.min) {
    //   slider.removeClass("active_filter");
    // } else {
    //   slider.addClass("active_filter");
    // }
    slider.addClass("active_filter");
  }
  // делает активным у ренж с несколькими ползунками если он не в дефолтном значение
  defaultRangeSlirdersLight = (slider, values) => {
    const [min, max] = values;
    if (+min <= +this.default_value.min && +max >= +this.default_value.max) {
      slider.removeClass("active_filter");
    } else {
      slider.addClass("active_filter");
    }
  }
  serializeNumber = (num) => {
    if (typeof num === 'string') {
      const str = num.replace(",", ".");
      return parseFloat(str);
    }
    return num
  }
  numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
}

export class RangesDefualt extends AbstractRanges {
  constructor(props) {
    super(props);
    this.step = 0.1;
    this.round_value = false;
    this.range = props.range;
    this.ranges = props.range;
  }
}

export class RangeFloor extends AbstractRanges {
  constructor(props) {
    super(props)
    this.range = props.range;
    this.ranges = props.range;
    this.step = 0.1;
    this.oneRange = false;
    this.percent = props.percent;
    this.round_value = props.round;
    this.options = getAllUrlParams();
  }
  update = (values) => {
    const parentRange = $(this.rangeItem).closest(".input.range");
    const inputMin = parentRange.find(".input__number-min");
    const inputMax = parentRange.find(".input__number-max");
    inputMin.val(Math.ceil(parseFloat(values[0]).toFixed(1)));
    inputMax.val(Math.ceil(parseFloat(values[1]).toFixed(1)));
    this.defaultRangeSlirdersLight(parentRange, values);
  }
}

export class RangePersent extends AbstractRanges {
  constructor(props) {
    super(props)
    this.range = props.range;
    this.ranges = props.range;
    this.step = 5_000;
    this.range_type = 'precent';
    this.round_value = true;
    this.options = getAllUrlParams();
    this.default_value = { min: 0, max: 1, percent: 20 };
    this.init();
  }
  create = (range) => {
    this.rangeItem = range[0];
    const value = this.init_value(range);
    if (value) {
      this.default_value = { min: value[0], max: value[1], percent: 20 }
      noUiSlider.create(this.rangeItem, this.getOptions(value[0], value[1], this.step, this.range_type, 20));
      this.update(value[0], value[1])
    }
  }
  init_value = (range) => {
    let valueMax = null;
    let valueMin = null;
    if (typeof range.data().valueMax === 'string') {
      valueMax = Number((range.data().valueMax).replace(/,/, '.'));
    } else {
      valueMax = Number(range.data().valueMax);
    }
    valueMin = Number(Math.floor(parseInt(range.data().valueMin) * 10).toFixed(1) / 10);

    this.default_value =
    {
      min: valueMin,
      max: valueMax
    };
    if (!isNaN(valueMax) & !isNaN(valueMin)) {
      if (valueMax !== valueMin) {
        return [valueMin, valueMax];
      }
    }
  }
  get_value = () => {
    return this.default_value
  }
  update_perc_input = (percent, targetPrice) => {
    const parentRange = $(this.rangeItem).closest(".input.range");
    const paymentInput = parentRange.find(".input__number-min");
    const percentInput = parentRange.find(".input__number-max");

    paymentInput.val(this.numberWithSpaces(parseInt(targetPrice)));
    percentInput.val(`${Math.ceil(percent)}%`);
  }
  update = (target_price, max_price) => {
    const parentRange = $(this.rangeItem).closest(".input.range");

    let percent = parseInt(max_price) ? (target_price * 100) / parseInt(max_price) : 0;

    this.default_value.percent = percent;

    if (percent) {
      this.update_perc_input(percent, target_price);
    } else {
      this.update_perc_input(0, 0);
    }

    parentRange.find(".input__wrap").last().css("border", "none");
    this.rangeSlirdersLight(parentRange, target_price);
  }
  update_percentage = (percent, max_price) => {
    max_price = parseInt(max_price);
    if (max_price) {
      const targetPrice = max_price * (percent / 100)

      const isChanged = Math.ceil(this.default_value.percent) < percent;
      if (isChanged) {
        this.default_value.percent = percent;
        this.default_value.min = targetPrice;
        this.update_perc_input(percent, targetPrice);

        this.rangeItem.noUiSlider.updateOptions({
          range: {
            min: this.default_value.min,
            max: this.default_value.max,
          }
        })
      }

      return isChanged;
    }

    return false;
  }
}

export class RangeDeadline extends AbstractRanges {
  constructor(...args) {
    super(...args)
    this.step = 1;
    this.round_value = false;
    this.start_input = $('.js_development_quarter_start');
    this.end_input = $('.js_development_quarter_end');
  }
  init_value = (range) => {
    const valueMin = 0
    const valueMax = range.data().valueMax;
    if (typeof valueMax === 'string') {
      $("input[name=development_quarter]").val('')
      return null
    } else if (valueMax === 0) {
      $("input[name=development_quarter]").val('Сдан')
    } else {
      return [valueMin, valueMax]
    }
  }
  create = (range) => {
    const self = this;
    this.rangeItem = range[0];
    const value = this.init_value(range);
    if (value) {
      this.default_value = { min: value[0], max: value[1] - 1 }
      noUiSlider.create(this.rangeItem, self.getOptions(value[0], value[1], this.step, this.range_type));
      this.target_value($(this), this.rangeItem);

      this.rangeItem.noUiSlider.on("update", function (values) {
        self.update(values);
      })
    }
  }
  update = (values) => {
    const parentRange = $(this.rangeItem).closest(".input.range");
    const inputMin = parentRange.find(".input__number-min");
    const inputMax = parentRange.find(".input__number-max");
    const [delta, allQuarterBetween] = values;
    const today = dayjs_quarter_of_year();
    const currentRelativeDate = dayjs_quarter_of_year().add(+delta, 'quarter');
    const currentQuarter = currentRelativeDate.quarter();
    const currentYear = currentRelativeDate.get("year");
    const resultDate = dayjs_quarter_of_year().add(+allQuarterBetween, 'quarter');
    const quarter = resultDate.get("quarter");
    const year = resultDate.get("year");
    const isEqual = today.isSame(currentRelativeDate);
    this.start_input.val(isEqual ? 0 : (currentYear * 10) + currentQuarter);
    this.end_input.val((year * 10) + quarter);
    inputMin.val(isEqual ? "Сдан" : `${currentQuarter}кв. ${currentYear}`);
    inputMax.val(Number(allQuarterBetween) === 0 ? "Сдан" : `${quarter}кв. ${year}`);
    this.defaultRangeSlirdersLight(parentRange, values);
  }
}

export class RangePrice extends AbstractRanges {
  constructor(...args) {
    super(...args);
    this.step = 50_000;
    this.oneRange = true;
    this.round_value = true;
    this.range_type = 'one';
  }
  create = (range) => {
    const self = this;
    self.rangeItem = range[0];
    const value = self.init_value(range);
    if (value) {
      noUiSlider.create(self.rangeItem, self.getOptions(value[0], value[1], self.step, self.range_type));
      self.target_value($(this), self.rangeItem);
      self.rangeItem.noUiSlider.on("update", function (values) {
        self.update(values, value[0]);
      })
      $(this).closest(".input.range").find(".input__wrap").last().remove();
    }
  }
  update = (values, defualtValue) => {
    const parentRange = $(this.rangeItem).closest(".input.range");
    const inputMin = parentRange.find(".input__number-min");
    const valueTypeNumber = this.serializeNumber(values[0]).toFixed(0);
    inputMin.val(this.numberWithSpaces(valueTypeNumber));
    parentRange.find(".input__wrap").last().css("border", "none");
    this.rangeSlirdersLight(parentRange, valueTypeNumber, defualtValue);
    return [valueTypeNumber];
  }
  get_value = () => {
    return this.rangeItem.noUiSlider.get();
  }
}

export class RangeYaerOrMounth extends AbstractRanges {
  constructor(props) {
    super(props)
    this.step = 1;
    this.oneRange = true;
    this.round_value = true;
    this.range_type = 'one';
  }
  create = (range) => {
    const self = this;
    self.rangeItem = range[0];
    const value = self.init_value(range);
    if (value) {
      self.default_value = { min: value[0], max: value[1] };
      noUiSlider.create(self.rangeItem, self.getOptions(value[0], value[1], self.step, self.range_type));
      self.target_value($(this), self.rangeItem);
      self.rangeItem.noUiSlider.on("update", function (values) {
        self.update(values, value[0]);
      })
      $(this).closest(".input.range").find(".input__wrap").last().remove();
    }
  }
  update = (values, defualtValue) => {
    const parentRange = $(this.rangeItem).closest(".input.range");
    const inputMin = parentRange.find(".input__number-min");
    const valueTypeNumber = this.serializeNumber(values[0]).toFixed(0);
    inputMin.val(this.numberWithSpaces(valueTypeNumber));
    parentRange.find(".input__wrap").last().css("border", "none");
    this.rangeSlirdersLight(parentRange, valueTypeNumber, defualtValue);
    return [valueTypeNumber];
  }
  get_value = () => {
    return this.rangeItem.noUiSlider.get();
  }
}

import { getAllUrlParams } from '../utils/parse_url';
import { createPopper } from '@popperjs/core';

/**
 * @class - класс содержит логику создания кастомного селекта, используется Popper для изменения положения выпадашки
 * @method start_select - перебирает все селекты на странице и иницализирует
 * @method init_select - инициализирует селекты, если есть значения в qwery строке, то устанавливает их
 * @method update_select - обновляет значения селекта после рендера
 * @method remove - очищает выпадающий список
 * @method get_select - возвращает значение у селекта
 * @method toggle_select - открывает и закрывает выпадающий список
 * @method target_list_item - слушает событие клика в выпадающем списке
 * @method set_value - устанавливает значение текущего селекта
 * @method install_value - устанавливает активным первое значение из списка, либо переданое значние
 * @method install_last_value - устанавливает активным последнее значение из списка
 */

export class CustomSelect {
	constructor() {
		this.custom_select_wrap = $('.js-custom-select-wrap');
		this.options = getAllUrlParams();
		this.default_title = '';
	}
	init = () => {
		this.start_select();
		this.toggle_select();
		this.target_list_item();
	}
	start_select = () => {
		this.custom_select_wrap.each((index, select) => {
			this.init_select(select);
		})
	}
	init_select = (select) => {
		const dropDownBtn = $(select).find('.js-dropdown-button');
		const dropDownList = $(select).find('.js-dropdown-list');
		const dropDownListItems = $(dropDownList).find('.js-dropdown-list-item');
		const dropDownInput = $(select).find('.js-dropdown-input-hidden');

		//если есть значение в url подставляет как активное, если нет оставляет первый в списке
		if (this.options.length) {
			let key = dropDownList.attr('name');
			const option_value = this.options[key];
			for (let i = 0; i < dropDownListItems.length; ++i) {
				if (option_value) {
					if (option_value === dropDownListItems[i].getAttribute('data-value')) {
						dropDownListItems[i].addClass('dropdown__list--active');
						dropDownInput.val(option_value);
						dropDownBtn.text(dropDownListItems[i].text());
					}
				}
			}
		} else {
			if (dropDownListItems[0]) {
				this.default_title = $(dropDownListItems[0]).text().trim();
				dropDownBtn.text(this.default_title);
				dropDownInput.val($(dropDownListItems[0]).attr('data-value'));
				$(dropDownListItems[0]).addClass('dropdown__list--active');
			}
		}
		// Сравнивает начальное и текущее значение
		$(dropDownListItems).on('click', dropDownList, function (e) {
			if (this.default_title === this.innerText.trim()) {
				$(select).removeClass('active_filter');
			} else {
				$(select).addClass('active_filter');
			}
		})
		// Клик снаружи дропдауна. Закрыть дропдаун
		$(document).on('click', function (e) {
			if (e.target !== dropDownBtn[0]) {
				dropDownList.removeClass('dropdown__list--visible');
				$(select).removeClass('dropdown__button--active');
				$(select).removeClass('hover_select');
			}
		})
		// Нажатие на Tab или Escape. Закрыть дропдаун
		$(document).on('keydown', function (e) {
			if (e.key === 'Tab' || e.key === 'Escape') {
				dropDownList.removeClass('dropdown__list--visible');
				$(select).removeClass('dropdown__button--active');
				$(select).removeClass('hover_select');
			}
		})
	}
	update_select = (select_name) => {
		const select = $(`.js-custom-select-wrap[name=${select_name}]`)
		const dropDownBtn = $(select).find('.js-dropdown-button');
		const dropDownList = $(select).find('.js-dropdown-list');
		const dropDownListItems = $(dropDownList).find('.js-dropdown-list-item');
		const dropDownInput = $(select).find('.js-dropdown-input-hidden');
		dropDownBtn.text($(dropDownListItems[0]).text());
		dropDownInput.val($(dropDownListItems[0]).attr('data-value'));
		$(dropDownListItems[0]).addClass('dropdown__list--active');
		select.removeClass('active_filter')
	}
	remove = (name) => {
		let wrap = $(`.js-dropdown-list[name=${name}]`);
		wrap.children().remove();
	}
	get_select = (select_name) => {
		const select = $(`.js-custom-select-wrap[name=${select_name}]`);
		const dropDownInput = $(select).find('.js-dropdown-input-hidden');
		return dropDownInput.val();
	}
	// Клик по кнопке. Открыть/Закрыть select
	toggle_select = () => {
		this.custom_select_wrap.on('click', '.js-dropdown-button', function (e) {
			const parent = $(this).closest('.js-custom-select-wrap');
			const list = parent.children('.js-dropdown-list');
			createPopper(this, list[0], {
				placement: 'bottom',
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, 8],
						},
					},
				],
			});
			list.toggleClass('dropdown__list--visible');
			parent.children('.js-dropdown-button').addClass('dropdown__button--active');
			parent.addClass('hover_select');
		})
	}
	target_list_item = () => {
		const then = this;
		this.custom_select_wrap.on('click', '.js-dropdown-list-item', function () {
			then.set_value(this);
		})
	}
	set_value = (item) => {
		const parent = $(item).closest('.js-custom-select-wrap');
		const dropDownInput = parent.children('.js-dropdown-input-hidden');
		const dropDownBtn = parent.children('.js-dropdown-button');
		const dropDownList = parent.children('.js-dropdown-list');
		const dropDownListItem = dropDownList.children('.js-dropdown-list-item');
		dropDownList.removeClass('dropdown__list--visible');
		dropDownListItem.removeClass('dropdown__list--active');
		$(item).addClass('dropdown__list--active');
		if (this.default_title !== $(item).text().trim()) {
			parent.addClass('active_filter')
		}else{
			parent.removeClass('active_filter')
		}
		dropDownBtn.text($(item).text());
		dropDownInput.val($(item).attr('data-value'));
	}
	install_value = (select_name, value) => {
		const select = $(`.js-custom-select-wrap[name=${select_name}]`)
		const dropDownBtn = $(select).find('.js-dropdown-button');
		const dropDownList = $(select).find('.js-dropdown-list');
		const dropDownListItems = value
			? $(dropDownList).find(`.js-dropdown-list-item[data-value='${value}']`)
			: $(dropDownList).find(`.js-dropdown-list-item[data-value='']`);
		const dropDownInput = $(select).find('.js-dropdown-input-hidden');
		dropDownBtn.text($(dropDownListItems).text());
		dropDownInput.val(value);
		if(value){
			select.addClass('active_filter')
		}else{
			select.removeClass('active_filter')
		}
		$(dropDownList).find(`.js-dropdown-list-item`).removeClass('dropdown__list--active');
		$(dropDownListItems).addClass('dropdown__list--active');
	}
	install_last_value = (select_name) => {
		const select = $(`.js-custom-select-wrap[name=${select_name}]`)
		const dropDownBtn = $(select).find('.js-dropdown-button');
		const dropDownList = $(select).find('.js-dropdown-list');
		const dropDownListItems = $(dropDownList).find('.js-dropdown-list-item');
		const dropDownListItemLast = dropDownListItems[dropDownListItems.length - 1]
		const dropDownInput = $(select).find('.js-dropdown-input-hidden');
		dropDownBtn.text($(dropDownListItemLast).text());
		dropDownInput.val($(dropDownListItemLast).attr('data-value'));
		select.addClass('active_filter')
		$(dropDownList).find(`.js-dropdown-list-item`).removeClass('dropdown__list--active');
		$(dropDownListItemLast).addClass('dropdown__list--active');
	}
  	install_first_value = (select_name) => {
		const select = $(`.js-custom-select-wrap[name=${select_name}]`)
		const dropDownBtn = $(select).find('.js-dropdown-button');
		const dropDownList = $(select).find('.js-dropdown-list');
		const dropDownListItems = $(dropDownList).find('.js-dropdown-list-item');
		const dropDownListItemLast = dropDownListItems[0]
		const dropDownInput = $(select).find('.js-dropdown-input-hidden');
		dropDownBtn.text($(dropDownListItemLast).text());
		dropDownInput.val($(dropDownListItemLast).attr('data-value'));
		select.addClass('active_filter')
		$(dropDownList).find(`.js-dropdown-list-item`).removeClass('dropdown__list--active');
		$(dropDownListItemLast).addClass('dropdown__list--active');
	}
}

export const custom_select = new CustomSelect();
custom_select.init();

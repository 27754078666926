import Swiper from '../libs/swiper';
/**
 * @class - класс создает экземпляры слайдеров с разными настройками
 * @method schema_slider - Слайдер "Схем размещения квартир в подъезде и таунхаусов в проетке"
 * @method slider_floors - Слайдер этажей
 * @method how_to_buy_slider - Слайдер для секции "как купить"
 * @method slider_on_1_slide - Слайдер за раз показывает 1 слайд
 * @method slider_on_3_slide - Слайдер за раз показывает до 3 слайдов
 * @method slider_on_4_slide - Слайдер за раз показывает до 4 слайдов
 */

import SwiperLib from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
class Sliders {
  constructor() { }
  schema_slider = () => new Swiper(".js-schemaslider", {
    slidesPerView: 'auto',
    centeredSlides: false,
    freeMode: true,
    spaceBetween: 30,
    observer: true,
    observeParents: true,
    navigation: {
      nextEl: ".js-schemaslider_next",
      prevEl: ".js-schemaslider_prev",
    },
    breakpoints: {
      1200: {
        // slidesPerView: 3,
      },
      500: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    },
    speed: 1000,
  })
  slider_floors = () => new Swiper('.js-slider-floors', {
    slidesPerView: "auto",
    centeredSlides: true,
    direction: 'vertical',
    spaceBetween: 30,
    navigation: {
      nextEl: '.js-floor-next',
      prevEl: '.js-floor-prev',
    },
  });
  how_to_buy_slider = () => new Swiper(".js-howtobuyslider", {
    slidesPerView: "auto",
    spaceBetween: 30,

    navigation: {
      nextEl: ".js-howtobuyslider-next",
      prevEl: ".js-howtobuyslider-prev",
    },
    breakpoints: {
      1160: {
        slidesPerView: 'auto',
      },
      650: {
        slidesPerView: 1,
      },
      0: {
        slidesPerView: 1,
      },
    },
    speed: 1000,
  })
  slider_on_1_slide = ({ wrap, next_nav, prev_nav, pagination }) => new Swiper(wrap, {
    slidesPerView: 1,
    navigation: {
      nextEl: next_nav,
      prevEl: prev_nav,
    },
    pagination: {
      el: pagination,
      dynamicBullets: true,
      clickable: true,
    },
    // loop: true,
    speed: 1000,
  })
  slider_on_3_slide = ({ wrap, next_nav, prev_nav }) => new Swiper(wrap, {
    slidesPerView: 3,
    spaceBetween: 30,

    navigation: {
      nextEl: next_nav,
      prevEl: prev_nav,
    },

    breakpoints: {
      1160: {
        slidesPerView: 3,
      },
      650: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    },
    speed: 1000,
  })
  slider_on_4_slide = ({ wrap, next_nav, prev_nav }) => new Swiper(wrap, {
    slidesPerView: 4,
    spaceBetween: 30,

    navigation: {
      nextEl: next_nav,
      prevEl: prev_nav,
    },

    breakpoints: {
      1500: {
        slidesPerView: 4,
      },
      1100: {
        slidesPerView: 3,
      },
      650: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    },
    speed: 1000,
  })

  comparison_slider = ({ wrap, next_nav, prev_nav }) => new SwiperLib(wrap, {
    modules: [Navigation, Pagination],
    slidesPerView: 3,
    navigation: {
      nextEl: next_nav,
      prevEl: prev_nav
    },
    breakpointsBase: 'container',
    breakpoints: {
      1024: {
        slidesPerView: 3,
      },
      500: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 2,
      },
    },
    speed: 1000,
  });
}
export const sliders = new Sliders()

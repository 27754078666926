import {
    set_local,
    get_local
} from '../utils/storege';
import api from '../api/request';
import { custom_select } from './custom-select';
import { get_tempalte_select_city_array, get_tempalte_select_option } from '../templates/templates_filters';
import { showModal, killModal } from './modal';
/**
    * @class GetCity - опаределяет город пользователя, показывает и скрывает модалку и переключает шаблоны в модалке
    * @method render_city - по url определяет город из списка городов в шаблоне и подставляет значение для отображения пользователю
    * @method switch_city - переключает модалку городов
    * @method write_city_in_template - продставляет значения в шаблон и записывает id говрода
    * @method open_switch_city_wrap_first - показывает шаблон с вопросом города нахождения пользователя
    * @method open_switch_city_wrap - показывает шаблон с списком городов
    * @method close_switch_city_wrap - скрывает все шаблоны и закрывает модалку
    * @method render_city_select - запрашивает список городов и рендерит их в custom-select
    * @method render_city_select_vacansies - запрашивает список городов и рендерит их в custom-select страницы вакансий
    * @method set_city_id_localstorege - определяет какой город выбран и записывает id города из profitbase в локальное хранилище
    * @method set_cookie_id_city - оправляет запрос для записи в куки id города
*/

export class GetCity {
    constructor() {
        this.target_city = $('.js-target-city').first();
        this.yes_city = get_local('consent-city');
        this.list_city_top_menu =  $(".js-city-menu-list");
        this.switch_city_general_wrap = $('.js-switch-city');
        this.switch_city_active_btn = $(".js-switch-city-active");
        this.switch_city_confirmation = $('.js-switch-city-confirmation');
        this.close_modal_button = $('.js-req-modal__close');
        this.switch_city_wrap_1 = $(".js-switch-city-wrap-1");
        this.switch_city_wrap_2 = $(".js-switch-city-wrap-2");
        this.modal_city = $('.js-req-modal__city');
        this.city_datas = null;
        this.name_city = null;
        this.city_pb = null;
    }
    init_city = async() => {
       await api.city()
        .then(data => {
            this.city_datas = data.cities;
            this.render_city();
            this.switch_city();
        })
    }
    render_city = () => {
        this.name_city = this.target_city.text();
        this.write_city_in_template();
        if (this.yes_city !== 'yes') {
            this.open_switch_city_wrap_first();
            this.set_cookie_id_city();
        }
    }
    switch_city = () => {
        this.switch_city_active_btn.on('click', () => {
            if (this.yes_city === 'yes') {
                this.open_switch_city_wrap();
            } else {
                this.open_switch_city_wrap_first();
            }
        })
        this.switch_city_confirmation.on('click', () => {
            this.switch_city_wrap_1.removeClass('show');
            this.switch_city_wrap_2.addClass('show');
        })
        this.list_city_top_menu.on("click", (e) => {
            e.preventDefault();
            const target_city_name = $(e.target).text();
            set_local('consent-city', 'yes');
            if (target_city_name === this.city_name) {
                killModal(this.modal_city);
            } else {
                killModal(this.modal_city);
                this.write_city_in_template();
                this.set_cookie_id_city();
                location.href = e.target.href;
            }
        })
        this.close_modal_button.on('click', () => {
            set_local('consent-city', 'yes');
            this.set_cookie_id_city();
            this.set_city_id_localstorege();
        })
    }
    write_city_in_template = () => {
        this.city_pb =  this.city_datas.find(i => i.city === this.name_city);
        if(this.city_pb) {
          set_local("city",this.city_pb.id);
        }
        this.set_city_id_localstorege();
    }
    open_switch_city_wrap_first = () => {
        showModal(this.modal_city)
        this.switch_city_wrap_1.addClass('show');
        this.switch_city_wrap_2.removeClass('show');
    }
    open_switch_city_wrap = () => {
        showModal(this.modal_city)
        this.switch_city_wrap_1.removeClass('show');
        this.switch_city_wrap_2.addClass('show');
    }
    close_switch_city_wrap = () => {
        this.switch_city_wrap_2.removeClass('show');
        this.switch_city_wrap_2.removeClass('show');
        killModal(self.modal_city);
    }
    set_cookie_id_city = () => this.city_pb && this.city_pb.id && api.post_city({ city_id: this.city_pb.id})
    render_city_select = async (select_city, all_city) => {
        await api.city()
            .then(data => {
                if (all_city) {
                    select_city.append(get_tempalte_select_city_array([{ id: '', city: "Все города" }]));
                    select_city.append(get_tempalte_select_city_array(data.cities));
                    custom_select.update_select('city');
                } else {
                    select_city.append(get_tempalte_select_city_array(data.cities));
                }
                custom_select.install_value('city', get_local("city"));
            })
    }
    render_city_select_vacansies = (select_city) => {
        this.city_datas.forEach(i => {
            select_city.append(get_tempalte_select_option(i));
        })
        custom_select.install_value('city', get_local("city"));
    }
    set_city_id_localstorege = () => {
        this.city_pb = this.city_datas.find(i => i.city === this.name_city);
        if(this.city_pb) {
          set_local('city',this.city_pb.id);
        }
    }
}
export const get_city = new GetCity();

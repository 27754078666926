import api from "../api/request";
import { ActionSvg } from "../components/action_svg";
import { get_template_decor_slide } from "../templates/templates_decor";
import form_option from "../utils/trigget-filters";
import { slider_decor } from "./section_sliders";
import { loader } from "../components/loader";
import { get_render_drop_section_inf } from "../templates/templates_dropdown_cards";
import { asyncImg } from "../utils/loadingAsyncImg"
/**
 * @class - класс содержит логику для секции блока "Отделка квартир"
 * @method slider_init - устанавливает активным первый фильтр и делает первый запрос
 * @method request - запрос отдеки
 * @method render - обновляет верстку в контейнере и обновляет слайдер
 * @method switch_filter - слушатель нажатия на фильтры
 * @method target_decor - инициализирует класс с логикой вывода информации при наведение на свг
 */

export class SectionDecor {
    constructor() {
        this.wrap = $(".js-decor-wrap");
        this.empty_wrap = $(".js-wrap-empty-decor");
        this.control_wrap = $('.js-decor-controls');
    }
    init = () => {
        this.slider_init();
        this.target_decor();
        this.switch_filter();
    }
    slider_init = () => {
        const first_item = $($(".js-filter-decor")[0]);
        const fist_input = first_item.find("input");
        first_item.addClass('active_radio');
        this.request({ id: fist_input.val() });
    }
    switch_filter = () => {
        const self = this
        $(document).on("click", ".js-filter-decor", function () {
            $(`.js-filter-decor`).removeClass('active_radio');
            $(this).addClass('active_radio');
            const params = form_option.get_options_form(this);
            self.request(params);
        })
    }
    request = (params) => {
        this.empty_wrap.removeClass('show');
        loader.show_loader('decor_slider');
        this.wrap.children().remove();
        api.get_decor(params)
            .then(data => this.render(data))
            .catch(err => {
                switch (err?.response?.status) {
                    case 404:
                    case 500:
                        showModalError(this.modal_error);
                        break;
                }
            })
    }
    render = (data) => {
        if (data[0]) {
            loader.hidden_loader('decor_slider');
            if (data[0].description.length) {
                this.wrap.append(data[0].description.map(i => get_template_decor_slide(i)));
                slider_decor.update();
                asyncImg.initOnWrap(this.wrap);
                if (data[0].description.length < 2) {
                    this.control_wrap.addClass("hidden-wrap");
                } else {
                    this.control_wrap.removeClass("hidden-wrap");
                }
            } else {
                this.empty_wrap.addClass('show');
            }
        }
    }
    target_decor = () => {
        const paths_decor = new ActionSvg({
            project: $(".js-decor-slide__svg"),
            project_item: ".js-decor-slide__svg [data-typepath]",
            template: get_render_drop_section_inf
        }, false);
        paths_decor.init();
    }

}

/**
 * @class - класс содержит логику shareMenu
 * @method share_handler - слушает нажатие на триггер  и показывает shareMenu
 * @method share_handler_down - скрыввает shareMenu
 * @method copy_link - копирует ссылку страницы из url
 */
class ShareMenu {
  constructor() {
    this.shares = $(".share");
    this.url = location.href;
    this.title = document.title;
    this.share_list = {
      vkontakte: `http://vk.com/share.php?url=${encodeURIComponent(this.url)}&title=${encodeURIComponent(this.title)}&utm_source=share2`,
      odnoklassniki: `https://connect.ok.ru/offer?url=${encodeURIComponent(this.url)}&title=${this.title}&utm_source=share2`,
      facebook: `https://www.facebook.com/sharer.php?src=sp&u=${encodeURIComponent(this.url)}&title=${encodeURIComponent(this.title)}&utm_source=share2`,
      instagram: `http://instagram.com/###/?url=${encodeURIComponent(this.url)}`
    }
  }
  init = () => {
    this.share_handler();
    this.share_handler_down();
    this.copy_link();
    this.touch_share()
  }
  share_handler = () => {
    this.shares.on('click', function () {
      $(this).find(".share-dropdown").toggleClass("show");
      $(this).find(".share__btn").toggleClass("share__btn_active");
    })
  }
  share_handler_down = () => {
    $(document).on('click', (e) => {
      const target = $(e.target).closest('.share')
      if (target[0] !== this.shares[0]) {
        this.shares.find(".share-dropdown").removeClass("show");
        this.shares.find(".share__btn").removeClass("share__btn_active");
      }
    })
  }
  copy_link = () => {
    $(".js-copy-link").on("click", (e) => {
      e.preventDefault();
      navigator.clipboard.writeText(this.url);
    })
  }
  touch_share = () => {
    const self = this;
    $(document).on('click', '.js-social-share', function (e) {
      e.preventDefault()
      self.open_modal_share(this);
    })
  }
  open_modal_share(a) {
    const social = $(a).attr('data-social');
    const urlSet = this.share_list[social]
    const isOpened = this.popup(urlSet);
    if (null === isOpened) {
      a.href = urlSet;
      return true;
    }
    return false;
  }
  popup = (url) => {
    const x = screen.width/2 - 626/2;
    const y = screen.height/2 - 436/2;
    return window.open(url, '', `toolbar=0,status=0,width=626,height=436,left=${x},top=${y}`);
  }
}
const share_menu = new ShareMenu();
share_menu.init();
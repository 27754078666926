import { WAY_UP_DEF_IMG } from '../const/way_up'
import { get_dayjs_localized_ru } from '../utils/dayjs'

export const get_card_news_and_promotions = (item) => {
    return `
    <a class="card news-card" href="${item.url}">
        <div class="news-card__img">
        ${item.image ? `<img src="${item.image}" alt="news">` : `<img class="flat-map__img" src="${WAY_UP_DEF_IMG}" alt='default'>`}
        </div>
        <div class="news-card-content">
            <h4 class="news-card__title">${item.title}</h4>
            <p class="news-card__text">${item.short_description}</p>
            <span class="news-card__date">${get_dayjs_localized_ru(item.date)}</span>
        </div>
    </a> `
}
export const get_news_year = (data) => {
    return data.map((i) => `<li class="dropdown__list-item js-dropdown-list-item js-year-item" data-value='${i}'>${i}</li>`)
}
export const get_news_category = (data) => {
    return data.map((i) => `<li class="dropdown__list-item js-dropdown-list-item" data-value='${i.category__pk}'>${i.category__title}</li>`)
}
export const get_news_category_radio = (data) => {
    return data.map((i) =>
    `<div class="filter-radio__option">
        <input class="filter-radio__input js-news-category" type="checkbox" name="category" value="${i.category__pk}" >
        <label class="filter-radio__label" for="news-type">${i.category__title}</label>
    </div>`)
}
export const get_news_month = (data) => {
    const months = {
        '': 'Все месяцы',
        1: 'Январь',
        2: 'Февраль',
        3: 'Март',
        4: 'Апрель',
        5: 'Май',
        6: 'Июнь',
        7: 'Июль',
        8: 'Август',
        9: 'Сентябрь',
        10: 'Октябрь',
        11: 'Ноябрь',
        12: 'Декабрь'
    }
    return data.map(i => `<li class="dropdown__list-item js-dropdown-list-item js-month-item" data-value='${i}'>${months[i]}</li>`)
}

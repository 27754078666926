import { parseOptionInOpject } from './parse_url';

class TriggerFilters {
    constructor() {
        this.select_prev_value = true;
    }

    get_options_form = (e, filters) => {
        if (!e) return null
        const form = e.closest('form')
        let options_request = this.serializeObject(form);
        if (filters) {
            options_request = this.filter_options_main(options_request, filters);
        }
        return options_request;
    }

    serializeObject = (form) => {
        let arrayData, objectData;
        arrayData = $(form).serializeArray();
        objectData = {};
        $.each(arrayData, function () {
            let value = String(this.value);
            let prevValue = '';

            // Заменяем символы только между числами.
            while (prevValue !== value) {
                prevValue = value;
                value = value.replace(/(\d+)[\s,\%](\d*)$/, '$1$2');
            }

            if (objectData[this.name]) {
                if (!objectData[this.name].push) {
                    objectData[this.name] = [objectData[this.name]];
                }
                if (value) {
                    objectData[this.name].push(value);
                }
            } else {
                if (/[\.]/.test(this.name)) {
                    const key = this.name.split('.');
                    if (!objectData[key[0]]) {
                        objectData[key[0]] = {};
                    }
                    if (value) {
                        objectData[key[0]][key[1]] = value;
                    }
                } else {
                    if (value) {
                        objectData[this.name] = value;
                    }
                }
            }

        })
        return parseOptionInOpject(objectData);
    };

    filter_options_main = (collections, filters) => {
        let new_options = {};
        for (let key in collections) {
            filters.forEach(i => {
                if (i === key) {
                    new_options[key] = collections[key];
                }
            })
        }
        return new_options;
    }
    count_active_filter = (form) => {
        let count = form.find('.active_filter').length
        active_filter_count.text(count)
        $('.js-count-active').on("change", (e) => {
            e.preventDefault()
            let count = form.find('.active_filter').length
            active_filter_count.text(count)
        })
    }
    get_qwery_string = (filters) => {
        const collections_active_filters = document.querySelectorAll('.active_filter');
        let active_filters_names = []
        collections_active_filters.forEach((i) => {
            const name = $(i).attr('name');
            if (name && name.indexOf(',') > -1) {
                const arr_name = name.split(',');
                active_filters_names = [...active_filters_names, ...arr_name];
            } else {
                active_filters_names.push(name);
            }
        })
        return form_option.get_options_form(filters, active_filters_names);
    }
}

const form_option = new TriggerFilters()
export default form_option

import { clear_phone_format } from "../utils/clear_format"

export const get_template_partner = (data) => {
    let text = `<div class="partner-card">
    <div class="partner-card-wrap">
        <div class="partner-card-category-wrap">`
    data.categories && data.categories.forEach((i) => { text += `<a class="category-wrap_item js-category-card" id='${i.id}'>${i.title}</a>` })
    text += `</div>
    
    <div class="partner-card-content">
        <div class="partner-card-top">
            <h4 class="partner-card__title">
                ${data.title}
            </h4>
            <div class="partner-card_address-wrap">`
    data.addresses && data.addresses.forEach((i) => { text += `<p class="partner-card__address"> ${i.title} </p>` })
    text += `</div>`
    if (data.discount_from === data.discount_to) {
        text += parseInt(data.discount_from) ? `<p class="partner-card__discount">
            Скидка <span class="partner-card__discount__procent"> ${parseInt(data.discount_to)} %</span>
        </p>`: ``
    } else {
        text += `<p class="partner-card__discount">
            Скидка <span class="partner-card__discount__procent">  ${parseInt(data.discount_from)} - ${parseInt(data.discount_to)} %</span>
        </p>`
    }
    text += `</div>
        <div class="partner-card__text">
        ${data.short_description}
        </div>
        <div class="partner-card-bottom">
            <div class="partner-card__contacts">
                <a class="partner_link-site" href="${data.site_link}" target="_blank">${data.site_name}</a>
                <a class="partner_tel-number" href="tel:${clear_phone_format(data.phone)}">${data.phone}</a>
            </div>
            <div class="partner-card__city-wrap">`
    data.cities && data.cities.forEach((i) => { text += `<p class="partner-card__city">${i}</p>` })
    return text += `</div></div></div></div></div>`
}

export const template_catigories_partners = (data) => {
    return `<div class="filter-radio__option">
        <input class="filter-radio__input js-partners-category" type="checkbox" name="category" value="${data.id}">
        <label class="filter-radio__label" for="partners-type">${data.title}</label>
    </div>`
}
export const template_catigories_partners_list = (data) => {
    return ` <li class="dropdown__list-item js-dropdown-list-item js-category-list-item" data-value="${data.id}">${data.title}</li>`
}

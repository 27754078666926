import api from "../api/request";
import { WAY_UP_DEF_IMG } from "../const/way_up";
import { loader } from "./loader";
import { floor_slider } from "../sections/section_sliders";
import { conjugation } from "../utils/conjugation";

/**
 * @class - класс содержит логику
 * @method request_plan_floor - отправляет запрос на полученние плана этажа
 * @method render_detal_floor - рендерит план этажа
 * @method info_sale_flat - рендерит сообщение о кол-ве квартир в продаже на этаже
 * @method init_floor_slider - инициализирует работу слайдера этажа
 * @method sort_floors - сортирует этажи в массив в этажей от меньшего к большему
 * @method init_active_floor - определеяет активный этаж
 * @method render_active_floor - рендерит номер активного этажа
 * @method render_floors - рендерит слайды этажей
 * @method install_active_slide_on_floor - определяет номер этажа и устанавливает слайд с номером этажа
 * @method click_floor_button - слушает нажатие на кнопки управления слайдером и запрашивает данные этажа
 * @method click_floor_number - слушает зажатие на номер этажа и запрашивает данные этажа
 */

export class SliderFloorsHouse {
    constructor() {
        this.floor_wrap = $('.js-floor-slider-wrap');
        this.floor_content = $(".js-floor-content");
        this.floor_img = $(".js-floor-img");
        this.floor_svg_wrap = $(".js-floor-flat-svg");
        this.prev_img_floor_detail = null;
        this.content_item = $(".js-vertical-slider-item");
        this.number_floor_wrap = $(".js-project-plan-detal__room-floor");
        this.floors = [];
        this.active_floor = null;
        this.count_floor_sale = 0;
        this.loader_name = 'floor-detail';
    }
    init = () => {
        this.click_floor_button();
        this.click_floor_number();
    }
    request_plan_floor = (init_slider, id_floor) => {
        loader.show_loader(this.loader_name);
        this.floor_content.addClass('hidden-wrap');
        api.house_floor({ floor: id_floor })
            .then((data) => {
                loader.hidden_loader(this.loader_name);
                this.floor_content.removeClass('hidden-wrap');
                if (data.results) {
                    this.count_floor_sale = data.results[0].properties_on_sale;
                    this.render_detal_floor(data.results[0]);
                    this.info_sale_flat();
                    if (init_slider) {
                        this.init_floor_slider(data);
                    }
                }
            })
            .catch((e) => {});
    }
    render_detal_floor = (item) => {
        if (this.prev_img_floor_detail !== item.picture) {
            this.prev_img_floor_detail = item.picture;
            if (item.picture) {
                this.floor_img.attr('src', item.picture);
            } else {
                this.floor_img.attr('src', WAY_UP_DEF_IMG);
            }
        }
        this.floor_svg_wrap.html(item.svg);
    }
    info_sale_flat = () => {
        if (this.count_floor_sale) {
            this.content_item.text(conjugation(this.count_floor_sale, ['квартирa в продаже', 'квартиры в продаже', 'квартир в продаже']))
        } else {
            this.content_item.text("Квартир в продаже нет");
        }
    }
    init_floor_slider = (data) => {
        console.log(data.results[0].section_number);
        $('#section-header-address-section_number').text(data.results[0].section_number ? `${data.results[0].section_number}` : '');
        floor_slider.removeAllSlides();
        this.sort_floors(data);
        this.init_active_floor(data);
        this.render_active_floor(data);
        this.render_floors();
        floor_slider.update();
        this.install_active_slide_on_floor();
    }
    sort_floors = (data) => {
        this.floors = data.results[0].floors.sort((a, b) => a.floor - b.floor);
    }
    init_active_floor = (data) => {
        this.active_floor = data.results[0].number;
    }
    render_active_floor = () => {
        this.number_floor_wrap.text(this.active_floor);
    }
    render_floors = () => {
        this.floor_wrap.append(this.floors.map(floor_item =>
            `<div class="swiper-slide js-swiper-slide">
                <button class="vertical-pagination_item js-active-slide" data-floor="${floor_item.floor}" type="button"id="${floor_item.id}">
                    ${floor_item.floor}
                </button>
            </div>`
        ))
    }
    install_active_slide_on_floor = () => {
        const index_active_floor = this.floors.findIndex(i => i.floor === this.active_floor);
        floor_slider.slideTo(index_active_floor);
    }
    click_floor_button = () => {
        const self = this
        $(".js-floor-btn").on("click", function () {
            const active_floor = $(".js-swiper-slide.swiper-slide-active .js-active-slide");
            const id_floor = active_floor.attr('id');
            const target_floor = active_floor.attr('data-floor');
            self.number_floor_wrap.text(target_floor);
            self.request_plan_floor(false, id_floor);
        })
    }
    click_floor_number = () => {
        const self = this
        $(document).on('click', ".js-active-slide", function (e) {
            const id_floor = $(this).attr('id');
            const target_floor = $(this).attr('data-floor');
            floor_slider.slideTo(target_floor - 1);
            self.number_floor_wrap.text(target_floor);
            self.request_plan_floor(false, id_floor);
        })
    }
}

const set_history = (params) =>{
    history.replaceState(params,"newTitle")
}
const get_history = () =>{
    return history.state ? history.state : {};
}
const add_history = (params) => {
    history.pushState(params,"newTitle")
}
const add_query_to_history = (params) => {
    var url = new URL(window.location);
    url.search = "";
    Object.keys(params).forEach(param => {
        url.searchParams.set(param, params[param]);
    })
    history.pushState(params, "", url);
}
export {
    set_history,
    get_history,
    add_history,
    add_query_to_history,
}

/**
 * @class -  класс содержит логику для секции "отображения очереди таунхаусов"
 * @method render_map - при наличие координат отмечает проект на карте
 * @method target_flat - выводит информацию при наведение на свг помещения таунхауса
 * @method target_schema - выводит информацию при наведение на помещения таунхауса в схеме очереди
 * @method mortgage_section - при наличие секции ипотеки ее иницилизирует
 * @method switch_details - инициализирует переключает план очереди и схемы
 * @method link_to - при выборе помещения на этаже открывает детальную страницу помещения таунхауса
 * @method init_custom_radiobutton - инициализирует работу кастомных радиокнопок
 */

export class SectionYoutube {
  constructor() {
    this.youtube_id = $('.js-video-iframe');
    this.youtube_overlay = $('.js-video-container-overlay');
    this.youtube_thumb = $('.js-video-link');
    this.youtube_preview = $(".js-video_preview");
    this.video_title = '';
    this.video_date = '';
  }
  init = () => {
    if (this.youtube_id.length) {
      this.show_first_video();
      this.youtube_handler();
      this.next_video();
    }
  }
  show_first_video = () => {
    this.youtube_overlay.css('background-image', `url(http://img.youtube.com/vi/${this.youtube_id}/hqdefault.jpg)`);
    const id = this.youtube_id.attr('id');
    $(`.js-video-link[data-id=${id}]`).css({ 'display': 'none' });
  }
  youtube_handler = () => {
    const self = this
    self.youtube_overlay.on('click', function (e) {
      $(this).css('display', 'none');
      const iframe = $(this).parent().find('iframe');
      const scr = iframe.attr("src");
      iframe.attr("src", `${scr}?autoplay=1`);
    })
  }

  next_video = () => {
    const self = this;
    const main_iframe = $('.video-main iframe');
    $(document).on('click', ".js-video-link", function (e) {
      e.preventDefault();
      $(this).closest('.video-playlist-wrap').find(self.youtube_overlay).css('display', 'none');
      $(`.js-video-link`).css({ "display": "" });
      $(this).css({ "display": "none" });
      main_iframe.attr("src", `${this.href}`);
      self.youtube_preview.attr('src', $(this).attr('data-preview'));
      self.youtube_overlay.css({ "display": '' });
      this.video_title = $(this).find('.js-video-title').text();
      $('.js-main-video-title').text(this.video_title);
      this.video_date = $(this).find('.js-video-date').text();
      $('.js-main-video-date').text(this.video_date);
    })
  }
}

/**
 * @class - класс содержит логику для секции "Преимущества"
 * @method active_block - устанавливает начальное состояние первого преимущества
 * @method target_block - дейтвие по нажатию категорию премущества
 * @method switch_target_block - убирает все активные состояния и устанавливает на выбранную категорию
 * @method switch_target_block_mobile - убирает все активные состояния и устанавливает на выбранную категорию в мобильной версии
 */
export class SectionAdvanse {
  constructor() {
    this.blocks_btn_wrap = $(`.js-advantage-item`);
    this.blocks_btns = $(".js-advantage-item_btn");
    this.blocks = $(".js-project-advantages-content");
    this.block_min_items = $(".js-advantage-item-min");
    this.content_min_items = $(".js-advantage-item-min-container");
  }
  init = () => {
    this.active_block();
    this.target_block();
    this.switch_target_block_mobile();
  }
  active_block = () => {
    $(this.blocks[0]).addClass("show");
    $(this.blocks_btn_wrap[0]).addClass("active");
  }
  target_block = () => {
    this.blocks_btns.on("click", (e) => {
      const id = String(e.target.id);
      this.switch_target_block(id);
    })
  }
  switch_target_block = (id) => {
    this.blocks_btn_wrap.removeClass("active");
    this.blocks.removeClass("show");
    $(`.js-advantage-item[data-id=${id}]`).addClass("active");
    $(`.project-advantages-content[data-id=${id}]`).addClass("show");
  }
  switch_target_block_mobile = () => {
    this.block_min_items.on("click", function(e) {
      if ($(e.currentTarget).is(this) && $(e.currentTarget).hasClass('show')) {
        $(e.currentTarget).removeClass('show');
      } else {
        $(e.currentTarget).addClass("show");
      }
    })
  }
}
